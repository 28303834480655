/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import "../../App.css";
import LangContext from "../../contextProvider/LangContext";
import PageToolbar from "../../components/PageToolbar";
import moment from "moment";
import { Input, Radio, Select, DatePicker, Button, Checkbox, Form, message, Tooltip } from "antd";
import TableIDs from "../../data/TableIDs";
import ModalChangeMoveinFormat from "../../components/ModalChangeMoveinFormat";
import ModalProgress from "../../components/ModalProgress";
import { Job, User, OutputType, ProcessType } from '../../JmReact';
import GenericModal from "../../components/GenericModal";
import GenericSelector from "../../components/GenericSelector";
import { disabledDatePicker } from "../../utils/utils";
import GenericSelectorPosStorages from "../../components/GenericSelectorPosStorages";
import ResponsiveTable from "../../components/ResponsiveTable";
import TableLineIcons from "../../components/TableLineIcons";
import ViewDoc from "../../components/ViewDoc";
import ReportShowButton from "../../components/ReportShowButton";

const { Option } = Select;

class JournalEntriesFormat extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            mStartDate: null,
            mEndDate: null,
            mFileType: null,
            mUpdatedDates: false,
            ///
            percent: null,
            status: null,
            message: null,
            type: null,
            ///
            modalKupain: false,
            mPayTypeList: '',

            modalStatusManager: false,
            modalSMView: null,
        }

        this.api = {
            get: "get_movein_defs_row_data",

            g_file_1: "generate_movein_file_1",
            g_file_2: "generate_movein_file_2",
            g_file_3: "generate_movein_file_3",
            g_file_4: "generate_movein_file_4",
            // g_file_5: "generate_movein_file_5",
            g_check_len: "check_len_pos_pay_h_accounts",
            g_file_delete: "generate_movein_delete_all",

            kupain_file: "generate_kupain_file",

            get_sm: "get_status_manager_movein_steps",
            reset_sm: "reset_status_manager_movein_step",

            get_sm_data: "get_status_manager_movein_step_data",
        }

        this.id = "MOVEION_FORMAT";
        this.sm_id = "STATUS_MANAGER";
        this.sm_data_id = "STATUS_MANAGER_STEP_DATA";

        this.selectors = [
            { id: TableIDs.genericSelectorBranches, script: "get_branch_list" },
            { id: TableIDs.genericSelectorDocumentTypes, script: "get_document_types_list" },
            { id: TableIDs.genericSelectorPos, script: "list_pos" },
            { id: TableIDs.genericSelectorPaymentTypes, script: "get_payment_types_list" },
        ]

        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericData };
        this.selectors.forEach((x) => { if (props.data[x.id] === undefined) props.data[x.id] = { ...props.data.genericSelector } })

        this.actionButtons = [
            {
                buttonType: 'refresh',
                buttonFunction: this.refreshData
            }
        ];
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        this.initFilter(-1);
    }

    componentDidUpdate(p) {
        let store = this.props.data[this.id].editedRecord;
        let pStore = p.data[this.id].editedRecord;
        if (!pStore !== store && store.mDates && !this.state.mUpdatedDates) this.setState({ mUpdatedDates: true }, () => { this.dateRange(store.mDates) });
    }

    initFilter = (id) => {
        this.props.dataActions.genericDataGetEditWithParams(this.id, this.props.user.companyCode, this.props.user.token, { mID: id }, this.api.get);
        this.selectors.forEach((x) => { this.props.dataActions.genericSelectorRefreshDataset(x.id, this.props.user.companyCode, this.props.user.token, x.script) });
        this.refreshDataSM()
    }

    refreshData = () => {
        let er = this.props.data[this.id].editedRecord;
        this.initFilter(er.mID);
        this.dateRange(er.mDates)
    }

    refreshDataSM = () => {
        this.props.dataActions.genericDataSetFilter(this.sm_id, this.props.user.companyCode, this.props.user.token, {}, this.api.get_sm);
    }

    dateRange = (num) => {

        let startDate = new Date();
        let endDate = new Date();
        let c = new Date();

        let zeros = (n) => n >= 10 ? n : "0" + n;
        let myFormat = (d) => (d.getYear() + 1900) + "-" + zeros(d.getMonth() + 1) + "-" + zeros(d.getDate());

        switch (num) {
            case "1":
                startDate.setDate(c.getDate() - 1);
                endDate.setDate(c.getDate() - 1);
                break;
            case "2": break;
            case "3":
                startDate.setDate(c.getDate() - c.getDay());
                break;
            case "4":
                startDate.setDate(c.getDate() - c.getDay() - 7);
                endDate.setDate(c.getDate() - c.getDay() - 1);
                break;
            case "5":
                startDate.setDate(1);
                break;
            case "6":
                startDate.setDate(1);
                startDate.setMonth(c.getMonth() - 1);
                endDate.setDate(0);
                break;
            default:
                startDate = null;
                endDate = null;
        }

        this.setState({
            mStartDate: startDate ? myFormat(startDate) : null,
            mEndDate: endDate ? myFormat(endDate) : null
        })

    }

    generateFile1 = () => {
        this.setState({
            percent: 20,
            status: "active", // "exception",
            message: this.getText(12918),
            type: "info",
        }, () => {
            let er = this.props.data[this.id].editedRecord;
            let data = "mStartDate\fmEndDate\fmByPos\fmPosList\fmBranches\fmDocs\fmIsForExcel\r" +
                this.state.mStartDate + "\f" +
                this.state.mEndDate + "\f" +
                er.mByPos + "\f" +
                er.mPosList + "\f" +
                er.mBranches + "\f" +
                er.mDocs + "\f" +
                (this.state.mFileType == 2 ? "1" : "0");

            this.sendAPI(this.api.g_file_1, data, (ob) => {
                let data = ob.data ? ob.data.split("\f") : ['0', '0']
                if (!parseInt(data[0])) {
                    this.errorCallback(12920);
                } else if (parseInt(data[1])) {
                    this.setState({
                        status: "exception2",
                        message: (<div>
                            <p>{this.getText(19797)} {data[0]} {this.getText(19798)}</p>
                            <p>{this.getText(19799)} {data[1]} {this.getText(19800)}</p>
                            {parseInt(data[0]) === parseInt(data[1]) ? '' : <p>{this.getText(19801)} {parseInt(data[0]) - parseInt(data[1])} {this.getText(19802)}</p>}
                        </div>),
                        type: parseInt(data[0]) === parseInt(data[1]) ? "error" : "warning"
                    });
                } else {
                    this.generateFile2();
                }
            });
        });
    }

    generateFile2 = () => {
        this.setState({
            percent: 40,
            message: this.getText(12919),
        }, () => {
            let data = "mDefCust\r" + this.props.data[this.id].editedRecord["mDefCust"];
            this.sendAPI(this.api.g_file_2, data, (ob) => {
                let res = ob.data ? ob.data.split("\r") : [];
                if (res.length > 0) {
                    let errorText = [];
                    res.forEach((x) => {
                        let y = x.split("\f");
                        errorText.push(this.getText(y[2] == '2' ? 16160 : 14147) + " " + y[0] + " " + this.getText(y[2] == '2' ? 16161 : 14148) + " " + y[1]);
                        errorText.push(<br />);
                    })
                    this.setState({
                        status: "exception",
                        message: (<span>{errorText}</span>),
                        type: "error"
                    }, () => { this.sendAPI(this.api.g_file_delete, "", () => { }) });
                } else {
                    this.generateFile3();
                }
            });
        });
    }

    generateFile3 = () => {
        this.setState({
            percent: 60,
            message: this.getText(12923),
        }, () => {
            let params = [
                "mDefCust",
                "mNOD",
                "mCollect1",
                "mCollect2",
                "mCollect3",
                "mCollect4",
                "mCollect5",
                "mCollect6",
                "mCollect7",
                "mCollect8",
                "mCollect9",
                "mByPos",
                "mSuppRef",
            ];
            let data1 = '';
            let data2 = '';
            params.map((x, i) => {
                if (i > 0) {
                    data1 += "\f";
                    data2 += "\f";
                }
                data1 += x;
                data2 += this.props.data[this.id].editedRecord[x];
            });
            this.sendAPI(this.api.g_file_3, data1 + "\r" + data2, (ob) => {
                // if (parseInt(ob.data)) 
                this.checkAccountLen();
                // else this.errorCallback(12922);
            });
        });
    }

    checkAccountLen = () => {
        let er = this.props.data[this.id].editedRecord;
        let paramToCheck = "mByPos\fmPosList\fmBranches\fmAccntLen\r" +
            er['mByPos'] + "\f" +
            er['mPosList'] + "\f" +
            er['mBranches'] + "\f" +
            er['mAccntLen'];
        this.sendAPI(this.api.g_check_len, paramToCheck, (ob) => {
            if (parseInt(ob.data) > 0) {
                this.setState({
                    percent: 70,
                    message: this.getText(12976) + " " + er['mAccntLen'] + " " + this.getText(12977),
                    type: "warning"
                })
            } else {
                this.generateFile4();
            }
        })
    }

    reverseHe = (str) => {
        let res = '';
        let notHeChars = '';
        let notHeRegexp = /[0-9a-zA-Z]/;
        let heCharsAdd = () => {
            if (notHeChars) {
                res += notHeChars.split('').reverse().join('');
                notHeChars = '';
            }
        }

        str.split('').forEach((x) => {
            if (x.search(notHeRegexp) > -1) {
                notHeChars += x;
            } else {
                heCharsAdd();
                res += x;
            }
        });
        heCharsAdd();

        return res.split('').reverse().join('');
    }

    generateFile4 = () => {
        let er = this.props.data[this.id].editedRecord;

        this.setState({
            percent: 80,
            message: this.getText(12929),
            type: "info"
        }, () => {
            let params = [
                'mRefLen',
                'mAccntLen',
                "mInH",
                "mInP",
                "mOutH",
                "mVATin",
                "mVATout",
                'mBank',
                'mHesh',
                'mZikuy',
                'mPatur',
                'mBuy',
                'mByPos',
                'mNOD',
                'mAutoDeposit',
                'mRicuzH',
                'mRicuzP',
            ];
            let data1 = 'typeFormat';
            let data2 = this.props.typeFormat;
            let reportName = "";

            switch (this.state.mFileType) {
                case 1:
                    // reportName = this.props.typeFormat == "movein" ? "movein" + (er["mFileType"] == "Dat" ? ".dat" : ".doc") : "LOADFNC";
                    // let reportFormat = this.props.typeFormat == "movein" ? 'text/plain;charset=cp862' : 'text/plain;charset=ISO-8859-8-I';

                    params.map((x, i) => {
                        data1 += "\f" + x;
                        data2 += "\f" + er[x];
                    });

                    this.sendAPI(this.api.g_file_4, data1 + "\r" + data2, (ob) => {
                        if (ob.data) {
                            let newData = ob.data.split("\r");
                            newData = newData.map((x) => {
                                let y = x.split("\f");
                                let heRegexp = /[א-ת]/;
                                if (y[6] && y[6].search(heRegexp) > -1 && this.props.typeFormat == "movein") y[6] = this.reverseHe(y[6]);
                                // if (y[19] && y[19].search(heRegexp) > -1 && this.props.typeFormat == "loadfnc") y[19] = this.reverseHe(y[19]);
                                return y;
                            })

                            let blobData = newData.map((x) => {
                                if (this.props.typeFormat == "movein") return x.slice(1, -1).join("")
                                else return x.join("")
                            }).join("\r\n")


                            let text = blobData + "\r\n";

                            let uint8 = new Uint8Array(text.length);
                            for (let i = 0; i < uint8.length; i++) {
                                let b = text.charCodeAt(i);
                                // if (b >= 1488 && b <= 1514) b -= 1360;
                                let heHaxMinus = this.props.typeFormat == "movein" ? 1360 : 1264
                                uint8[i] = (b >= 1488 && b <= 1514) ? b - heHaxMinus : b;
                            }

                            if (this.props.typeFormat == "movein") {
                                this.printFile(uint8, 'text/plain;charset=cp862', "movein" + (er["mFileType"] == "Dat" ? ".dat" : ".doc"))
                            } else {
                                this.printFile(uint8, 'text/plain;charset=ISO-8859-8', "LOADFNC")
                            }

                            if (this.props.typeFormat == "movein") {
                                let rl = parseInt(er['mRefLen']);
                                let al = parseInt(er['mAccntLen']);
                                // let prmData = [3, rl, rl, 10, 10, 0, 0, 22, al, al, al, al, 12, 12, 12, 12];

                                let prmData = [
                                    3, rl, rl, 10, 10, 0, 0, 22, al, al, al, al,
                                    12, 12, 12, 12, 0, 0, 0, 0, 0, rl,
                                    0, 0, 0, 50, 0, 0, 0, 0, 0, 0, 0, 9
                                ];
                                let rowLen = 0;
                                let prmPrint = []
                                prmData.map((x) => {
                                    let posStart = x ? rowLen + 1 : 0;
                                    let posEnd = x ? rowLen + x : 0;
                                    prmPrint.push(posStart + ' ' + posEnd);
                                    rowLen += x;
                                });

                                prmPrint = [rowLen, ...prmPrint]

                                this.printFile(prmPrint.join(";\r\n") + ';', 'text/plain', "movein.prm");

                            }

                            this.generateFile5();
                        } else {
                            this.errorCallback(12920);
                        }
                    });
                    break;
                case 2:
                    reportName = this.props.typeFormat == "movein" ? "MoveInExcel.xls" : "LoadfncExcel.xls";
                    data1 = "reportName\fREPORT_LOCALE";
                    data2 = "reports/" + reportName + "\f" + this.getText(101);
                    params.forEach((x, i) => {
                        data1 += "\f" + x;
                        data2 += "\f" + encodeURIComponent(er[x]);
                    });
                    this.sendAPI("generate_report", data1 + "\r" + data2, (ob) => {
                        if (ob && ob.blob && ob.blob.size > 0) {
                            this.props.ActionQueue.addToQueue({ action: this.props.dataActions.generateReportSuccess, args: [ob.blob, reportName] });
                            this.generateFile5();
                        }
                    });
                    break;
            }
        });
    }

    generateFile5 = () => {
        this.setState({
            percent: 100,
            type: "success",
            status: "success",
            message: this.getText(12930),
        }, () => {
            setTimeout(() => {
                this.handleCancel();
            }, 3000);
        })
    }

    handleCancel = () => {
        this.sendAPI(this.api.g_file_delete, "", () => {
            this.props.uiActions.hideModal(TableIDs.modalProgress);
        })
    }

    errorCallback = (text) => {
        this.setState({
            status: "exception",
            message: this.getText(text),
            type: "error"
        });

    }

    sendAPI = (script, data, sCall) => {
        let outputType = script === "generate_report" ? "OUTPUT_TYPE_REPORT_A4" : "OUTPUT_TYPE_DATA";
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType[outputType], ProcessType["PROCESS_TYPE_SYNC"]);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCall, () => { this.errorCallback(12922) });
    };

    printKupainFile = () => {
        const { mStartDate, mEndDate, mPayTypeList } = this.state;
        const { mByPos, mPosList, mBranches, mFileType, mDocs, mDefCust } = this.props.data[this.id].editedRecord;

        let dataSend = "mStartDate\fmEndDate\fmByPos\fmPosList\fmBranches\fmDocs\fmPayTypes\fmDefCust\r" +
            (mStartDate ? mStartDate : "") + "\f" +
            (mEndDate ? mEndDate : "") + "\f" +
            (mByPos ? mByPos : "") + "\f" +
            (mPosList ? mPosList : "") + "\f" +
            (mBranches ? mBranches : "") + "\f" +
            (mDocs ? mDocs : "") + "\f" +
            (mPayTypeList ? mPayTypeList : "") + "\f" +
            (mDefCust ? mDefCust : "");

        this.sendAPI(this.api.kupain_file, dataSend, (ob) => {
            if (ob.data) {
                let newData = ob.data.split("\r");
                newData = newData.map((x) => {
                    let y = x.split("\f");
                    let heRegexp = /[א-ת]/;
                    if (y[6] && y[6].search(heRegexp) > -1) y[6] = this.reverseHe(y[6]);
                    return y;
                })

                let blobData = newData.map((x) => x.slice(1, -1).join("")).join("\r\n");

                let text = blobData + "\r\n";
                let uint8 = new Uint8Array(text.length);
                for (let i = 0; i < uint8.length; i++) {
                    let b = text.charCodeAt(i);
                    // if (b >= 1488 && b <= 1514) b -= 1360;
                    uint8[i] = (b >= 1488 && b <= 1514) ? b - 1360 : b;
                }

                this.printFile(uint8, 'text/plain;charset=cp862', mFileType === "Dat" ? "kupain.dat" : "kupain.doc")

                // let rl = parseInt(er['mRefLen']);
                // let al = parseInt(er['mAccntLen']);
                // let prmData = [3, rl, rl, 10, 10, 0, 0, 22, al, al, al, al, 12, 12, 12, 12];
                // let rowLen = 0;
                // let prmPrint = []
                // prmData.map((x) => {
                //     let posStart = x ? rowLen + 1 : 0;
                //     let posEnd = x ? rowLen + x : 0;
                //     prmPrint.push(posStart + ' ' + posEnd);
                //     rowLen += x;
                // });

                // prmPrint = [rowLen, ...prmPrint]

                // this.printFile(prmPrint.join(";\r\n") + ';', 'text/plain', "kupain.prm");
                this.setState({ modalKupain: false })
            }
        })
    }

    printFile = (data, type, fileName, encoding) => {
        let blob = new Blob([data], { type, encoding });
        let anchor = document.createElement('a');
        anchor.download = fileName;
        anchor.href = (window.webkitURL || window.URL).createObjectURL(blob);
        anchor.dataset.downloadurl = [type, anchor.download, anchor.href].join(':');
        anchor.click();
    }

    validParamsToSend = () => {
        if (this.props.typeFormat == "movein" && ["mHesh", "mZikuy", "mPatur", "mBuy", "mRicuzH", "mRicuzP"].find(f => !this.props.data[this.id].editedRecord[f])) {
            message.error(this.getText(19270));
        } else if (["mDefCust", "mDefSpecCust", "mInH", "mInP"].find(f => !this.props.data[this.id].editedRecord[f])) {
            message.error(this.getText(19271));
        } else {
            return true;
        }
    }

    generateSalesReport = () => {
        const { mByPos, mPosList, mBranches } = this.props.data[this.id].editedRecord;
        const { mStartDate, mEndDate } = this.state;

        const generateReport = (StorageList, PosList, viewStorages) => {

            let params = {
                LowerSelectionDate: mStartDate,
                LowerSelectionHour: "00:00:00",
                UpperSelectionDate: mEndDate,
                UpperSelectionHour: "23:59:59",
                StorageList,
                PosList,
                EmployeeList: '',
                TagList: '',
                AllHours: '1',
                viewStorages,
                viewEmployees: '0',
                viewTags: 0,
                viewDays: 0,
                daysList: '1,2,3,4,5,6,7',
                _withSendingDocs: '5',
                centralPerHours: 0,
                reportName: "reports/SalesDays.pdf",
                REPORT_LOCALE: this.getText(101)
            }

            this.props.ActionQueue.addToQueue({
                action: this.props.dataActions.generateReport,
                args: [this.props.user.companyCode, this.props.user.token, params]
            });

        }

        if (mByPos == '1') {
            generateReport("", mPosList, '2');
        } else {
            this.sendAPI("get_storages_from_branch_list", "_brancList\r" + mBranches, ob => {
                generateReport(ob.data, "", '1');
            })
        }

    }



    smActions = (t, record) => {
        return (<TableLineIcons
            genericIcons={[
                {
                    type: "delete",
                    tooltip: this.getText(19812),
                    onClick: () => {
                        this.props.dataActions.genericDataDeleteRow(this.sm_id, record.key, this.props.user.companyCode, this.props.user.token, this.api.reset_sm, this.api.get_sm)
                    },
                    popconfirm: { title: this.getText(19809), okText: this.getText(19810), cancelText: this.getText(19811) }
                }, {
                    type: "table",
                    tooltip: this.getText(19813),
                    onClick: () => {
                        this.setState({ modalSMView: record.step }, () => {
                            this.props.dataActions.genericDataSetFilter(this.sm_data_id, this.props.user.companyCode, this.props.user.token, { step: record.step }, this.api.get_sm_data);
                        })
                    },
                }
            ]}
        />)
    }

    renderShowDoc = (t, record) => {
        return (<ReportShowButton
            {...this.props}
            iconButton
            validSubmit
            params={{
                DocumentNumber: record.num,
                DocumentTypeID: record.type,
                DocumentPOSID: record.pos,
                userID: this.props.user.userId,
                userName: encodeURIComponent(this.props.user.displayName),
                CompanyCode: this.props.user.companyCode,
                isOriginal: false,
                CentralFatherItem: 0,
                PrintFormat: record.type == 5 ? 1 : null,
                docIsWithSigns: 0,
                reportName: record.type == 5 ? "reports/DocumentCopyCentral.pdf" : "reports/DocumentCopy.pdf",
                REPORT_LOCALE: this.getText(101)
            }} />)
    }

    render() {

        const divStyle = {
            dir: "rtl", // dir: 'ltr'
            padding: "2% 2% 2% 2%",
            width: '100%',
        };

        let fieldStyle = (label, content, width) => {
            let resWidth = (n) => window.innerWidth > 600 ? n + "%" : "100%";
            return (<div style={{ paddingTop: 5 }}>
                <div style={{ display: 'inline-block', width: resWidth(width) }}>{this.getText(label)} : </div>
                <div style={{ display: 'inline-block', width: resWidth(100 - width) }}>{content}</div>
            </div>)
        }

        let editedRecord = this.props.data[this.id].editedRecord;

        let updateIn = (f, v) => this.props.dataActions.genericDataSetEdit(this.id, { [f]: v });

        let tInput = (f) => (<Input style={{ width: 300 }} value={editedRecord[f]} onChange={(v) => updateIn(f, v.target.value)} />)

        let radioInput = (field, options) => {
            let list = options.map((x) => (<Radio value={x.code}>{x.name}</Radio>))
            return (<Radio.Group onChange={(e) => { updateIn(field, e.target.value) }} value={editedRecord[field]}>{list}</Radio.Group>)
        }

        let multiSelector = (field, num, filter) => {
            const props = {
                ...this.props,
                multi: true,
                style: { width: 400 },
                value: editedRecord[field] ? editedRecord[field].split(",") : [],
                onChange: (e) => { updateIn(field, e.join(",")) },
                filterList: filter
            }

            return field === 'mPosList' ? (<GenericSelectorPosStorages {...props} />) : (<GenericSelector {...props} id={this.selectors[num].id} />)
        }

        let dPicker = (label, field) => {
            let v = this.state[field] ? moment(this.state[field], "YYYY-MM-DD") : null;
            let minDate = field == 'mEndDate' ? this.state.mStartDate : null
            let maxDate = field == 'mStartDate' ? this.state.mEndDate : null
            return (<span>
                <span>{this.getText(label)}</span>
                <DatePicker
                    style={{ marginRight: 5, marginLeft: 5 }}
                    format="DD-MM-YYYY" value={v}
                    disabledDate={d => disabledDatePicker(d, minDate, maxDate)}
                    onChange={(e) => { this.setState({ [field]: e.format("YYYY-MM-DD") }) }}
                />
            </span>)
        }

        let cButton = (text, onClick) => {
            return (<div style={{ display: 'inline-block', width: "20%", textAlign: "center" }}>
                <Button type="primary" onClick={onClick}>{text}</Button>
            </div>)
        }

        let gbClick = (type) => {
            if (this.validParamsToSend()) {
                this.setState({ mFileType: type }, () => {
                    this.props.uiActions.showModal(TableIDs.modalProgress);
                    this.generateFile1();
                })
            }
        }

        return (<div style={divStyle} >
            <PageToolbar
                title={this.props.title}
                actionButtons={this.actionButtons}
                ui={this.props.ui}
                uiActions={this.props.uiActions}
                data={this.props.data}
                dataActions={this.props.dataActions}
            />
            {fieldStyle(12886, [dPicker(12887, "mStartDate"), dPicker(12888, "mEndDate")], 20)}
            {editedRecord['mByPos'] === '1' ?
                fieldStyle(12975, multiSelector("mPosList", 2), 20) :
                fieldStyle(12889, multiSelector("mBranches", 0), 20)
            }
            {this.props.typeFormat == "movein" ? [
                fieldStyle(12890, radioInput("mFileType", [
                    { code: "Doc", name: "Doc" },
                    { code: "Dat", name: "Dat" },
                ]), 20),
                fieldStyle(12891, radioInput("mRefLen", [
                    { code: "5", name: "5 " + this.getText(12894) },
                    { code: "8", name: "8 " + this.getText(12894) },
                ]), 20),
                fieldStyle(12892, radioInput("mAccntLen", [
                    { code: "8", name: "8 " + this.getText(12894) },
                    { code: "15", name: "15 " + this.getText(12894) },
                ]), 20)
            ] : ""}
            {fieldStyle(12893, multiSelector("mDocs", 1, ['1', '2', '3', '5', '16', '17', '20', '21', '22', '23', '42', '43']), 20)}
            {fieldStyle(12895, tInput("mDefCust"), 40)}
            {fieldStyle(12896, radioInput("mCollect1", [{ code: "1", name: this.getText(12902) }, { code: "2", name: this.getText(12903) }]), 40)}
            {fieldStyle(12897, radioInput("mCollect2", [{ code: "1", name: this.getText(12902) }, { code: "2", name: this.getText(12903) }]), 40)}
            {fieldStyle(12898, radioInput("mCollect3", [{ code: "1", name: this.getText(12902) }, { code: "2", name: this.getText(12903) }]), 40)}
            {fieldStyle(12899, radioInput("mCollect4", [{ code: "1", name: this.getText(12902) }, { code: "2", name: this.getText(12903) }]), 40)}
            {fieldStyle(12900, radioInput("mCollect5", [{ code: "1", name: this.getText(12904) }, { code: "2", name: this.getText(12905) }]), 40)}
            {fieldStyle(12901, radioInput("mNOD", [{ code: "1", name: this.getText(12906) }, { code: "2", name: this.getText(12907) }, { code: "3", name: this.getText(12908) }]), 40)}
            {fieldStyle(19650, radioInput("mSuppRef", [{ code: "1", name: this.getText(12906) }, { code: "2", name: this.getText(12907) }]), 40)}

            <div style={{ marginTop: 20 }}>
                {cButton(this.getText(18359) + " " + this.props.typeFormat, () => { gbClick(1) })}
                {cButton(this.getText(18359) + " Excel", () => { gbClick(2) })}
                {cButton(this.getText(12911), () => { this.props.uiActions.showModal(TableIDs.modalChangeMoveinFormat) })}
                {cButton(this.getText(19803), () => { this.setState({ modalStatusManager: true }, this.refreshDataSM) })}
                {cButton(this.getText(19598), this.generateSalesReport)}
            </div>
            <ModalChangeMoveinFormat
                id={TableIDs.modalChangeMoveinFormat}
                onChange={(id) => { this.initFilter(id) }}
                user={this.props.user} ActionQueue={this.props.ActionQueue}
                uiActions={this.props.uiActions} ui={this.props.ui}
                dataActions={this.props.dataActions} data={this.props.data}
                toggle={this.props.ui.modalStack.includes(TableIDs.modalChangeMoveinFormat)}
            />
            <ModalProgress
                id={TableIDs.modalProgress}
                uiActions={this.props.uiActions} ui={this.props.ui}
                dataActions={this.props.dataActions} data={this.props.data}
                toggle={this.props.ui.modalStack.includes(TableIDs.modalProgress)}
                title={this.getText(18359) + " " + (this.state.mFileType === 1 ? this.props.typeFormat : "Excel")}
                handleCancel={this.handleCancel}
                handleRefrash={this.generateFile1}
                handleIgnore={this.state.status == "exception2" ? this.generateFile2 : this.generateFile4}
                percent={this.state.percent}
                status={this.state.status}
                message={this.state.message}
                type={this.state.type}
            />
            <GenericModal
                visible={this.state.modalKupain}
                onCancel={() => { this.setState({ modalKupain: false }) }}
                title={this.getText(17203)}
                width={500}
                footer={[
                    <Button onClick={() => { this.setState({ modalKupain: false }) }}>{this.getText(17206)}</Button>,
                    <Button type={"primary"} onClick={this.printKupainFile}>{this.getText(17205)}</Button>
                ]}>
                <Form.Item label={this.getText(17204)}>
                    <GenericSelector
                        {...this.props}
                        id={this.selectors[3].id}
                        multi
                        value={this.state.mPayTypeList ? this.state.mPayTypeList.split(',') : []}
                        onChange={e => { this.setState({ mPayTypeList: e ? e.join(',') : '' }) }}
                    />
                </Form.Item>
            </GenericModal>

            <GenericModal
                visible={this.state.modalStatusManager}
                onCancel={() => { this.setState({ modalStatusManager: false }) }}
                title={this.getText(19804)}
                width={500}>
                <ResponsiveTable
                    tableOnly
                    idTable={this.props.data[this.sm_id]}
                    columns={[
                        { title: this.getText(19805), key: "step", dataIndex: "step", width: "20%", render: t => t },
                        { title: this.getText(19806), key: "datetime", dataIndex: "datetime", width: "30%", render: t => (<div dir="ltr">{moment(t).format("DD/MM/YYYY HH:mm")}</div>) },
                        { title: this.getText(19807), key: "count", dataIndex: "count", width: "30%", render: t => t },
                        { width: "20%", render: this.smActions },
                    ]}
                    pagination={{ pageSize: 10 }}
                />

            </GenericModal>

            <GenericModal
                visible={this.state.modalSMView != null}
                onCancel={() => { this.setState({ modalSMView: null }) }}
                title={this.getText(19814) + " " + this.state.modalSMView}
                width={1000}>
                <ResponsiveTable
                    tableOnly
                    idTable={this.props.data[this.sm_data_id]}
                    columns={[
                        { title: this.getText(19815), key: "pos", dataIndex: "pos", width: "10%", render: t => t },
                        { title: this.getText(19816), key: "num", dataIndex: "num", width: "15%", render: t => t },
                        { title: this.getText(19817), key: "typeName", dataIndex: "typeName", width: "15%", render: t => t },
                        { title: this.getText(19818), key: "datetime", dataIndex: "datetime", width: "15%", render: t => moment(t).format("DD/MM/YYYY") },
                        { title: this.getText(19819), key: "custName", dataIndex: "custName", width: "30%", render: t => (<div style={{ textAlign: 'right' }}>{t}</div>) },
                        { title: this.getText(19820), key: "sum", dataIndex: "sum", width: "10%", render: t => (<div style={{ textAlign: 'right' }}>{parseFloat(t)?.toFixed(2)}</div>) },
                        { width: "5%", render: this.renderShowDoc },
                    ]}
                    pagination={{ pageSize: 10 }}
                />

            </GenericModal>
        </div >)
    }
}
export default JournalEntriesFormat;