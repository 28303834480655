import React from "react";
import "../App.css";
import { Form, Button, Input, message, Tooltip } from 'antd';
import LangContext from "../contextProvider/LangContext";
import GenericModal from "./GenericModal";
import GenericSelector from "./GenericSelector";
import { Job, User, OutputType, ProcessType } from '../JmReact';
import { join } from "lodash";
import TableIDs from "../data/TableIDs";
import GenericSelectorPosStorages from "./GenericSelectorPosStorages";

type Props = {
    datesToFavorites: [
        { field: String, label: String, format: Number }
    ],
    validSubmit: Function,
    data: Object,
    dataActions: Object,
    ui: Object,
    uiActions: Object,
    user: Object,
}

class ReportFavoriteSave extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            modalOpen: false,
            favoriteDesc: '',
            favoritePosList: '',
            favoriteDates: [],
        }

        this.id = "REPORT_FAVORITE_DINAMIC_VALUE_TYPES";
        this.api = {
            selector: "get_report_favorite_dinamic_value_types",
            save: "create_edit_report_favorites",
            save_params: "create_report_favorite_params",
        }

        if (props.data[this.id] === undefined) props.data[this.id] = { dataset: [] }
        if (props.data[TableIDs.genericSelectorPos] === undefined) props.data[TableIDs.genericSelectorPos] = { dataset: [] }
    }


    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        this.sendAPI(this.api.selector, "", (ob) => {
            let dataset = ob.data.split('\r').map(x => {
                let y = x.split('\f');
                return {
                    code: y[0],
                    name: y[1],
                    format: y[2]
                }
            });
            this.props.dataActions.setJson(this.id, { dataset })
        })
    }

    sendAPI = (script, data, sCal) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCal, e => { console.error(e) });
    }

    handleOk = () => {
        let _desc = this.state.favoriteDesc;
        let _posList = this.state.favoritePosList;
        let _report = this.props.params.reportName ?? "";
        let fieldsNotValue = this.state.favoriteDates.filter(f => !f.value);

        if (!_desc || fieldsNotValue.length) {
            message.error(this.getText(16595))
        } else {
            let _url = this.props.notSaveUrl ? "" : window.location.href.replace(window.location.origin, '');
            let _isNotReport = this.props.isNotReport ? "1" : "0";

            this.sendAPI(this.api.save, '_desc\f_report\f_url\f_posList\f_isNotReport\r' + _desc + '\f' + _report + '\f' + _url + '\f' + _posList + '\f' + _isNotReport, (ob) => {
                let paramsData = "_id\f_pName\f_pValue\f_pDynamicType";
                let _id = ob.data;
                Object.keys(this.props.params).forEach(x => {

                    let isDynamic = this.state.favoriteDates.find(f => f.field === x);
                    let isDynamic3 = this.state.favoriteDates.find(f => f.format === 2 && f.fields && f.fields[0] === x);
                    let isDynamic4 = this.state.favoriteDates.find(f => f.format === 2 && f.fields && f.fields[1] === x);
                    let isDynamic21 = this.state.favoriteZ?.field === x && this.state.favoriteZ?.value && this.state.favoriteZ?.pos ? this.state.favoriteZ : null;

                    let valueType = '0';
                    let paramValue = this.props.params[x];

                    if (isDynamic) {
                        valueType = isDynamic.value;
                    }
                    if (isDynamic21) {
                        valueType = isDynamic21.value;
                        paramValue = isDynamic21.pos;
                    }

                    if (isDynamic3 && isDynamic3.value) {
                        let splitValue = isDynamic3.value.split('@')
                        if (splitValue[0]) valueType = splitValue[0];
                    }

                    if (isDynamic4 && isDynamic4.value) {
                        let splitValue = isDynamic4.value.split('@')
                        if (splitValue[1]) valueType = splitValue[1];
                        else if (splitValue[0]) valueType = splitValue[0];
                    }

                    paramsData += '\r' + _id + '\f' + x + '\f' + paramValue + '\f' + valueType;

                });
                this.sendAPI(this.api.save_params, paramsData, () => {
                    this.setState({ modalOpen: false }, () => { message.success(this.getText(16594)) })
                });
            })
        }
    }

    onClickBtn = () => {
        let validSubmit = this.props.validSubmit ? this.props.validSubmit() : true;
        if (validSubmit) {
            let favoriteDesc = this.props.favoriteDesc ? this.props.favoriteDesc : document.title.split("Cash On Tab - ").join('');
            let favoriteDates = this.props.datesToFavorites ? this.props.datesToFavorites : [];
            let favoriteZ = this.props.zToFavorites ? this.props.zToFavorites : null;

            this.setState({ modalOpen: true, favoriteDesc, favoriteDates, favoriteZ })
        }
    }

    render() {
        const btnStyle = { marginLeft: 5, marginRight: 5 }
        let btnText = this.props.btnText ? this.props.btnText : this.getText(16589);
        let btnClick = (<Button style={btnStyle} onClick={this.onClickBtn}>{btnText}</Button>)
        return (<span>
            {this.props.btnTooltip ? (<Tooltip title={this.props.btnTooltip}>{btnClick}</Tooltip>) : btnClick}
            <GenericModal
                visible={this.state.modalOpen}
                onCancel={() => { this.setState({ modalOpen: false }) }}
                width={600}
                title={this.getText(this.props.isNotReport ? 19266 : 16590)}
                footer={[
                    <Button style={{ marginLeft: 5 }} onClick={() => { this.setState({ modalOpen: false }) }}>{this.getText(16591)}</Button>,
                    <Button style={{ marginLeft: 5 }} onClick={this.handleOk} type="primary">{this.getText(16592)}</Button>,
                ]} >
                {this.props.isNotReport ? "" : [
                    <Form.Item label={this.getText(16593)}>
                        <Input value={this.state.favoriteDesc} onChange={(e) => { this.setState({ favoriteDesc: e.target.value }) }} />
                    </Form.Item>,
                    <Form.Item label={this.getText(17777)}>
                        <GenericSelectorPosStorages
                            {...this.props}
                            multi
                            // id={TableIDs.genericSelectorPos}
                            // api={"list_pos"}
                            value={this.state.favoritePosList ? this.state.favoritePosList.split(",") : []}
                            onChange={e => { this.setState({ favoritePosList: e ? e.join(",") : "" }) }}
                        />
                    </Form.Item>
                ]}
                {this.state.favoriteDates ?
                    this.state.favoriteDates.map((x, i) => {
                        let filterList = this.props.data[this.id].dataset
                            .filter(f => f.format == '0' || f.format == x.format || (f.format == '1' && x.format === undefined))
                            .map(y => y.code)
                        return (<Form.Item label={x.label}>
                            <GenericSelector
                                {...this.props}
                                id={this.id}
                                value={x.value}
                                onChange={e => {
                                    let favoriteDates = [...this.state.favoriteDates];
                                    favoriteDates[i] = { ...favoriteDates[i], value: e }
                                    this.setState({ favoriteDates })
                                }}
                                filterList={filterList}
                                notCodeView />
                        </Form.Item>)
                    })
                    : ""}

                {this.state.favoriteZ ?
                    (<Form.Item label={this.state.favoriteZ.label}>
                        <GenericSelector
                            {...this.props}
                            id={this.id}
                            value={this.state.favoriteZ.value}
                            onChange={e => { this.setState({ favoriteZ: { ...this.state.favoriteZ, value: e } }) }}
                            filterList={this.props.data[this.id].dataset.filter(f => f.format == '21').map(y => y.code)}
                            notCodeView />
                        <GenericSelectorPosStorages
                            {...this.props}
                            value={this.state.favoriteZ.pos}
                            onChange={e => { this.setState({ favoriteZ: { ...this.state.favoriteZ, pos: e } }) }}
                        />
                    </Form.Item>)
                    : ""}
            </GenericModal>
        </span>)
    }
}

export default ReportFavoriteSave;