
import React from "react";
import "../App.css";
import { Form, Row, Col, Input, Icon, Button, InputNumber, Tooltip, Modal, Table, Checkbox, Select, message, Radio } from 'antd';
import { DatePicker } from 'antd';
import moment from "moment";
import Jax from "../Jax/jax.es6.module";
//import ExtendedTable from "./ExtendedTable";
import { Job, User, OutputType, ProcessType } from '../JmReact';
import TableIDs from '../data/TableIDs.js';
import PageToolbar from "./PageToolbar";
//import ItemPicker from "./ItemPicker";
import SelectorItems from "./SelectorItems";
import LangContext from "../contextProvider/LangContext";
import CustPicker from "./CustPicker";
import DateTimePicker from "./DateTimePicker";
import { escapeRegExp } from "../utils/utils";
import GenericSelectorItemTags from "./GenericSelectorItemTags";
import GenericSelector from "./GenericSelector";
import GenericSelectorCustTags from "./GenericSelectorCustTags";
import GenericSelectorSuppliers from "./GenericSelectorSuppliers";
import GenericSelectorPosStorages from "./GenericSelectorPosStorages";

const FormItem = Form.Item;
const { Option, OptGroup } = Select;
/*
type Props = {
    //title: string,
    apiCalls: any[],
    api_create: any,
    text:any,
};
*/
//type State = {}


type TagObj = {
    type: {
        id: string,
        text: string,
    },
    tags: {
        id: string,
        text: string,
    }[],
}

class ModalAdvanceSearchReports extends React.Component/*<Props, State>*/ {
    // props: user,tableFormat,apiCalls
    constructor(props/*: Props*/) {
        super(props);

        this.id = "ADVANCE_SEARCH_REPORTS";

        if (props.data[this.id] === undefined) { props.data[this.id] = { ...props.data.genericData }; }


        this.api = {
            selector_pos: "list_pos",
            selector_employees: "get_employee_list",
            //selectorMulti_itemtag: "get_tags_with_types",
            //selector_storages: "get_storage_list",
            //selector_item_tagTypes: "get_tag_types_with_relation_list",//
            //selector_item_group: "get_item_group_list",
            //selector_supplier: "get_suppliers",
            //selector_payment_types: "get_payment_types_list",
            selector_docTypes: "get_document_types_list",
            selector_customers: "get_customers_list_v3",
            selector_storages: "get_storage_list",
        }

        this.barcodeReaderBuffer = '';
        this.isBufferFull = false;
        this.ctrlBKeyFlag = false;


        this.tableApi = {
            get: "get_doc_search_table_list",
        }

        this.actionButtons = [{
            buttonType: 'refresh',
            buttonFunction: this.refreshData
        }];

        this.state = {
            loading: false,
            visible: false,
            /*
            request: {
                _words: '',
                _FromDate: undefined,
                _ToDate: undefined,
                _EmployeeList: '',
            },*/
            PaymentsSearch: {
                _id: 0,
                _words: '',
                mDocTypeList: this.props.isFromOrders ? '15' : '',
                mPosList: '',
                mLowerSelectionDate: moment().add(-30, 'days').format('YYYY-MM-DD'),
                mLowerSelectionTime: '00:00:00',
                mUpperSelectionDate: moment().format('YYYY-MM-DD'),
                mUpperSelectionTime: '23:59:59',
                mApplyCreditCardSearchQuery: null,
                mIsCustomerActive: 1,
                mCustomer: null,
                mItemCode: null,
                mEmployeeList: '',
                mStorageList: '',
                mDocumentIsClosed: this.props.isFromOrders ? 1 : 0,
                mSumFrom: null,
                mSumTo: null,
                mSumAbsolute: 0,
                mSupplierDoc: null,
                mSupplierDocDateFrom: null,
                mSupplierDocDateTo: null,
                mTagList: '',
                mCustTagList: '',
                searchColFilter: "4",
                mCustWithConnected: "0",
                mItemSerialNumber: '',
                mCustomerList: '',
                mCustType: this.props.isFromOrders ? "2" : "0",
            },
            isConnectedCust: false,
        }
        //this.props.dataActions.genericSelectorRefreshDataset(
        //    TableIDs.genericSelectorEmployees, this.props.user.companyCode, this.props.user.token, this.api.selector_employees);
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }


    // componentWillReceiveProps(newProps) {
    //     //alert("on props: " + JSON.stringify(newProps));        
    //     // let _state = this.state;
    //     // _state.visible = newProps.toggle;
    //     this.setState({ visible: newProps.toggle });

    //     //this.props.ActionQueue.addToQueue({
    //     //    action: this.props.dataActions.genericSelectorRefreshDataset,
    //     //    args: [TableIDs.genericSelectorEmployees, this.props.user.companyCode, this.props.user.token, this.api.selector_employees]
    //     //});
    // }

    refreshData = () => {
        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.genericSelectorRefreshDataset,
            args: [TableIDs.genericSelectorPos, this.props.user.companyCode, this.props.user.token, this.api.selector_pos]
        });
        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.genericSelectorRefreshDataset,
            args: [TableIDs.genericSelectorEmployees, this.props.user.companyCode, this.props.user.token, this.api.selector_employees]
        });
        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.genericSelectorRefreshDataset,
            args: [TableIDs.genericSelectorStorages, this.props.user.companyCode, this.props.user.token, this.api.selector_storages]
        });
    }
    /*
    showModal = () => {
        this.setState({
            visible: true,
        });
    }*/
    handleOk = () => {
        console.log("search...");
        //1
        let fullPosList = Jax.get(this.props.data, TableIDs.genericSelectorPos + ".dataset", []).map((obj) => obj['code']).join(',');
        this.props.dataActions.genericDataSetFilter(
            'DOC_SEARCH_TABLE', this.props.user.companyCode, this.props.user.token,
            {
                ...this.state.PaymentsSearch,
                //2
                mPosList: this.state.PaymentsSearch.mPosList === '' ? fullPosList : this.state.PaymentsSearch.mPosList,
                mDocTypeList: this.props.isFromOrders ? '15' : this.state.PaymentsSearch.mDocTypeList,
                _words: escapeRegExp(this.state.PaymentsSearch._words)
                // this.state.PaymentsSearch._words.trim()
                //     .replace(/\s\s+/g, ' ')
                //     .replace(/\'/g, "\\\'")
            }, this.tableApi.get);

        this.setState({ visible: false });
        this.props.uiActions.hideModal(this.props.id);
    }
    handleCancel = () => {
        // TODO - clear field and set editable cells to 'closed'
        this.setState({ visible: false });
        this.props.uiActions.hideModal(this.props.id);
    }
    /*
    disabledStartDate = (mFormDate) => {
        const _ToDate = this.state._ToDate;
        if (!mFormDate || !_ToDate) {
            return false;
        }
        return mFormDate.valueOf() > _ToDate.valueOf();
    }

    disabledEndDate = (_ToDate) => {
        const mFormDate = this.state.mFormDate;
        if (!_ToDate || !mFormDate) {
            return false;
        }
        return _ToDate.valueOf() <= mFormDate.valueOf();
    }
    */

    onChange = (field, value) => {
        //this.setReportParameters("HoursReport", { [field]: value })
        let r = this.state.PaymentsSearch;
        this.setState({
            PaymentsSearch: {
                ...r, [field]: value,
            }
        });
    }

    onStartChange = (value) => {
        this.onChange('mLowerSelectionDate', value ? value.format("YYYY-MM-DD") : null);
    }

    onEndChange = (value) => {
        this.onChange('mUpperSelectionDate', value ? value.format("YYYY-MM-DD") : null);
    }
    /*
    handleStartOpenChange = (open) => {
        if (!open) {
            this.setState({ endOpen: true });
        }
    }

    handleEndOpenChange = (open) => {
        this.setState({ endOpen: open });
    }*/

    createCheckBox = (field, data) => {
        return (
            <Checkbox
                style={{ display: 'inline' }}
                onChange={(e) => {
                    if (e.target.checked)
                        this.setReportParameters("PaymentsSearch", { [field]: data });
                    else
                        this.setReportParameters("PaymentsSearch", { [field]: '' });
                }} />);
    }

    loadData() {
        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.genericSelectorRefreshDataset,
            args: [TableIDs.genericSelectorPos, this.props.user.companyCode, this.props.user.token, this.api.selector_pos]
        });

        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.genericSelectorRefreshDataset,
            args: [TableIDs.genericSelectorDocumentTypes, this.props.user.companyCode, this.props.user.token, this.api.selector_docTypes]
        });

        // this.props.ActionQueue.addToQueue({
        //     action: this.props.dataActions.genericSelectorRefreshDataset,
        //     args: [TableIDs.genericSelectorCustomers, this.props.user.companyCode, this.props.user.token, this.api.selector_customers, { _words: null }]
        // });

        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.genericSelectorRefreshDataset,
            args: [TableIDs.genericSelectorEmployees, this.props.user.companyCode, this.props.user.token, this.api.selector_employees]
        });

        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.genericSelectorRefreshDataset,
            args: [TableIDs.genericSelectorStorages, this.props.user.companyCode, this.props.user.token, this.api.selector_storages]
        });

        this.props.dataActions.getItemCodes(this.props.user.companyCode, this.props.user.token);
    }

    setReportParameters(report: string, params: Object) {
        console.log(params);
        let newState = Jax.mutate(this.state || {}, report, params);
        console.log(newState);
        this.setState(newState,
            () => { console.log("cc:  ", this.state.PaymentsSearch) }
        );
    }

    componentDidMount() {
        // document.title = "Cash On Tab - דו\"חות";
        this.loadData();
    }

    componentDidUpdate(prevProps) {
        if (this.props.data.DOC_SEARCH_TABLE && this.props.data.DOC_SEARCH_TABLE.filters !== prevProps.data.DOC_SEARCH_TABLE.filters) {
            this.setState({
                PaymentsSearch: {
                    ...this.state.PaymentsSearch,
                    ...this.props.data.DOC_SEARCH_TABLE.filters
                }
            })
        }
    }

    checkIfItemExist = (s) => {
        if (s) {
            let dataSend = "_itemCode\f_isWithMatrix\f_isWithFather\r" + s + "\f1\f0";
            let user = new User(this.props.user.token, this.props.user.companyCode);
            let job = new Job(user, "check_all_item_code", OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
            job.setInput(dataSend);
            job.send("/cgi-bin/CashOnTab",
                (ob) => {
                    let itemsRows = (ob.data).split("\r");
                    let _ItemCode = itemsRows[0].split('\f')[1];
                    ////console.log('yuda items selector ', itemsRows, _ItemCode)
                    if (!_ItemCode || itemsRows.length > 1) {
                        this.props.uiActions.showModal(TableIDs.SelectorItemsModal/*, modalParamObj*/);
                    } else {

                        this.onChangeItemCode(_ItemCode)
                    }
                },
                (error) => {
                    message.error(this.getText(11200));
                }
            );
        }
    }

    keyboardListener(e) {
        ////console.log('yuda test ', this.barcodeReaderBuffer, m);
        switch (e.key) {
            case "b": case "B": case "נ": case "M": case "m": case "צ":
                if (e.ctrlKey) {
                    this.onChangeItemCode('');
                    this.ctrlBKeyFlag = true;
                    this.barcodeReaderBuffer = '';
                }
                break;

            case "Enter":
                if (this.ctrlBKeyFlag == true) this.ctrlBKeyFlag = false;
                if (this.barcodeReaderBuffer || this.isBufferFull === true) this.checkIfItemExist(this.barcodeReaderBuffer)
                break;

            default: break;
        }
    }

    onChangeItemCode = (code) => {
        if (this.ctrlBKeyFlag === true && this.isBufferFull === false) {
            this.barcodeReaderBuffer = this.barcodeReaderBuffer + code;
        } else {
            this.setReportParameters("PaymentsSearch", { mItemCode: code })
        }
    }

    checkIfCustIsConnected = (cust) => {
        this.sendAPI("check_cust_if_primary_connected", cust, ob => {
            this.setState({ isConnectedCust: ob.data > 0 })
        })
    }


    sendAPI = (script, data, sCal) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCal, (e) => { console.error(e) });
    }


    render() {
        console.log("render - modal advance search ");
        const { visible, loading, endOpen } = this.state;
        //const { _FromDate, _ToDate } = this.state.request;

        //const formItemLayout = {
        //    labelCol: { span: 4 },
        //    wrapperCol: { span: 14 },
        //}

        let modalStyle = {
            dir: 'rtl',
            textAlign: 'right',
        };


        let titleBar = (
            <div style={{
                paddingLeft: '4px',
                paddingRight: '4px',
                paddingTop: '4px',
                paddingBottom: '0px',
            }}>
                <PageToolbar
                    title={this.getText(11185)}
                    actionButtons={this.actionButtons}
                    isModal={true}
                    ui={this.props.ui} uiActions={this.props.uiActions}
                    data={this.props.data} dataActions={this.props.dataActions}
                />
            </div>)


        let mDocumentIsClosedTexts = this.props.isFromOrders ? [12804, 12805, 12806, 12807] : [16801, 16802, 16803, 16804]

        return (
            <Modal
                style={modalStyle}
                destroyOnClose={true}
                width={window.innerWidth > 1200 ? 1200 : '95%'}
                visible={this.props.toggle}
                title={titleBar}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                footer={/**/null}
            // <Button key="back" onClick={this.handleCancel}>ביטול</Button>,
            // <Button key="clear" onClick={this.handleReset}>ניקוי</Button>,
            // <Button key="create" type="primary" loading={loading} onClick={this.handleOk}>חיפוש</Button>,
            //]} 
            >
                <Form id="search_form" className="data-entry-form" layout='horizontal' //layout='vertical' //'horizontal' // inline  |'vertical'|'inline'
                    style={{ columnWidth: 'auto', columnCount: window.innerWidth > 600 ? '3' : '1' }}
                    onSubmit={this.handleSaveChanges}
                >
                    <FormItem label={this.getText(11184)} /*mLowerSelectionDate - mLowerSelectionTime*/>
                        {/* <DatePicker
                            //disabledDate={this.disabledStartDate}
                            showTime
                            format="DD-MM-YYYY"//"YYYY-MM-DD"
                            //format="D/M/YY"
                            value={(this.state.PaymentsSearch.mLowerSelectionDate)
                                ? moment(this.state.PaymentsSearch.mLowerSelectionDate, "YYYY-MM-DD") : null}
                            placeholder={this.getText(11201)}
                            onChange={this.onStartChange}
                        //onOpenChange={this.handleStartOpenChange}
                        /> */}
                        <DateTimePicker
                            format="DD-MM-YYYY"//"YYYY-MM-DD"
                            maxDate={this.state.PaymentsSearch.mUpperSelectionDate}
                            value={this.state.PaymentsSearch.mLowerSelectionDate}
                            placeholder={this.getText(11201)}
                            onChange={(value) => { this.onChange('mLowerSelectionDate', value) }}
                        />

                    </FormItem>
                    <FormItem label={this.getText(11187)} /*style={isEndDateShownStyle}*/>
                        {/* <DatePicker
                            //disabledDate={this.disabledEndDate}
                            showTime
                            format="DD-MM-YYYY"//"YYYY-MM-DD"
                            //format="D/M/YY"
                            value={(this.state.PaymentsSearch.mUpperSelectionDate)
                                ? moment(this.state.PaymentsSearch.mUpperSelectionDate, "YYYY-MM-DD") : null}
                            placeholder={this.getText(11202)}
                            onChange={this.onEndChange}
                        //open={endOpen}
                        //onOpenChange={this.handleEndOpenChange}
                        /> */}
                        <DateTimePicker
                            format="DD-MM-YYYY"//"YYYY-MM-DD"
                            minDate={this.state.PaymentsSearch.mLowerSelectionDate}
                            value={this.state.PaymentsSearch.mUpperSelectionDate}
                            placeholder={this.getText(11202)}
                            onChange={(value) => { this.onChange('mUpperSelectionDate', value) }}
                        />

                    </FormItem>

                    {this.props.isFromOrders ? "" : [
                        <FormItem label={this.getText(11188)} >
                            <GenericSelectorPosStorages
                                {...this.props}
                                multi
                                onChange={(value) => this.setReportParameters("PaymentsSearch", { mPosList: value.join(',') })}
                                value={
                                    (this.state.PaymentsSearch)
                                        ? ((this.state.PaymentsSearch.mPosList === '') ? [] : this.state.PaymentsSearch.mPosList.split(','))
                                        : []
                                }
                            />
                        </FormItem>,

                        <FormItem label={this.getText(11189)} >
                            <GenericSelector
                                {...this.props}
                                multi
                                id={TableIDs.genericSelectorDocumentTypes}
                                onChange={(value) => this.setReportParameters("PaymentsSearch", { mDocTypeList: value.join(',') })}
                                value={
                                    (this.state.PaymentsSearch)
                                        ? ((!this.state.PaymentsSearch.mDocTypeList) ? [] : this.state.PaymentsSearch.mDocTypeList.split(','))
                                        : []
                                }
                            />
                        </FormItem>,

                        <FormItem label={this.getText(11190)} >
                            <GenericSelector
                                {...this.props}
                                multi
                                id={TableIDs.genericSelectorEmployees}
                                onChange={(value) => this.setReportParameters("PaymentsSearch", { mEmployeeList: value.join(',') })}
                                value={
                                    //console.log("mposlist\n",this.state.PaymentsSearch.mPosList)
                                    (this.state.PaymentsSearch)
                                        ? ((this.state.PaymentsSearch.mEmployeeList === '') ? [] : this.state.PaymentsSearch.mEmployeeList.split(','))
                                        : []
                                }
                            />
                        </FormItem>,

                        <FormItem label={this.getText(11191)} >
                            <GenericSelector
                                {...this.props}
                                multi
                                id={TableIDs.genericSelectorStorages}
                                onChange={(value) => this.setReportParameters("PaymentsSearch", { mStorageList: value.join(',') })}
                                value={
                                    //console.log("mposlist\n",this.state.PaymentsSearch.mPosList)
                                    (this.state.PaymentsSearch)
                                        ? ((this.state.PaymentsSearch.mStorageList === '') ? [] : this.state.PaymentsSearch.mStorageList.split(','))
                                        : []
                                }
                            />
                        </FormItem>,

                        <FormItem /*label={this.getText(11192)}*/ >
                            <Radio.Group
                                value={this.state.PaymentsSearch.mCustType}
                                onChange={(value) => {
                                    this.setReportParameters("PaymentsSearch", {
                                        mCustType: value.target.value,
                                        mCustomer: null,
                                    })
                                }}>
                                <Radio value={"0"}>{this.getText(19716)}</Radio>
                                <Radio value={"1"}>{this.getText(19717)}</Radio>
                                <Radio value={"2"}>{this.getText(19718)}</Radio>
                            </Radio.Group>,
                            <br />,
                            {this.state.PaymentsSearch.mCustType == '1' ?
                                <CustPicker
                                    value={(this.state.PaymentsSearch) ? this.state.PaymentsSearch.mCustomer : null}
                                    onChange={(value) => {
                                        this.setReportParameters("PaymentsSearch", { mCustomer: value })
                                        this.checkIfCustIsConnected(value)
                                    }}
                                    defaultView={this.getText(12121)}
                                    data={this.props.data}
                                    dataActions={this.props.dataActions}
                                    uiActions={this.props.uiActions}
                                    user={this.props.user}
                                />
                                : this.state.PaymentsSearch.mCustType == '2' ?
                                    <GenericSelectorSuppliers
                                        {...this.props}
                                        value={(this.state.PaymentsSearch) ? this.state.PaymentsSearch.mCustomer : null}
                                        onChange={(value) => {
                                            this.setReportParameters("PaymentsSearch", { mCustomer: value })
                                        }}
                                    />
                                    : ""}
                        </FormItem>
                    ]}
                    <FormItem style={{ display: this.state.isConnectedCust ? "block" : "none" }}>
                        <Checkbox
                            checked={this.state.PaymentsSearch.mCustWithConnected == '1'}
                            onChange={(e) => {
                                this.setReportParameters("PaymentsSearch", { mCustWithConnected: e.target.checked ? "1" : "0" })
                            }}>{this.getText(18838)}</Checkbox>
                    </FormItem>
                    {this.props.isFromOrders ? [
                        <FormItem label={this.getText(19337)}  >
                            <GenericSelectorSuppliers
                                {...this.props}
                                multi
                                value={this.state.PaymentsSearch.mCustomerList ? this.state.PaymentsSearch.mCustomerList.split(",") : []}
                                onChange={(e) => {
                                    this.setReportParameters("PaymentsSearch", { mCustomerList: e ? e.join(",") : "" })
                                }}
                            />
                        </FormItem>,
                        <FormItem label={this.getText(11191)} >
                            <GenericSelector
                                {...this.props}
                                multi
                                id={TableIDs.genericSelectorStorages}
                                onChange={(value) => this.setReportParameters("PaymentsSearch", { mStorageList: value.join(',') })}
                                value={
                                    //console.log("mposlist\n",this.state.PaymentsSearch.mPosList)
                                    (this.state.PaymentsSearch)
                                        ? ((this.state.PaymentsSearch.mStorageList === '') ? [] : this.state.PaymentsSearch.mStorageList.split(','))
                                        : []
                                }
                            />
                        </FormItem>
                    ] : [
                        <FormItem label={this.getText(11193)}  /*mLowerSelectionDate - mLowerSelectionTime*/>
                            <Input
                                //ApplyRange_Lower
                                value={this.state.PaymentsSearch.mApplyCreditCardSearchQuery}
                                min={0} defaultValue={null} step={1} precision={0}
                                onChange={(value) => this.setReportParameters("PaymentsSearch", { mApplyCreditCardSearchQuery: value.target.value })}
                                //disabled={!this.state.PaymentsSearch.mDays}
                                style={{ marginLeft: '4px' }}
                            />
                        </FormItem>,


                        <FormItem label={this.getText(11194)}
                        //validateStatus={this.isItemValid()}
                        // hasFeedback={false}
                        //help={"בלה בלה"}
                        //extra={<label style={(this.isItemValid() === 'success') ? { display: 'none' } : { color: '#f5222d' }}>נדרש לבחור פריט להצגה</label>}
                        //required={true}
                        >
                            <SelectorItems
                                //doFocus={true}
                                user={this.props.user}
                                uiActions={this.props.uiActions}
                                ui={this.props.ui}
                                dataActions={this.props.dataActions}
                                data={this.props.data}
                                ActionQueue={this.props.ActionQueue}
                                ctrlBKeyFlag={null}
                                isExpandMatrixFatherItem={false}
                                itemType={1}
                                value={this.state.PaymentsSearch.mItemCode}
                                onChangeSearch={(s) => { this.onChangeItemCode(s.mCode) }}
                                onChange={(s) => { this.onChangeItemCode(s.target.value) }}
                                onBlur={(s) => { this.checkIfItemExist(s.target.value) }}
                                onKeyDown={this.keyboardListener.bind(this)}
                            />
                            {/* <ItemPicker
                            //defaultItemCode={this.props.data[this.DATA_SET_NAME].editedRecord.clubNewItem}
                            defaultItemCode={this.state.PaymentsSearch.mItemCode}
                            onChange={(value) => {
                                this.setReportParameters("PaymentsSearch", { mItemCode: value.itemCode })
                                //this.setState({ ...this.state.PaymentsSearch, mItemCode: this.state.PaymentsSearch.mItemCode })
                            }}
                            label={this.state.PaymentsSearch.mItemCode}
                            data={this.props.data.itemCodes}
                            showDialog={this.props.uiActions.uiShowItemPickerDialog}
                            hideDialog={this.props.uiActions.uiHideItemPickerDialog}
                        /> */}
                        </FormItem>,

                        <FormItem label={this.getText(18949)}>
                            <Input style={{ width: '80%' }}
                                value={this.state.PaymentsSearch.mItemSerialNumber}
                                onChange={(e) => this.setReportParameters("PaymentsSearch", { mItemSerialNumber: e.target.value })}
                            />
                        </FormItem>,

                        <FormItem label={this.getText(17783)} >
                            <GenericSelectorItemTags
                                {...this.props} multi notReport
                                value={this.state.PaymentsSearch.mTagList ? this.state.PaymentsSearch.mTagList.split(",") : []}
                                onChange={(e) => this.setReportParameters("PaymentsSearch", { mTagList: e ? e.join(",") : "" })}
                            />
                        </FormItem>,
                        <FormItem label={this.getText(19146)} >
                            <GenericSelectorCustTags
                                {...this.props} multi notReport
                                value={this.state.PaymentsSearch.mCustTagList ? this.state.PaymentsSearch.mCustTagList.split(",") : []}
                                onChange={(e) => this.setReportParameters("PaymentsSearch", { mCustTagList: e ? e.join(",") : "" })}
                            />
                        </FormItem>,

                        <FormItem label={this.getText(11195)} style={{ margin: '4px', breakInside: 'avoid-column' }}/*{...formItemLayout}*/>
                            <Input placeholder={this.getText(11205)} style={{ width: '80%' }}
                                value={this.state.PaymentsSearch._words}
                                onChange={(e) => this.setReportParameters("PaymentsSearch", { _words: e.target.value })}
                            //onBlur={this.handleFilterBlur}
                            />
                            <Radio.Group
                                style={{ textAlign: 'right', direction: "rtl" }}
                                value={this.state.PaymentsSearch.searchColFilter}
                                onChange={e => { this.setState({ PaymentsSearch: { ...this.state.PaymentsSearch, searchColFilter: e.target.value } }) }}>
                                {/* <Radio value="0">{this.getText(18813)}</Radio><br /> */}
                                <Radio value="4">{this.getText(19448)}</Radio><br />
                                <Radio value="6">{this.getText(19719)}</Radio><br />
                                <Radio value="1">{this.getText(18814)}</Radio><br />
                                <Radio value="2">{this.getText(18815)}</Radio><br />
                                <Radio value="3">{this.getText(18816)}</Radio><br />
                                <Radio value="5">{this.getText(19461)}</Radio><br />
                            </Radio.Group>
                        </FormItem>,
                        <FormItem label={this.getText(11196)} style={{ margin: '4px', breakInside: 'avoid-column' }}/*{...formItemLayout}*/>
                            <Input placeholder={this.getText(11206)} style={{ width: '80%' }}
                                value={this.state.PaymentsSearch._id ? this.state.PaymentsSearch._id : ''}
                                onChange={(e) => this.setReportParameters("PaymentsSearch", { _id: e.target.value })}
                            //onBlur={this.handleFilterBlur}
                            />
                        </FormItem>
                    ]}
                    <FormItem label={this.getText(15730)} style={{ margin: '4px', breakInside: 'avoid-column' }} >
                        <Row>
                            <Col span={12}>
                                {this.getText(15731) + ' - '}
                                <InputNumber
                                    value={this.state.PaymentsSearch.mSumFrom}
                                    onChange={(e) => this.setReportParameters("PaymentsSearch", { mSumFrom: e })}
                                />
                            </Col>
                            <Col span={12}>
                                {this.getText(15732) + ' - '}
                                <InputNumber
                                    value={this.state.PaymentsSearch.mSumTo}
                                    onChange={(e) => this.setReportParameters("PaymentsSearch", { mSumTo: e })}
                                />
                            </Col>
                        </Row>
                        <Checkbox
                            checked={this.state.PaymentsSearch.mSumAbsolute == 1}
                            onChange={(e) => this.setReportParameters("PaymentsSearch", { mSumAbsolute: e.target.checked ? 1 : 0 })}
                        >{this.getText(15733)}</Checkbox>
                    </FormItem>
                    {this.props.isFromOrders ? "" : [
                        <FormItem label={this.getText(16202)} style={{ margin: '4px', breakInside: 'avoid-column' }}/*{...formItemLayout}*/>
                            <InputNumber
                                style={{ width: '80%' }}
                                value={this.state.PaymentsSearch.mSupplierDoc}
                                onChange={(e) => this.setReportParameters("PaymentsSearch", { mSupplierDoc: e })}
                            //onBlur={this.handleFilterBlur}
                            />
                        </FormItem>,
                        <FormItem label={this.getText(17370)} style={{ margin: '4px', breakInside: 'avoid-column' }}/*{...formItemLayout}*/>
                            <Row>
                                <Col span={12}>
                                    {this.getText(17371)}: <br />
                                    <DateTimePicker
                                        format="DD/MM/YYYY"
                                        maxDate={this.state.PaymentsSearch.mSupplierDocDateTo}
                                        value={this.state.PaymentsSearch.mSupplierDocDateFrom}
                                        onChange={(e) => this.setReportParameters("PaymentsSearch", { mSupplierDocDateFrom: e })}
                                    />
                                </Col>
                                <Col span={12}>
                                    {this.getText(17372)}: <br />
                                    <DateTimePicker
                                        format="DD/MM/YYYY"
                                        minDate={this.state.PaymentsSearch.mSupplierDocDateFrom}
                                        value={this.state.PaymentsSearch.mSupplierDocDateTo}
                                        onChange={(e) => this.setReportParameters("PaymentsSearch", { mSupplierDocDateTo: e })}
                                    />
                                </Col>
                            </Row>
                        </FormItem>
                    ]}
                    <FormItem label={this.getText(mDocumentIsClosedTexts[0])}
                    // style={{ display: this.props.isFromOrders ? 'block' : 'none' }}
                    >
                        <Select key={"mDocumentIsClosed"} style={{ width: '95%', paddingLeft: '4px', paddingRight: '4px' }}
                            value={this.state.PaymentsSearch["mDocumentIsClosed"]} onChange={(e) => {
                                this.setReportParameters("PaymentsSearch", { mDocumentIsClosed: e })
                            }} >
                            <Option key={1} value={0}>{this.getText(mDocumentIsClosedTexts[1])}</Option>
                            <Option key={2} value={1}>{this.getText(mDocumentIsClosedTexts[2])}</Option>
                            <Option key={3} value={2}>{this.getText(mDocumentIsClosedTexts[3])}</Option>
                        </Select>
                    </FormItem>
                </Form>
                <div className="action_footer">
                    <Button key="back" onClick={this.handleCancel}>{this.getText(11197)}</Button>
                    <Button key="clear" onClick={this.handleReset}>{this.getText(11198)}</Button>
                    <Button form="search_working_hours_form" key="create" type="primary" loading={loading}
                        onClick={this.handleOk}>{this.getText(11199)}</Button>
                </div>
            </Modal >)
    }

    handleReset = () => {
        let fullPosList = this.props.data[TableIDs.genericSelectorPos].dataset.map(x => x.code).join(',')
        //this.props.form.resetFields();
        let _request = {
            _id: 0,
            // ui
            page: 1,
            // recordsPerPage: 8,
            // sortType: 1,
            // sortColumn: 1,
            // api
            _words: '', ////////
            _page_num: 1,
            // _rows_num: 8,
            // _isAsc: 1,
            // _sort_by: 'col_1',

            mDocTypeList: this.props.isFromOrders ? '15' : '',
            mPosList: fullPosList,
            mLowerSelectionDate: null,
            mLowerSelectionTime: '00:00:00',
            mUpperSelectionDate: null,
            mUpperSelectionTime: '23:59:59',
            mApplyCreditCardSearchQuery: null,
            mIsCustomerActive: 1,
            mCustomer: null,
            mItemCode: '',
            mEmployeeList: '',
            mStorageList: '',
            mDocumentIsClosed: 0,
            mSumFrom: null,
            mSumTo: null,
            mSumAbsolute: 0,
            mSupplierDoc: null,
            mSupplierDocDateFrom: null,
            mSupplierDocDateTo: null,
            mTagList: '',
            mCustTagList: '',
            searchColFilter: "4",
            mCustWithConnected: "0",
            mItemSerialNumber: '',
            mCustomerList: '',
            mCustType: '0',
        };

        this.setState({ PaymentsSearch: _request },
            () => {
                this.props.dataActions.genericDataSetFilter(
                    'DOC_SEARCH_TABLE', this.props.user.companyCode, this.props.user.token, _request, this.tableApi.get);
            }
        );
    }
}

export default ModalAdvanceSearchReports;
