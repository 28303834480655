/* @flow */
/*jshint esversion: 6 */
import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import TableIDs from '../data/TableIDs';
import CrmMenu from '../menus/CrmMenu';
import ServiceSubjects from "./Crm/ServiceSubjects";
import ServiceCallStatuses from "./Crm/ServiceCallStatuses";
import ServicesTypes from "./Crm/ServicesTypes";
import ExternalServiceProviders from "./Crm/ExternalServiceProviders";
import ServiceCalls from "./Crm/ServiceCalls";

type Props = {
    user: any,
    baseUrl: string,
    uiActions: any,
    ui: any,
    dataActions: any,
    data: Object,
    ActionQueue: Object
};

type State = {};

class Crm extends Component<Props, State> {
    constructor(props/*: Props*/) {
        super(props);
        if (props.data[TableIDs.pseudoSelectorUIcomponentsModules] === undefined) {
            props.data[TableIDs.pseudoSelectorUIcomponentsModules] = { ...props.data.genericSelector };
        }
    }

    isActive = (key) => {
        return this.props.data[TableIDs.pseudoSelectorUIcomponentsModules].dataset
            .map(obj => obj.code).includes(key);
    };

    render() {
        const { baseUrl, user, ui, uiActions, dataActions, data, ActionQueue } = this.props;
        let routeProps = { baseUrl, user, ui, uiActions, dataActions, data, ActionQueue }

        const routes = [
            (<Route key={271} path={this.props.baseUrl + "/crm/service-subjects"} exact render={() => <ServiceSubjects {...routeProps} />} />),
            (<Route key={272} path={this.props.baseUrl + "/crm/service-call-statuses"} exact render={() => <ServiceCallStatuses {...routeProps} />} />),
            (<Route key={273} path={this.props.baseUrl + "/crm/services-types"} exact render={() => <ServicesTypes {...routeProps} />} />),
            (<Route key={274} path={this.props.baseUrl + "/crm/external-service-providers"} exact render={() => <ExternalServiceProviders {...routeProps} />} />),
            (<Route key={275} path={this.props.baseUrl + "/crm/service-calls"} exact render={() => <ServiceCalls {...routeProps} />} />),
            
        ].filter((route, index) => this.isActive(route.key));

        const homeRoute = (routes.length > 0)
            ? (<Route key={routes[0].key} path={this.props.baseUrl + "/crm"} exact render={routes[0].props.render} />)
            : <div />

        return (
            <div>
                {this.props.isFromUserFavorites ? '' : <CrmMenu baseUrl={this.props.baseUrl} data={this.props.data} />}
                {homeRoute}
                {routes}
            </div>
        );
    }
}
export default Crm;
