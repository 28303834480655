
import React from "react";
import {
    Input, InputNumber, Form, Button, Modal, Select, Table, DatePicker, TimePicker,
    Checkbox, Collapse, Icon, Popconfirm, message,
    Tooltip,
    Row,
    Col,
} from 'antd';
import SelectorItems from "./SelectorItems";
import { Job, User, OutputType, ProcessType } from '../JmReact';

import moment from "moment";
import PageToolbar from "./PageToolbar";
import TableIDs from '../data/TableIDs.js';
import CustomerPicker from "./CustomerPicker";
import ResponsiveTable from "./ResponsiveTable";

import "../style/generalUI.css";
import "./ModalCreateEditFixLabDocuments.css";
import FormItem from "antd/lib/form/FormItem";
import Jax from "../Jax/jax.es6.module";
import LangContext from "../contextProvider/LangContext";
import AnswerTypeInput from "./AnswerTypeInput";
import SelectorItemClassic from "./SelectorItemClassic";
import GenericModal from "./GenericModal.js";


//import RadioGroup from "antd/lib/radio/group";


const { TextArea } = Input;
const { Option } = Select;
const Panel = Collapse.Panel;

//type State = {}
class ModalCreateEditFixLabDocuments extends React.Component/*<Props, State>*/ {
    DATA_SET_NAME: string;
    // props: user,tableFormat,apiCalls
    constructor(props/*: Props*/) {
        super(props);
        this.state = {
            defaultItemCode: "",
            itemCodeSelectionVisible: false,
            loading: false,
            visible: false,
            activePanel: 'basicDiv',
            // estimatedTimeFocus: false,
            counterRender: 0,
            newItemCode: "",
            newItemName: "",

            itemsEditing: false,

            modalCustData: false,
        }

        this.id = "MODAL_CREATE_EDIT_FIX_LAB_DOCUMENTS";
        this.statuses_id = "FIX_LAB_STATUSES_LIST"
        this.question_id = "FIX_LAB_QUESTION_LIST";
        this.items_id = "FIX_LAB_ITEMS_LIST";
        this.logs_id = "FIX_LAB_LOGS_LIST";
        this.custDialog_id = "FIX_LAB_CUSTOMER_DIALOG_LIST";
        this.custFields_id = "FIX_LAB_CUSTOMER_FIELDS"

        // calc page height
        this.state.height = window.innerHeight;
        this.doOnResize = null;

        this.numeratorNewStore = -1;

        this.api = {
            get: "get_fix_lab_document_data",
            get_statuses: "get_fix_lab_statuses",
            get_question: "get_fix_lab_questions",
            get_items: "get_fix_lab_items",
            get_logs: "get_fix_lab_logs",
            get_custDialog: "get_fix_lab_customer_dialog",
            get_custData: "get_fix_lab_customer_fields",
            save_all: "create_edit_fix_lab_document_v3",
        }

        this.barcodeReaderBuffer = '';
        this.isBufferFull = false;
        this.ctrlBKeyFlag = false;

        this.modalActions = [
            { id: this.id, script: this.api.get },
            { id: this.statuses_id, script: this.api.get_statuses },
            { id: this.question_id, script: this.api.get_question },
            { id: this.items_id, script: this.api.get_items },
            { id: this.logs_id, script: this.api.get_logs },
            { id: this.custDialog_id, script: this.api.get_custDialog },
            { id: this.custFields_id, script: this.api.get_custData },
        ];

        this.modalActions.forEach((x) => {
            if (props.data[x.id] === undefined) {
                props.data[x.id] = { ...props.data.genericData };
            }
        })

        if (props.data[this.DATA_SET_NAME] === undefined) {
            props.data[this.DATA_SET_NAME] = Object.assign({}, props.data.genericData);
        }


    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        this.initFilters();
        window.addEventListener("resize", () => {
            clearTimeout(this.doOnResize);
            this.doOnResize = setTimeout(this.handleWindowSizeChange, 100)
        });
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        this.setState({ height: window.innerHeight }, this.initFilters);
    };

    initFilters = () => {
        let request = {
            _id: this.props.ui.data ? this.props.ui.data['fixId'] : 0,
            _user_id: this.props.user.userId,
            _user_name: this.props.user.displayName,
        }
        this.modalActions.forEach((x) => {
            if (x.id == this.items_id) {
                this.props.dataActions.genericDataSetFilter(
                    x.id, this.props.user.companyCode, this.props.user.token, request, x.script);
            } else {
                this.props.dataActions.genericDataGetEditWithParams(
                    x.id, this.props.user.companyCode, this.props.user.token, request, x.script);
            }
        })
    }

    componentDidUpdate(prevProps) {
        if (
            (this.props.ui.data && !prevProps.ui.data) ||
            (this.props.ui.data && this.props.ui.data["fixId"] !== prevProps.ui.data["fixId"])
        ) {
            this.setState({}, this.initFilters, {});
        }
    }

    dataObject = (id) => {
        let headers = this.props.data[id].headers;
        return this.props.data[id].data.map((x, indexData) => {
            let y = x.split("\f");
            let ob = { key: y[0] }
            headers.forEach((z, index) => ob = { ...ob, [z]: y[index], index: indexData });
            return ob;
        });
    }

    requiredQuestions = () => {
        return this.dataObject(this.question_id).map((x) => (x.qRequired === '1' && !x.qAnswer) ? false : true).reduce((a, b) => a && b);
    }

    handleOk = () => {
        this.props.dataActions.dataTableSetRow(this.id, 0, this.props.data[this.id].editedRecord, true);
        let request = {
            sectionData: this.dataObject(this.id)[0],
            sectionQuestion: this.dataObject(this.question_id),
            sectionItems: this.dataObject(this.items_id),
            sectionLogs: this.dataObject(this.logs_id),
        };
        if (request.sectionData.fCustomerId === '0') {
            message.error(this.getText(11243));
            this.setState(Jax.set(this.state, "activePanel", 'basicDiv'));
        } else if (!this.requiredQuestions()) {
            message.error(this.getText(11244));
            this.setState(Jax.set(this.state, "activePanel", 'questions'));
        } else if (typeof (this.props.data[this.items_id].editing) === 'string') {
            message.error(this.getText(11245));
            this.setState(Jax.set(this.state, "activePanel", 'items'));
        } else {
            this.props.dataActions.genericDataJustSaveEditMulti(
                this.id,
                this.props.user.companyCode,
                this.props.user.token,
                this.api.save_all,
                [
                    { name: this.id },
                    { name: this.question_id, sendDataTable: true },
                    { name: this.items_id, sendDataTable: true },
                    { name: this.logs_id, sendDataTable: true },
                    { name: this.custDialog_id, sendDataTable: true },
                ],
                {
                    action: this.props.dataActions.genericDataGetRequested,
                    args: ['FIX_LAB_DOCUMENTS', this.props.user.companyCode, this.props.user.token, 'get_fixLabDocuments_table_bo']
                }
            )
            this.handleCancel();
            message.success(this.getText(11246));
        }
    }

    handleCancel = () => {
        this.props.uiActions.hideModal(TableIDs.modalCreateEditFixLabDocuments);
        this.setState(Jax.set(this.state, "activePanel", 'basicDiv'));
        this.initFilters();
    }

    insertLog = (field, label) => {
        let oldData = this.dataObject(this.id)[0];
        let newData = this.props.data[this.id].editedRecord;
        let insertIn = (textLog) => {
            this.numeratorNewStore--;
            this.props.dataActions.genericDataStartInlineCreate(this.logs_id);
            this.props.dataActions.dataTableSetRow(
                this.logs_id, 0,
                {
                    lId: this.numeratorNewStore,
                    lEmployee: newData.fUserEmployee,
                    lEmployeeId: newData.fUserEmployeeId,
                    lTime: moment(Date.now()).format("YYYY-MM-DD HH:mm:ss"),
                    lText: textLog,
                    lField: field,
                },
                true);
        }
        if (typeof field === 'string' && oldData[field] !== newData[field]) {
            if (oldData[field]) {
                let textLog = this.getText(11247) + ' ' + label + " " + this.getText(11248) + oldData[field] + " " + this.getText(11249) + newData[field];
                if (field === 'fStatusNum') textLog = this.getText(11247) + ' ' + label + " " + this.getText(11248) + oldData['fStatus'] + " " + this.getText(11249) + newData['fStatus'];
                if (field === 'fCustomerName') textLog = this.getText(11250) + '  ' + oldData['fCustomerName'];
                insertIn(textLog);
            }
            this.props.dataActions.dataTableSetRow(this.id, 0, newData, true);
        }

        if (typeof field === 'object') {
            let textLog = field.key ? this.getText(11247) : this.getText(11251);
            textLog += label + field.iName + ' ' + this.getText(11252) + ' ' + field.iQty;
            insertIn(textLog);
        }
    }

    checkIfItemExist = (s) => {
        if (s) {
            let dataSend = "_itemCode\f_isWithMatrix\f_isWithFather\r" + s + "\f1\f0";
            let user = new User(this.props.user.token, this.props.user.companyCode);
            let job = new Job(user, "check_all_item_code", OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
            job.setInput(dataSend);
            job.send("/cgi-bin/CashOnTab",
                (ob) => {
                    let itemsRows = (ob.data).split("\r");
                    let _ItemData = itemsRows[0].split('\f');
                    let _ItemCode = _ItemData[1];
                    let _ItemName = _ItemData[2];

                    if (!_ItemCode || itemsRows.length > 1) {
                        this.props.uiActions.showModal(TableIDs.SelectorItemsModal, { ...this.props.ui.data });
                    } else {
                        this.onChangeItemCode(_ItemCode, _ItemName)
                    }
                },
                (error) => {
                    message.error(this.getText(11253));
                }
            );
        }
    }

    keyboardListener(e) {
        let bufferUpdate = (u) => { this.barcodeReaderBuffer = this.barcodeReaderBuffer + u }
        switch (e.key) {
            case "b": case "B": case "נ": case "M": case "m": case "צ":
                if (e.ctrlKey) {
                    this.ctrlBKeyFlag = true;
                    this.barcodeReaderBuffer = '';
                } else {
                    bufferUpdate(e.key)
                }
                break;

            case "Enter":
                if (this.ctrlBKeyFlag == true) this.ctrlBKeyFlag = false;
                this.checkIfItemExist(this.barcodeReaderBuffer)
                break;

            default:
                bufferUpdate(e.key)
                break;
        }
    }


    onChangeItemCode = (code, name) => {
        if (this.ctrlBKeyFlag === true && this.isBufferFull === false) {
            this.barcodeReaderBuffer = this.barcodeReaderBuffer + code;
        } else {
            console.log(this.props.data[this.items_id].editedRecord, "editingtest")
            if (this.props.data[this.items_id].editing == -1) this.props.dataActions.genericDataStartInlineCreate(this.items_id);
            this.sendAPI("get_price_by_item_and_user", "_item\r" + code, ob => {
                this.props.dataActions.genericDataSetEdit(this.items_id, {
                    iCode: code, iName: name, iDocId: this.props.ui.data["fixId"], iUnitPrice: ob.data, iQty: 1
                });
            })

        }
    }



    sendAPI = (script, data, sCal) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCal, (e) => { console.error(e) });
    }

    getIconClick = (type, onClick) => {
        return (<Icon style={{ fontSize: 16 }} className={"clickable margin-4"} {...{ type, onClick }} />)
    }

    questionsColumns = () => {
        const { editing, editedRecord } = this.props.data[this.question_id]
        let gt = this.getText;

        let renderQText = (t, r) => r.qRequired === '1' ? (<span><span style={{ color: 'red' }}> * </span> {t} </span>) : t;
        let renderQAnswer = (t, r) => {
            return editing !== r.key ? t :
                (<AnswerTypeInput
                    type={r.qType}
                    style={{ width: '95%', marginRight: '2px', marginleft: '2px' }}
                    value={editedRecord['qAnswer']}
                    onChange={(value) => {
                        this.props.dataActions.genericDataSetEdit(this.question_id, { qAnswer: value });
                    }}
                />)
        }
        let renderQEdit = (t, r) => {
            return editing !== r.key ?
                this.getIconClick('edit', () => { this.props.dataActions.genericDataStartEditing(this.question_id, t, r.index) }) :
                [
                    this.getIconClick('check', () => {
                        if (r.qRequired === '1' && !editedRecord['qAnswer']) {
                            message.error(gt(11254));
                        } else {
                            this.props.dataActions.dataTableSetRow(this.question_id, r.index, editedRecord, true);
                            if (editing != 0) this.props.uiActions.hideModal(this.question_id + "Editor");
                        }
                    }),
                    this.getIconClick('close', () => {
                        if (editing == 0) this.props.dataActions.genericDataCancelInlineCreate(this.question_id);
                        else this.props.dataActions.genericDataCancelEditing(this.question_id);
                    })
                ]
        }

        return [
            { title: gt(11224), dataIndex: "qText", key: "qText", width: '40%', render: renderQText },
            { title: gt(11225), dataIndex: "qAnswer", key: "qAnswer", width: '50%', render: renderQAnswer },
            { title: gt(11226), dataIndex: "qId", key: "qId", width: '10%', render: renderQEdit }
        ];

    }

    itemsColumns = () => {
        const { editing, editedRecord } = this.props.data[this.items_id]
        let gt = this.getText;
        let renderIName = (t, r) => {
            return editing !== r.key ? t :
                (<Input
                    style={{ marginRight: '2px', marginleft: '2px' }}
                    value={editedRecord['iName']}
                    onChange={(value) => { this.props.dataActions.genericDataSetEdit(this.items_id, { iName: value.target.value }) }}
                />)
        };
        let renderISerialNo = (t, r) => {
            return editing !== r.key ? t :
                (<Input
                    style={{ marginRight: '2px', marginleft: '2px' }}
                    value={editedRecord['iSerialNo']}
                    onChange={(value) => { this.props.dataActions.genericDataSetEdit(this.items_id, { iSerialNo: value.target.value }) }}
                />)
        };
        let renderIQty = (t, r) => {
            return editing !== r.key ? t :
                (<InputNumber
                    min={0} step={1} precision={2}
                    style={{ width: '95%', marginRight: '2px', marginleft: '2px' }}
                    value={editedRecord && parseFloat(editedRecord['iQty']) ? parseFloat(editedRecord['iQty']).toFixed(2) : '0.00'}
                    onChange={(value) => { this.props.dataActions.genericDataSetEdit(this.items_id, { iQty: parseFloat(value).toFixed(2) }) }}
                />)
        }
        let renderIUnitPrice = (t, r) => {
            return editing !== r.key ? t :
                (<InputNumber
                    min={0} step={1} precision={2}
                    style={{ width: '95%', marginRight: '2px', marginleft: '2px' }}
                    value={editedRecord && parseFloat(editedRecord['iUnitPrice']) ? parseFloat(editedRecord['iUnitPrice']).toFixed(2) : '0.00'}
                    onChange={(value) => { this.props.dataActions.genericDataSetEdit(this.items_id, { iUnitPrice: parseFloat(value).toFixed(2) }) }}
                />)
        }
        let renderIDiscountPercentage = (t, r) => {
            return editing !== r.key ? t :
                (<InputNumber
                    min={0} step={1} precision={2}
                    style={{ width: '95%', marginRight: '2px', marginleft: '2px' }}
                    value={editedRecord && parseFloat(editedRecord['iDiscountPercentage']) ? parseFloat(editedRecord['iDiscountPercentage']).toFixed(2) : '0.00'}
                    onChange={(value) => { this.props.dataActions.genericDataSetEdit(this.items_id, { iDiscountPercentage: parseFloat(value).toFixed(2) }) }}
                />)
        }
        let renderIEdit = (t, r) => {
            return editing !== r.key ?
                [
                    this.getIconClick("edit", () => { this.props.dataActions.genericDataStartEditing(this.items_id, t, r.index) }),
                    <Popconfirm title={gt(11230)} okText={gt(11231)} cancelText={gt(11232)}
                        onConfirm={() => this.props.dataActions.dataTableDeleteRow(this.items_id, r.index)}
                    >{this.getIconClick("delete")}</Popconfirm>
                ] :
                [
                    this.getIconClick("check", () => {
                        let docNum = this.props.data[this.id] ? this.props.data[this.id].editedRecord.fId : 0;
                        if (!editedRecord.iQty || editedRecord.iQty === '0.00') {
                            message.error(gt(11255));
                        } else {
                            this.numeratorNewStore--;
                            this.props.dataActions.dataTableSetRow(this.items_id, r.index,
                                { ...editedRecord, iId: r.key !== '0' ? r.key : this.numeratorNewStore, iDocId: docNum }, true);
                            if (editing != 0) this.props.uiActions.hideModal(this.items_id + "Editor");

                            this.insertLog(editedRecord, ' ' + gt(11256) + ' ');
                            this.setState({ newItemCode: "", itemsEditing: false })
                        }
                    }),
                    this.getIconClick("close", () => {
                        this.setState({ newItemCode: "", itemsEditing: false })
                        if (editing == 0) this.props.dataActions.genericDataCancelInlineCreate(this.items_id, true);
                        else this.props.dataActions.genericDataCancelEditing(this.items_id);
                    }),
                ]
        }

        return [
            { title: gt(11227), dataIndex: "iName", key: "iName", width: '25%', render: renderIName },
            { title: gt(19022), dataIndex: "iSerialNo", key: "iSerialNo", width: '20%', render: renderISerialNo },
            { title: gt(11228), dataIndex: "iQty", key: "iQty", width: '15%', render: renderIQty },
            { title: gt(19023), dataIndex: "iUnitPrice", key: "iUnitPrice", width: '15%', render: renderIUnitPrice },
            { title: gt(19024), dataIndex: "iDiscountPercentage", key: "iDiscountPercentage", width: '15%', render: renderIDiscountPercentage },
            { title: gt(11229), dataIndex: "iId", key: "iId", width: '10%', render: renderIEdit }
        ];
    }

    custDialogColumns = () => {
        const { editing, editedRecord } = this.props.data[this.custDialog_id]
        let gt = this.getText;

        let renderGEmployee = (t, r) => editing != r.key ? t : editedRecord['gEmployee'];
        let renderGQuestionTime = (t, r) => editing != r.key ? t : editedRecord['gQuestionTime'];
        let renderGQuestion = (t, r) => editing != r.key ? t : (
            <TextArea
                value={editedRecord['gQuestion']}
                onChange={(e) => { this.props.dataActions.genericDataSetEdit(this.custDialog_id, { gQuestion: e.target.value }) }}
                autosize={{ minRows: 1, maxRows: 3 }} />);

        let renderGAnswerType = (t, r) => (
            <Select
                disabled={editing != r.key}
                key={'gAnswerType'}
                value={editing != r.key ? t : editedRecord['gAnswerType']}
                onChange={(e) => { this.props.dataActions.genericDataSetEdit(this.custDialog_id, { gAnswerType: e }) }} >
                <Option key={'1'} value={'1'}>{gt(11257)}</Option>
                <Option key={'2'} value={'2'}>{gt(11258)}</Option>
            </Select>)

        let renderGEdit = (t, r) => editing != r.key ? gt(11261) : [
            this.getIconClick("check", () => {
                if (!editedRecord['gQuestion']) message.error(gt(11259))
                else if (!editedRecord['gAnswerType']) message.error(gt(11260))
                else this.props.dataActions.dataTableSetRow(this.custDialog_id, r.index, editedRecord, true);
            }),
            this.getIconClick("close", () => { this.props.dataActions.genericDataCancelInlineCreate(this.custDialog_id, true) }),
        ]

        return [
            { title: gt(11236), dataIndex: "gEmployee", key: "gEmployee", width: '12%', render: renderGEmployee },
            { title: gt(11237), dataIndex: "gQuestion", key: "gQuestion", width: '20%', render: renderGQuestion },
            { title: gt(11238), dataIndex: "gQuestionTime", key: "gQuestionTime", width: '12%', render: renderGQuestionTime },
            { title: gt(11239), dataIndex: "gAnswerType", key: "gAnswerType", width: '12%', render: renderGAnswerType },
            { title: gt(11240), dataIndex: "gAnswer", key: "gAnswer", width: '20%', render: t => t },
            { title: gt(11241), dataIndex: "gAnswerTime", key: "gAnswerTime", width: '12%', render: t => t },
            { title: gt(11242), width: '6%', render: renderGEdit },
        ];
    }

    basicInputs = (field, label, style) => {
        const { editedRecord } = this.props.data[this.id];
        let valueIn = editedRecord[field];
        let updateIn = (value) => {
            this.props.dataActions.genericDataSetEdit(this.id, { [field]: value });
        }
        let content = '';

        switch (field) {
            case 'fCustomerName':
                let customerPickerLabel = this.getText(11262);
                let customerPickerDefault = '';
                let customerPickerName = '';
                let baseSource = this.props.data["customer"];
                if (baseSource) {
                    let baseSource2 = baseSource.editedRecord["customers"];
                    if (baseSource2 && baseSource2.customerId && baseSource2.customerId !== '0') {
                        customerPickerLabel = baseSource2.customerId + ' - ' + baseSource2.customerName;
                        customerPickerDefault = baseSource2.customerId;
                        customerPickerName = baseSource2.customerName;
                    }
                }
                if (editedRecord.fCustomerId === '0') {
                    content = (<CustomerPicker
                        defaultCustomerId={customerPickerDefault}
                        onChange={(value) => {
                            this.props.dataActions.genericDataSetEdit("customer.editedRecord.customers", value);
                            this.props.dataActions.genericDataSetEdit(this.id, { fCustomerId: value.customerId });
                            this.insertLog(field, label);
                        }}
                        label={customerPickerLabel}
                        data={(this.props.data.customerList) ? this.props.data.customerList.data : []}
                        showDialog={this.props.uiActions.uiShowCustomerPickerDialog}
                        hideDialog={this.props.uiActions.uiHideCustomerPickerDialog}
                    />);
                } else {
                    content = (<Input value={customerPickerName} />)
                }
                break;

            case 'fItemCode':
                content = (<SelectorItemClassic
                    {...this.props}
                    valueId={editedRecord.fItemCode}
                    valueName={editedRecord.fItemName}
                    onChange={(fItemCode, fItemName) => {
                        this.props.dataActions.genericDataSetEdit(this.id, { fItemCode, fItemName })
                    }} />);
                break;

            case 'fPhone1': case 'fPhone2': case 'fSerialNumber':
                content = (<Input
                    value={valueIn}
                    onChange={(value) => {
                        let text = value.target.value;
                        let lastChar = text[text.length - 1];
                        if (parseInt(lastChar) || lastChar === '0') updateIn(text)
                    }}
                    onBlur={() => { this.insertLog(field, label) }}
                />)
                break;

            case 'fOpenDate': case 'fOpenEmployee': case 'fOpenPos': case 'fStatusDate': case 'fStatusEmployee':
                content = (<Input value={valueIn} />)
                break;

            case 'fStatusNum':
                let statusesData0 = (this.props.data[this.statuses_id] && this.props.data[this.statuses_id].data) ?
                    this.props.data[this.statuses_id].data : false;

                let statusesData = statusesData0 ? statusesData0.map((x) => {
                    let y = x.split("\f");
                    return ({ sId: y[0], sName: y[1], sColor: y[2] })
                }) : false;

                content = (<Select
                    key={field} showSearch filterOption={true} value={valueIn}
                    onChange={(value) => {
                        let findStatus = statusesData.find(f => f.sId == value);
                        this.props.dataActions.genericDataSetEdit(this.id, {
                            fStatusNum: value,
                            fStatus: findStatus ? findStatus.sName : null,
                            fStatusEmployee: editedRecord['fUserEmployee'],
                            fStatusEmployeeId: editedRecord['fUserEmployeeId'],
                            fStatusDate: moment(Date.now()).format("YYYY-MM-DD HH:mm:ss"),
                        });
                    }}
                    onBlur={() => { this.insertLog(field, label) }} >
                    {statusesData ? statusesData.map((x) => {
                        let styleStatus = { backgroundColor: x.sColor }
                        return (< Option style={styleStatus} key={x.sId} value={x.sId} valuefilter={x.sName}>
                            <span style={styleStatus}>{x.sId + '-' + x.sName}</span>
                        </Option >)
                    }) : []}
                </Select>);
                break;

            case 'fEstimatedTime':
                let timeOver = new Date(valueIn) - Date.now();
                let hour = 60 * 60 * 1000;
                let color = (timeOver > (hour * 3)) ? '' : (timeOver > hour) ? 'pink' : 'red';

                content = (<span id={'estimated-time-' + color}>
                    <DatePicker
                        showTime
                        format="YYYY-MM-DD HH:mm:ss"//"YYYY-MM-DD"
                        value={valueIn ? moment(valueIn, "YYYY-MM-DD HH:mm:ss") : null}
                        onChange={(value) => { updateIn(value ? value.format("YYYY-MM-DD HH:mm:ss") : null) }}
                        onBlur={() => { this.insertLog(field, label) }}
                    />
                </span>
                );
                break;

            case 'fFirstPrice':
                content = (<InputNumber min={0} step={1} precision={2}
                    value={valueIn ? parseFloat(valueIn).toFixed(2) : '0.00'}
                    onChange={(value) => updateIn(parseFloat(value).toFixed(2))}
                    onBlur={() => { this.insertLog(field, label) }}
                />);
                break;

            case 'fProblemDescription': case 'fLabDescription':
                content = (<TextArea
                    value={valueIn}
                    onChange={(value) => { updateIn(value.target.value) }}
                    autosize={{ minRows: 5, maxRows: 8 }}
                    onBlur={() => { this.insertLog(field, label) }}
                />);
                break;
        }

        return (<FormItem label={label} style={{ margin: '4px', ...style }}>{content}</FormItem>)
    }

    panelValue = (key, header, body) => {
        let style = {
            display: 'inline-block',
            width: (window.innerWidth > 600 && key !== 'basicDiv' && key !== 'custDialog') ? '48%' : '96%',
        }
        let panelHeader = (<div onClick={() => {
            if (this.state.activePanel === key) this.setState(Jax.set(this.state, "activePanel", "basicDiv"));
            else this.setState(Jax.set(this.state, "activePanel", key));
        }}>{header}</div>)

        return (<Panel key={key} style={style} header={panelHeader}>{body}</Panel>)
    }

    render() {
        const { loading } = this.state;

        let docNum = this.props.data[this.id] ? this.props.data[this.id].editedRecord.fId : 0;
        let titleName = this.getText(11221);
        titleName += (docNum > 0) ? ' ' + this.getText(11222) + ' ' + docNum : ' ' + this.getText(11223);

        let titleBar = (
            <div style={{
                paddingLeft: '4px',
                paddingRight: '4px',
                paddingTop: '4px',
                paddingBottom: '4px',
            }}>
                <PageToolbar
                    title={titleName}
                    actionButtons={[]}
                    genericActionButtons={[
                        <Tooltip title={this.getText(20425)}>
                            <Button style={{ marginTop: 5, marginLeft: 5 }} onClick={() => { this.setState({ modalCustData: true }) }}>
                                <Icon style={{ fontSize: 20 }} className={"clickable margin-4"} type={"user"} />
                            </Button>
                        </Tooltip>
                    ]}
                    isModal={true}
                    ui={this.props.ui}
                    uiActions={this.props.uiActions}
                    data={this.props.data}
                    dataActions={this.props.dataActions}
                />
            </div>);

        let logsColumns = [
            { title: this.getText(11233), dataIndex: "lEmployee", key: "lEmployee", width: '30%', render: t => t },
            { title: this.getText(11234), dataIndex: "lTime", key: "lTime", width: '30%', render: t => t },
            { title: this.getText(11235), dataIndex: "lText", key: "lText", width: '35%', render: t => t },
        ];

        let responsiveStyle = {
            width: (window.innerWidth > 600) ? '20%' : '80%',
            display: 'inline-block',
            marginRight: '1%',
        }

        let textareaStyle = {
            width: (window.innerWidth > 600) ? '30%' : '80%',
            marginRight: '1%',
            display: 'inline-block',
        }

        let basicDiv = (<div>
            <div style={responsiveStyle} >
                {this.basicInputs('fCustomerName', this.getText(11263), {})}
                {this.basicInputs('fPhone1', this.getText(11264), {})}
                {this.basicInputs('fPhone2', this.getText(11265), {})}
                {this.basicInputs('fOpenPos', this.getText(11268), {})}
            </div>
            <div style={responsiveStyle} >
                {this.basicInputs('fOpenDate', this.getText(11266), {})}
                {this.basicInputs('fOpenEmployee', this.getText(11267), {})}
                {this.basicInputs('fStatusDate', this.getText(11269), {})}
                {this.basicInputs('fStatusEmployee', this.getText(11270), {})}
            </div>
            <div style={textareaStyle} >
                {this.basicInputs('fProblemDescription', this.getText(11274), {})}
                {this.basicInputs('fItemCode', this.getText(19005), {})}
                {this.basicInputs('fSerialNumber', this.getText(19006), {})}
            </div>
            <div style={responsiveStyle} >
                {this.basicInputs('fStatusNum', this.getText(11271), { width: '100%' })}
                {this.basicInputs('fEstimatedTime', this.getText(11272), {})}
                {this.basicInputs('fFirstPrice', this.getText(11273), {})}
            </div>

        </div>);

        let questionsTable = (<div style={{ height: '250px' }}>
            <ResponsiveTable ui={this.props.ui} tableOnly={true}
                bordered
                dataSource={this.dataObject(this.question_id)}
                columns={this.questionsColumns()}
                pagination={{ pageSize: 14 }}
                scroll={{ y: 190 }}
                locale={{ emptyText: this.getText(11275) }}
            />
        </div>
        )

        const { itemsEditing } = this.state;

        let itemsTable = (<div style={{ height: '250px' }}>
            {(!itemsEditing) ?
                (<Button type={"primary"} onClick={() => { this.setState({ itemsEditing: true }) }}>{this.getText(11276)}</Button>)
                : (<SelectorItems
                    doFocus={true}
                    user={this.props.user}
                    uiActions={this.props.uiActions}
                    ui={this.props.ui}
                    dataActions={this.props.dataActions}
                    data={this.props.data}
                    ActionQueue={this.props.ActionQueue}
                    ctrlBKeyFlag={null}
                    isExpandMatrixFatherItem={false}
                    itemType={1}
                    value={this.state.newItemCode}
                    onChangeSearch={(s) => { this.onChangeItemCode(s.mCode, s.mName) }}
                    onChange={(s) => { this.setState({ newItemCode: s.target.value }) }}
                    onBlur={(s) => { this.checkIfItemExist(this.state.newItemCode) }}
                    onKeyDown={this.keyboardListener.bind(this)}
                />)
            }
            <ResponsiveTable ui={this.props.ui} tableOnly={true}
                bordered
                dataSource={this.dataObject(this.items_id)}
                columns={this.itemsColumns()}
                pagination={{ pageSize: 14 }}
                scroll={{ y: 170 }}
                locale={{ emptyText: this.getText(11275) }}
            />
        </div>)

        let logsTable = (<div style={{ height: '250px', paddingTop: '30px' }}>
            <ResponsiveTable ui={this.props.ui} tableOnly={true}
                bordered
                dataSource={this.dataObject(this.logs_id).sort((a, b) => new Date(a.lTime) - new Date(b.lTime))}
                columns={logsColumns}
                pagination={{ pageSize: 14 }}
                scroll={{ y: 200 }}
                locale={{ emptyText: this.getText(11275) }}
            />
        </div>)

        let custDialogTable = (<div style={{ height: '250px', paddingTop: '10px' }}>
            <ResponsiveTable
                idTable={this.props.data[this.custDialog_id]}
                columns={this.custDialogColumns()}
                percentageToMobile={[30, 35, false, false, 35, false, false]}
                title={this.getText(11277)}
                actionButtons={[{
                    buttonType: 'add',
                    buttonFunction: () => {
                        if (this.props.data[this.custDialog_id].editing == -1 || this.props.data[this.custDialog_id].editing === 0) {
                            this.numeratorNewStore--;
                            this.props.dataActions.genericDataStartInlineCreate(this.custDialog_id);
                            this.props.dataActions.genericDataSetEdit(this.custDialog_id, {
                                gId: this.numeratorNewStore,
                                gDocId: this.props.ui.data.fixId,
                                gEmployee: this.props.data[this.id].editedRecord['fUserEmployee'],
                                gEmployeeId: this.props.data[this.id].editedRecord['fUserEmployeeId'],
                                gQuestionTime: moment(Date.now()).format("YYYY-MM-DD HH:mm:ss"),
                            });
                        }
                    }
                }]}
                ui={this.props.ui}
                uiActions={this.props.uiActions}
                pagination={{ pageSize: 14 }}
                scroll={{ y: 200 }}
            />
        </div>)

        this.state.counterRender++;

        return (<Modal
            destroyOnClose={true}
            width={'95%'}
            visible={false || this.props.toggle}
            title={titleBar}
            onOk={this.handleOk}
            onCancel={this.handleCancel}
            footer={[
                <Button key="back" onClick={this.handleCancel}>{this.getText(11278)}</Button>,
                <Button key="clear" loading={loading} onClick={() => {
                    this.setState(Jax.set(this.state, "activePanel", 'basicDiv'));
                    this.initFilters()
                }}>{this.getText(11279)}</Button>,
                <Button key="create" type="primary" loading={loading} onClick={this.handleOk}>{this.getText(11280)}</Button>,
            ]}
            ui={this.props.ui} uiActions={this.props.uiActions}
            data={this.props.data} dataAction={this.props.dataAction}
        >

            <Form className="data-entry-form" //layout='vertical' /*layout='horizontal' layout='inline'*/
                style={{ columnWidth: 'auto', columns: '1' }}
                onSubmit={this.handleSaveChanges}>
                <Collapse activeKey={Jax.get(this.state, "activePanel", "basicDiv")} >
                    {this.panelValue('basicDiv', this.getText(11281), basicDiv)}
                    {this.panelValue('questions', this.getText(11282), questionsTable)}
                    {this.panelValue((window.innerWidth > 600) ? 'questions' : 'description', this.getText(11283),
                        (<div style={{ height: '250px' }}>
                            {this.basicInputs('fLabDescription', this.getText(11283), {})}
                        </div>))}
                    {this.panelValue((window.innerWidth > 600) ? 'items' : 'logEvents', this.getText(11284), logsTable)}
                    {this.panelValue('items', this.getText(11285), itemsTable)}
                    {(this.props.ui.data && this.props.ui.data.fixId) ?
                        this.panelValue('custDialog', this.getText(11286), custDialogTable) : ''}

                </Collapse>
            </Form>

            <GenericModal
                visible={this.state.modalCustData}
                onCancel={() => { this.setState({ modalCustData: false }) }}
                title={this.getText(20428)}
                footer={[
                    <Button type="primary" onClick={() => { this.setState({ modalCustData: false }) }}>{this.getText(20431)}</Button>
                ]}
            >
                <div style={{ columnWidth: 'auto', columnCount: window.innerWidth > 600 ? '3' : '1' }}>
                    {this.props.data[this.custFields_id].data.map(x => {
                        let y = x.split("\f");
                        let cfLabel = y[1]
                        let cfValue = y[2]
                        let cfType = y[4]
                        let cfDataset = y[5]
                        switch (cfType) {
                            case '0':
                                return (<h1>{cfLabel}</h1>);
                            case '5':
                                return (<p><Row><Col span={10}><b>{cfLabel}:</b></Col> {cfValue ? moment(cfValue).format("DD/MM/YYYY") : ""}</Row></p>)
                            case '6':
                                return (<p><Row><Col span={10}><b>{cfLabel}:</b></Col> {this.getText(cfValue == '1' ? 20429 : 20430)}</Row></p>)
                            case '7': case '8':
                                let list = cfDataset?.indexOf('[') > -1 ? JSON.parse(cfDataset) : [];
                                let findData = list.find(f => f.code == cfValue)
                                return (<p><Row><Col span={10}><b>{cfLabel}:</b></Col> {cfValue + (findData ? " - " + findData.name : "")}</Row></p>)
                            case '9': case '13':
                                let origin = window.location.origin;
                                const isDev = origin == 'http://147.235.163.248' || origin == 'http://localhost:3000'
                                let baseUrl = isDev ? "http://147.235.163.248" : origin;
                                let url = baseUrl + "/uploads/" + this.props.user.companyCode + "/" + cfValue
                                return (<p><Row><Col span={10}><b>{cfLabel}:</b></Col> {cfValue ? (<img alt="example" style={{ width: 50 }} src={url} />) : ""}</Row></p>)
                            case '11':
                                let list11 = [
                                    { code: '1', name: this.getText(16788) },
                                    { code: '2', name: this.getText(16790) },
                                    { code: '3', name: this.getText(16789) },
                                    { code: '4', name: this.getText(16791) },
                                    { code: '5', name: this.getText(18136) },
                                ]
                                let findData11 = list11.find(f => f.code == cfValue)
                                return (<p><Row><Col span={10}><b>{cfLabel}:</b></Col> {cfValue + (findData11 ? " - " + findData11.name : "")}</Row></p>)
                            default:
                                return (<p><Row><Col span={10}><b>{cfLabel}:</b></Col> {cfValue}</Row></p>)
                        }
                    })}
                </div>
            </GenericModal>
        </Modal>)
    }

}

export default ModalCreateEditFixLabDocuments;
