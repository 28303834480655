
import React from "react";
import { DatePicker, Popover, Form, Input, InputNumber, Icon, Button, Popconfirm, Tooltip, Modal, Table, Select, Row, Col, Checkbox, Radio } from 'antd';

import { Job, User, OutputType, ProcessType } from '../JmReact';
import Jax from "../Jax/jax.es6.module";
import PageToolbar from "./PageToolbar";
import TableIDs from '../data/TableIDs.js';
import InputScanner from "./InputScanner";
import LangContext from "../contextProvider/LangContext";
import moment from "moment";
import { escapeRegExp } from "../utils/utils";
import DateTimePicker from "./DateTimePicker";
import GenericSelectorPosStorages from "./GenericSelectorPosStorages";
import ModalDocumentLines from "./ModalDocumentLines";

const FormItem = Form.Item;
const { Option, OptGroup } = Select;
const { TextArea } = Input;


//type State = {}
class ModalDocumentSelector extends React.Component/*<Props, State>*/ {
    // props: user,tableFormat,apiCalls
    constructor(props/*: Props*/) {
        super(props);
        this.state = {
            loading: false,
            visible: false,
            mWords: '',

            modalLines: null,

            mLowerSelectionDate: null,
            mUpperSelectionDate: null,
            mLowerSelectionTime: '00:00:00',
            mUpperSelectionTime: '23:59:59',
            mApplyCreditCardSearchQuery: null,
            mItemCode: null,
            mCustomer: null,
            mEmployeeList: null,
            mDocumentIsClosed: null,
            mSumFrom: null,
            mSumTo: null,
            mSumAbsolute: 0,
            mSupplierDoc: null,
            mSupplierDocDateFrom: null,
            mSupplierDocDateTo: null,
            mTagList: '',
            mStorageList: '',
            mCustTagList: '',
            mItemSerialNumber: '',
            searchColFilter: "4",
            mCustWithConnected: "0",
            mCustomerList: "",
            mCustType: "0",

            addRowTitle: false,
        }

        this.id = "MODAL_DOC_SELECTOR";
        //this.state = { defaultItemCode: "", itemCodeSelectionVisible: false };
        // calc page height
        this.state.height = window.innerHeight;
        this.doOnResize = null;
        if (props.data[this.id] === undefined) { props.data[this.id] = { ...props.data.genericData }; }
        if (props.data[TableIDs.genericSelectorPos] === undefined) { props.data[TableIDs.genericSelectorPos] = { ...props.data.genericSelector }; }
        this.api = {
            selector_pos: "list_pos",
            selector_docTypes: "get_document_types_list",
            selector_storages: "get_storage_list",
        }
        this.tableApi = {
            get: "get_doc_search_table_list",
        }
        this.actionButtons = [{
            buttonType: 'refresh',
            buttonFunction: this.refreshDataFilters
        }]

    }
    static contextType = LangContext;
    getText = (id) => { return this.context.get(id) || '[' + id + ']' }
    refreshData = () => {
        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.genericSelectorRefreshDataset,
            args: [TableIDs.genericSelectorPos, this.props.user.companyCode, this.props.user.token, this.api.selector_pos]
        });
        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.genericSelectorRefreshDataset,
            args: [TableIDs.genericSelectorStorages, this.props.user.companyCode, this.props.user.token, this.api.selector_storages]
        });
        this.props.ActionQueue.addToQueue({
            action: this.props.dataActions.genericSelectorRefreshDataset,
            args: [TableIDs.genericSelectorDocumentTypes, this.props.user.companyCode, this.props.user.token, this.api.selector_docTypes]
        });

    }
    refreshDataFilters = () => {
        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, this.props.data[this.id].filters, this.tableApi.get);
    }
    initFilters = (mWords) => {
        let fullPosList = this.props.data[TableIDs.genericSelectorPos].dataset.map((obj) => obj['code']).join(',');
        let filters = {
            mLowerSelectionDate: null,
            mUpperSelectionDate: null,
            mLowerSelectionTime: '00:00:00',
            mUpperSelectionTime: '23:59:59',
            mApplyCreditCardSearchQuery: null,
            mItemCode: null,
            mCustomer: null,
            mEmployeeList: null,
            mDocumentIsClosed: null,
            mSumFrom: null,
            mSumTo: null,
            mSumAbsolute: 0,
            mSupplierDoc: null,
            mSupplierDocDateFrom: null,
            mSupplierDocDateTo: null,
            mTagList: '',
            mCustTagList: '',

            mItemSerialNumber: '',

            //mWords: '',
            mPosList: this.state.mPosList === '' ? fullPosList : this.state.mPosList,
            mDocTypeList: this.state.mDocTypeList,
            mStorageList: this.state.mStorageList,
            searchColFilter: this.state.searchColFilter,
            mCustWithConnected: "0",
            mCustomerList: "",

            mCustType: "0",

            _id: 0,
            // ui
            page: 1,
            recordsPerPage: 10,
            sortType: 1,
            sortColumn: 1,
            // api
            _words: escapeRegExp(mWords), //? mWords.trim().replace(/\s\s+/g, ' ').replace(/\'/g, "\\\'") : "", //this.state.mWords,
            _page_num: 1,
            _rows_num: 10,
            _isAsc: 0,
            _sort_by: 'col_0',
        };

        let filterKeys = Object.keys(filters);
        let dataSend = filterKeys.join("\f") + "\r" + filterKeys.map(x => filters[x]).join("\f");
        console.log("handleFilterWhenOpen", mWords, filters)
        this.props.sendAPI(this.tableApi.get, dataSend, (ob) => {
            let data = ob.data ? ob.data.split("\r") : [];
            let headers = data.length ? data.splice(0, 1)[0].split("\f") : [];
            let count = data.length ? parseInt(data.splice(-1)[0]) : 0;
            let pagination = { ...this.props.data.MODAL_DOC_SELECTOR.pagination, total: count }
            console.log("handleFilterWhenOpen res", ob.data, count)
            if (mWords && !count) this.setState({ mWords: "" }, () => { this.initFilters("") });
            else this.props.dataActions.setJson("MODAL_DOC_SELECTOR", { data, count, headers, filters, pagination });
        });
        // this.props.dataActions.genericDataSetFilter(
        //     this.id, this.props.user.companyCode, this.props.user.token, request, this.tableApi.get);

        //  this.refreshDataSelectors();
    }

    componentDidMount() {
        // document.title = "Cash On Tab - דו\"חות";
        this.refreshData();
        // setTimeout(() => { this.initFilters(); }, 1500)
    }
    handleTableChange = (pagination: Object, filters: Object, sorter: Object) => {
        let col = 11;
        // fall through
        switch (sorter.field) {
            case "key": col--; // 0
            case "DocumentPOSID": col--;
            case "POSName": col--;
            case "DocTypeName": col--;
            case "DocumentNumber": col--;
            case "DocumentCustomerName": col--;
            case "DocumentTotalWithTax": col--;
            case "DocumentTimestamp": col--;
            case "EmployeeName": col--;
            case "DocZNum": col--;
            case "StorageName": col--;
            case "DocRef2": break; // 11

            //case "EmployeeName": col--;
            //case "DocumentEmployeeID": col--;
            //case "DocumentTypeId": break; // 10
            //case "DocumentTypeName": break; //10
            default:
                col = 0;
        }

        let request = {
            // just of ui-pagination update
            page: pagination.current,
            recordsPerPage: pagination.pageSize,
            sortType: sorter.order === "ascend" ? 1 : 0,
            //
            _page_num: pagination.current,
            _rows_num: pagination.pageSize,
            _isAsc: sorter.order === "ascend" ? 1 : 0,
            _sort_by: 'col_' + col,
            _words: escapeRegExp(this.state.mWords),
        };

        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, request, this.tableApi.get)
    }

    handleFilter = () => {
        let fullPosList = Jax.get(this.props.data, TableIDs.genericSelectorPos + ".dataset", []).map((obj) => obj['code']).join(',');
        this.props.dataActions.genericDataSetFilter(
            'MODAL_DOC_SELECTOR', this.props.user.companyCode, this.props.user.token,
            {
                mLowerSelectionDate: null,
                mUpperSelectionDate: null,
                mLowerSelectionTime: '00:00:00',
                mUpperSelectionTime: '23:59:59',
                mApplyCreditCardSearchQuery: null,
                mItemCode: null,
                mCustomer: null,
                mEmployeeList: null,
                mDocumentIsClosed: null,
                mSumFrom: null,
                mSumTo: null,
                mSumAbsolute: 0,
                mSupplierDoc: null,
                mSupplierDocDateFrom: null,
                mSupplierDocDateTo: null,
                mTagList: '',
                mCustTagList: '',
                mItemSerialNumber: '',
                mDocTypeList: '',
                mStorageList: this.state.mStorageList,
                searchColFilter: this.state.searchColFilter,
                mCustWithConnected: "0",
                mCustomerList: "",
                mCustType: "0",

                _id: 0,
                // ui
                page: 1,
                recordsPerPage: 10,
                sortType: 1,
                sortColumn: 1,
                //    
                _page_num: 1,
                _rows_num: 10,
                _isAsc: 0,
                _sort_by: 'col_0',

                ...this.state,
                //2
                mPosList: this.state.mPosList === '' ? fullPosList : this.state.mPosList,
                _words: escapeRegExp(this.state.mWords)// ? this.state.mWords.trim().replace(/\s\s+/g, ' ').replace(/\'/g, "\\\'") : ""
            }, this.tableApi.get);
    }

    handleOk = () => { }
    handleClear = () => { }
    handleCancel = () => {
        this.setState({ visible: false }, () => {
            this.props.uiActions.hideModal(TableIDs.modalDocumentSelector);
            this.barcodeReaderBuffer = '';
            this.isBufferFull = false;
            this.ctrlBKeyFlag = false;

        });
    }

    createCheckBox = (field, data) => {
        return (
            <Checkbox
                style={{ display: 'inline' }}
                onChange={(e) => {
                    if (e.target.checked)
                        this.setState({ [field]: data });
                    else
                        this.setState({ [field]: '' });
                }} />);
    }

    onChange = (field, value) => { this.setState({ [field]: value, }); }
    onStartChange = (value) => {
        this.onChange('mLowerSelectionDate', value ? value.format("YYYY-MM-DD") : null);
    }

    onEndChange = (value) => {
        this.onChange('mUpperSelectionDate', value ? value.format("YYYY-MM-DD") : null);
    }

    handleAddDocLines = (DocumentNumber, DocumentPOSID, DocumentTypeId, addRowDocTitle, DocTypeName, next) => {
        // let curSignFactor = this.props.data.DocTypesCategories.find(f => f.id == this.props.documentType)?.signFactor;
        // let sourceSignFactor = this.props.data.DocTypesCategories.find(f => f.id == DocumentTypeId)?.signFactor;
        // let signFactor = (curSignFactor ?? 1) * (sourceSignFactor ?? 1)
        let factorPlus = [4, 16]
        let factorMinus = [17, 18]
        let inversionSignFactor = (factorPlus.find(f => f == this.props.documentType) && factorMinus.find(f => f == DocumentTypeId))
            || (factorMinus.find(f => f == this.props.documentType) && factorPlus.find(f => f == DocumentTypeId))
        const addDocLine = factor => { this.props.handleAddDocLines(DocumentNumber, DocumentPOSID, DocumentTypeId, addRowDocTitle, DocTypeName, factor) }
        if (!inversionSignFactor) {
            addDocLine(1)
            if (next) next()
        } else {
            Modal.confirm({
                title: "",
                content: this.getText(20285),
                iconType: 'exclamation-circle',
                okText: this.getText(20286),
                cancelText: this.getText(20287),
                onOk: () => {
                    addDocLine(-1);
                    if (next) next()
                },
                onCancel: () => {
                    addDocLine(1);
                    if (next) next()
                },
            })
        }
    }

    render() {
        console.log("handleFilterWhenOpen", this.props.data.MODAL_DOC_SELECTOR)
        const { loading } = this.state;
        let listPos = Jax.get(this.props.data, TableIDs.genericSelectorPos + ".dataset", []).map((obj, index) => {
            return (<Option key={obj['code']} value={obj['code']} valuefilter={obj['code'] + ' ' + obj['name']}>{obj['code'] + ' - ' + obj['name']}</Option>)
        });
        let listStorages = Jax.get(this.props.data, TableIDs.genericSelectorStorages + ".dataset", []).map((obj, index) => {
            return (<Option key={obj['code']} value={obj['code']} valuefilter={obj['code'] + ' ' + obj['name']}>{obj['code'] + ' - ' + obj['name']}</Option>)
        });
        let listDocType = Jax.get(this.props.data, TableIDs.genericSelectorDocumentTypes + ".dataset", []).map((obj, index) => {
            if (obj['code'] < 90)
                return (<Option key={obj['code']} value={obj['code']} valuefilter={obj['code'] + ' ' + obj['name']}>{obj['code'] + ' - ' + obj['name']}</Option>)
        });

        let titleBar = (
            <div style={{
                paddingLeft: '4px',
                paddingRight: '4px',
                paddingTop: '4px',
                paddingBottom: '0px',
            }}>
                <PageToolbar
                    title={this.getText(15425)}
                    ui={this.props.ui} uiActions={this.props.uiActions} data={this.props.data} dataActions={this.props.dataActions}
                    actionButtons={this.actionButtons}
                    isModal={true}
                />
            </div>)

        const dataSource = this.props.data[this.id]?.data.map((x, index) => {
            let y = x.split("\f");
            return {
                //key: y[0],
                key: y[0] + '#' + y[1] + '#' + y[2],
                DocumentNumber: y[0],
                DocumentTypeId: y[1],
                DocumentPOSID: y[2],
                DocumentTimestamp: y[3],
                DocumentEmployeeID: y[4],
                DocumentTotalWithTax: y[5],
                DocumentCustomerName: y[6],
                POSName: y[7],
                EmployeeName: y[8],
                //DocumentTypeName: y[9],
                DocTypeName: y[10],
                DocZNum: y[11],
                DocIsForInventory: y[12],
                StorageName: y[13],
                docIsMatrix: y[14],
                docIsClosed: y[15],
                DocRef2: y[17],
                index: index
            }
        });
        // let dataSource = (this.props.data[this.id].data['dataset']) ? this.props.data[this.id].data['dataset'] : [];
        let columns = [
            // {
            //     title: "קוד קופה",
            //     dataIndex: "DocumentPOSID",
            //     key: "DocumentPOSID",
            //     sorter: true,
            //     width: '8%',
            //     render: (text, record) => text,
            // }, 
            {
                title: this.getText(11169),
                dataIndex: "DocumentPOSID",
                key: "DocumentPOSID",
                sorter: true,
                width: "7%",
                render: (text, record) => (<Tooltip title={record.POSName}>{text}</Tooltip>)
            }, {
                title: this.getText(11170),
                dataIndex: "StorageName",
                key: "StorageName",
                sorter: true,
                width: "13%",
                render: (text, record) => text,
            }, {
                title: this.getText(11171),
                dataIndex: "DocumentCustomerName",
                key: "DocumentCustomerName",
                sorter: true,
                width: '15%',
                render: (text, record) => text,
            }, {
                title: this.getText(11172),
                //dataIndex: "DocumentTypeName",
                dataIndex: "DocTypeName",
                //key: "DocumentTypeName",
                key: "DocTypeName",
                sorter: true,
                width: "15%",
                render: (text, record) => text,
                // }, {
                //     title: "מספר z",
                //     //dataIndex: "DocumentTypeName",
                //     dataIndex: "DocZNum",
                //     //key: "DocumentTypeName",
                //     key: "DocZNum",
                //     sorter: true,
                //     width: '10%',
                //     render: (text, record) => text,
            }, {
                title: this.getText(11173),
                dataIndex: "DocumentNumber",
                key: "DocumentNumber",
                sorter: true,
                width: "10%",
                render: (text, record) => (<Tooltip title={this.getText(11178) + " : " + record.DocZNum}>{text}</Tooltip>)
            }, {
                title: this.getText(11173) + " 2",
                dataIndex: "DocRef2",
                key: "DocRef2",
                sorter: true,
                width: "10%",
                render: (text, record) => text
            }, {
                title: this.getText(11174),
                dataIndex: "DocumentTotalWithTax",
                key: "DocumentTotalWithTax",
                sorter: true,
                width: '10%',
                render: (text, record) => (<div style={{ textAlign: "right" }}>{parseFloat(text).toFixed(2)}</div>),
            }, {
                title: this.getText(11175),
                dataIndex: "DocumentTimestamp",
                key: "DocumentTimestamp",
                sorter: true,
                width: "13%",
                render: (text, record) => text // moment(text, "DD/MM/YY HH:mm:ss").format("DD/MM/YYYY HH:mm"),
            },/* {
                title: this.getText(11176),
                dataIndex: "EmployeeName",
                key: "EmployeeName",
                sorter: true,
                width: '15%',
                render: (text, record) => text,
            },*/ {
                title: "" /*this.getText(11177)*/,
                width: "7%",
                render: (text, record) => {
                    let addRowDocTitle = this.state.addRowTitle ? record.DocTypeName + " " + record.DocumentNumber + " " + record.DocumentTimestamp : null
                    return (
                        <span>
                            <Tooltip title={this.getText(15426)}>
                                <Icon style={{ fontSize: 16, }} className={"clickable margin-4"} /*type="plus"*/ type="file-add" theme="outlined"
                                    onClick={() => {
                                        // TODO: ADD LINES
                                        this.handleAddDocLines(record.DocumentNumber, record.DocumentPOSID, record.DocumentTypeId, addRowDocTitle, record.DocTypeName);
                                    }} />
                            </Tooltip>
                            <Tooltip title={this.getText(17083)}>
                                <Icon className={"clickable margin-4"} style={{ fontSize: 16 }} type="eye" theme="outlined" onClick={() => { this.setState({ modalLines: record }) }} />
                            </Tooltip>
                            {[4, 8, 9, 18].find(f => f == record.DocumentTypeId) ?
                                record.docIsClosed == '1' ?
                                    <Icon style={{ fontSize: 16, color: "#aaaaaa" }} className={"clickable margin-4"} type="file-done" theme="outlined" /> :
                                    <Tooltip title={this.getText(19888)}>
                                        <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} /*type="plus"*/ type="file-done" theme="outlined"
                                            onClick={() => {
                                                this.handleAddDocLines(record.DocumentNumber, record.DocumentPOSID, record.DocumentTypeId, addRowDocTitle, record.DocTypeName, () => {
                                                    let data = "DocumentIsClosed\fDocumentNumber\fDocumentTypeId\fDocumentPOSID\r1\f"
                                                        + record.DocumentNumber + "\f" + record.DocumentTypeId + "\f" + record.DocumentPOSID;
                                                    this.props.sendAPI("edit_doc_is_closed", data, this.refreshDataFilters)
                                                });
                                            }} />
                                    </Tooltip>
                                : ""}
                        </span>
                    )
                }
            }
        ];

        if (window.innerWidth <= 600) {
            columns = columns.filter(i => {
                return i.dataIndex === "DocTypeName"
                    || i.dataIndex === "DocumentNumber"
                    || i.dataIndex === "DocumentTimestamp"
                    || !(i.dataIndex)
            }).map(i => {
                if (i.dataIndex === "DocTypeName") {
                    i.width = '30%';
                } else if (i.dataIndex === "DocumentNumber") {
                    i.width = '30%';
                } else if (i.dataIndex === "DocumentTimestamp") {
                    i.width = '25%';
                }
                return i;
            });
        }

        return (
            <Modal
                //style={modalStyle}
                destroyOnClose={true}
                width={window.innerWidth > 1200 ? 1200 : '90%'}
                visible={false || this.props.toggle}
                // title={this.props.title} // pass title in props for generics
                title={titleBar}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                //footer={false}
                footer={
                    [
                        <Button key="back" onClick={this.handleCancel}>{this.getText(15427)}</Button>,
                        // <Button key="back" onClick={this.handleCancel}>ביטול</Button>,
                        // <Button key="clear" loading={loading} onClick={this.handleClear}>ניקוי</Button>,
                        // <Button key="create" type="primary" loading={loading} onClick={this.handleOk}>שמור</Button>,
                    ]}
                ui={this.props.ui} uiActions={this.props.uiActions}
                data={this.props.data} dataAction={this.props.dataAction}
            >
                <div style={{ marginBottom: 8 }}>
                    <Form id="search_form" className="data-entry-form" layout='horizontal' //layout='vertical' //'horizontal' // inline  |'vertical'|'inline'
                        style={{ columnWidth: 'auto', columnCount: window.innerWidth > 600 ? '2' : '1' }}
                        onSubmit={this.handleSaveChanges}
                    >

                        <FormItem labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} label={this.getText(11188)} >

                            <GenericSelectorPosStorages {...this.props} multi
                                onChange={(value) => this.setState({ mPosList: value.join(',') })}
                                value={
                                    //console.log("mposlist\n",this.state.mPosList)
                                    (this.state)
                                        ? ((this.state.mPosList === '' || !this.state.mPosList) ? [] : this.state.mPosList.split(','))
                                        : []
                                }
                            />
                        </FormItem>

                        <FormItem labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} label={this.getText(11189)} >
                            {this.createCheckBox('mDocTypeList', Jax.get(this.props.data, TableIDs.genericSelectorDocumentTypes + ".dataset", []).map((obj, index) => obj['code']).join(','))}
                            <Select key={"mDocTypeList"} style={{ width: '90%' }}
                                showsearch
                                filterOption={true}
                                optionFilterProp="valuefilter"
                                mode="multiple"
                                maxTagCount={4}
                                onChange={(value) => this.setState({ mDocTypeList: value.join(',') })}
                                value={
                                    (this.state)
                                        ? ((this.state.mDocTypeList === '' || !this.state.mDocTypeList) ? [] : this.state.mDocTypeList.split(','))
                                        : []
                                }

                            >
                                {listDocType}
                            </Select>
                        </FormItem>

                        <FormItem labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} label={this.getText(11191)} >
                            {this.createCheckBox('mStorageList', Jax.get(this.props.data, TableIDs.genericSelectorStorages + ".dataset", []).map((obj, index) => obj['code']).join(','))}
                            <Select key={"mStorageList"} style={{ width: '90%' }}
                                showSearch
                                filterOption={true}
                                optionFilterProp="valuefilter"
                                mode="multiple"
                                maxTagCount={4}
                                onChange={(value) => this.setState({ mStorageList: value.join(',') })}
                                value={
                                    //console.log("mposlist\n",this.state.mPosList)
                                    (this.state)
                                        ? ((this.state.mStorageList === '' || !this.state.mStorageList) ? [] : this.state.mStorageList.split(','))
                                        : []
                                }

                            >
                                {listStorages}
                            </Select>
                        </FormItem>

                        <FormItem>
                            <Checkbox checked={this.state.addRowTitle} onChange={e => { this.setState({ addRowTitle: e.target.checked }) }}>
                                {this.getText(19985)}
                            </Checkbox>
                        </FormItem>

                        <FormItem labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} label={this.getText(11195)} style={{ /*margin: '4px',*/ breakInside: 'avoid-column', }}/*{...formItemLayout}*/>
                            <InputScanner
                                placeholder={this.getText(11205)}
                                style={{ width: '80%' }}
                                value={this.state.mWords}
                                skipCharsCount={8}
                                onChange={(e, isEnter) => {
                                    this.setState({ mWords: e }, () => { if (isEnter) this.handleFilter() })
                                }} />
                            <Radio.Group
                                style={{ textAlign: 'right', direction: "rtl" }}
                                value={this.state.searchColFilter}
                                onChange={e => { this.setState({ searchColFilter: e.target.value }) }}>
                                {/* <Radio value="0">{this.getText(18813)}</Radio><br /> */}
                                <Radio value="4">{this.getText(19448)}</Radio><br />
                                <Radio value="6">{this.getText(19719)}</Radio><br />
                                <Radio value="1">{this.getText(18814)}</Radio><br />
                                <Radio value="2">{this.getText(18815)}</Radio><br />
                                <Radio value="3">{this.getText(18816)}</Radio><br />
                                <Radio value="5">{this.getText(19461)}</Radio><br />
                            </Radio.Group>
                        </FormItem>

                        <FormItem labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} label={this.getText(11184)} /*mLowerSelectionDate - mLowerSelectionTime*/>

                            <DateTimePicker
                                format="DD-MM-YYYY"//"YYYY-MM-DD"
                                maxDate={this.state.mUpperSelectionDate}
                                value={this.state.mLowerSelectionDate}
                                placeholder={this.getText(11201)}
                                onChange={(value) => { this.onChange('mLowerSelectionDate', value) }}
                            />
                        </FormItem>
                        <FormItem labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} label={this.getText(11187)} /*style={isEndDateShownStyle}*/>

                            <DateTimePicker
                                format="DD-MM-YYYY"//"YYYY-MM-DD"
                                minDate={this.state.mLowerSelectionDate}
                                value={this.state.mUpperSelectionDate}
                                placeholder={this.getText(11202)}
                                onChange={(value) => { this.onChange('mUpperSelectionDate', value) }}
                            />
                        </FormItem>
                    </Form>
                </div>
                <div style={{ marginBottom: 24, }}>
                    <Button type="primary" key="filter" loading={this.props.ui.workInProgress} style={{ float: 'left', marginLeft: '10%' }} onClick={this.handleFilter}>{this.getText(15428)}</Button>,
                </div>
                <Table
                    id={'DOCUMENT_SELECTOR_TABLE'}
                    dataSource={dataSource}
                    columns={columns}
                    loading={this.props.ui.workInProgress}
                    scroll={this.props.scroll ? this.props.scroll : {}}
                    bordered
                    pagination={this.props.data[this.id]?.pagination}
                    onChange={this.handleTableChange}
                    locale={{ filterConfirm: this.getText(10245), filterReset: this.getText(10246), emptyText: this.getText(10247) }}
                />
                <ModalDocumentLines
                    user={this.props.user}
                    visible={this.state.modalLines !== null ? true : false}
                    onCancel={() => { this.setState({ modalLines: null }) }}
                    DocumentNumber={this.state.modalLines ? this.state.modalLines.DocumentNumber : 0}
                    DocumentPOSID={this.state.modalLines ? this.state.modalLines.DocumentPOSID : 0}
                    DocumentTypeId={this.state.modalLines ? this.state.modalLines.DocumentTypeId : 0}
                />
            </Modal >
        )
    }

    componentDidUpdate(prevProps) {
        if (this.props.ui.data !== prevProps.ui.data || (prevProps.toggle === false && this.props.toggle === true)) {
            let mWords = this.props.data.document2[this.props.documentType == 5051 ? "storageName2" : "customerName"];
            this.setState({ mWords }, () => { this.initFilters(mWords) })
            // this.refreshData();
        }
    }
}

export default ModalDocumentSelector;
