import React,{ Component } from "react"
import GenericModal from "../../components/GenericModal"


class PizzaSlicesExchange extends Component {
    constructor(props) {
        super(props)
       
        this.state = {}
       
        this.id = "PIZZA_SLICES_EXCHANGE"
        this.type_id = "PIZZA_SLICES_TYPES"
       
        this.api = {
            get: "get_pizza_slices_exchange",
            get_types: "get_pizza_slices_types",
            edit: "edit_pizza_slices_exchange",
            create: "create_pizza_slices_exchange",
        }
    }

    render(){
        return(<GenericModal {...this.props} title={"בפיתוח..."}>בפיתוח...</GenericModal>)
    }
}

export default PizzaSlicesExchange