import React from "react";
import GenericTable from "../../components/GenericTable";
import GenericModal from "../../components/GenericModal";
import GenericSelector from "../../components/GenericSelector";
import DateTimePicker from "../../components/DateTimePicker";
import TableLineIcons from "../../components/TableLineIcons";
import GridKeyValues from "../../components/GridKeyValues";
import LangContext from "../../contextProvider/LangContext";
import moment from "moment";
import { Button, Form, Row, Col } from "antd";
import TableIDs from "../../data/TableIDs";

const FormItem = Form.Item;
const curdate = moment(Date.now()).format("YYYY-MM-DD");

class DeliveriesTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalFilter: false,
            modalDetails: null,
            filters: {
                _dateFrom: curdate,
                _dateTo: curdate,
                _statusList: '',
            },

            filterActive: false,
            valueSearch: '',

        }

        this.id = "DELIVERIES_TABLE";
        this.lines_id = "EXTERNAL_ORDER_LINES";

        this.api = {
            get: "get_deliveries_table",
            get_lines: "get_external_order_lines",
            get_doc: "get_doc_lines",
        }

        if (props.data[this.id] === undefined) { props.data[this.id] = { ...props.data.genericData } }
        if (props.data[this.lines_id] === undefined) { props.data[this.lines_id] = { ...props.data.genericData } }
        if (props.data[TableIDs.genericSelectorDeliveryStatus] === undefined) { props.data[TableIDs.genericSelectorDeliveryStatus] = { ...props.data.genericSelector } }
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidUpdate() {
        document.title = "Cash On Tab - " + this.getText(14591);
    }

    getIcons = (record) => {
        return (<TableLineIcons
            genericIcons={[
                {
                    type: 'eye',
                    tooltip: this.getText(14602),
                    onClick: () => {
                        this.setState({ modalDetails: record })
                    },
                }, {
                    type: 'environment',
                    tooltip: this.getText(20312),
                    onClick: () => {
                        window.open(record.yTrackingUrl, '_blank')
                    },
                }, {
                    type: 'barcode',
                    tooltip: this.getText(20313),
                    onClick: () => {
                        window.open(record.yBarcodeUrl, '_blank')
                    },
                }
            ]} />)
    }

    getModal = (state, title, width, content, footer) => {
        return this.state[state] ? (<GenericModal visible
            onCancel={() => { this.setState({ [state]: false }) }}
            width={width} title={this.getText(title)} footer={footer}
        >{content}</GenericModal>) : ""
    }

    clearFilter = () => {
        this.setState({
            filters: {
                _dateFrom: null,
                _dateTo: null,
                _statusList: '',
            },
            filterActive: false,
            valueSearch: '',
        }, () => {
            this.props.dataActions.genericDataSetFilter(
                this.id, this.props.user.companyCode, this.props.user.token, {
                _dateFrom: null,
                _dateTo: null,
                _statusList: '',
                _words: '',
            }, this.api.get);
        });
    }

    filterField = (label, field) => {
        let frame = (content) => {
            return (<Col span={window.innerWidth <= 600 ? 24 : 8}>
                <FormItem label={this.getText(label)}>{content}</FormItem>
            </Col>)
        }
        let value = this.state.filters[field];
        let onChange = (e) => {
            this.setState({
                filters: {
                    ...this.state.filters,
                    [field]: e,
                }
            })
        }

        switch (field) {
            case '_statusList':
                return frame(<GenericSelector
                    id={TableIDs.genericSelectorDeliveryStatus} api={"delivery_status_list"} multi
                    value={value ? value.split(',') : []} onChange={(e) => { onChange(e ? e.join(',') : '') }}
                    user={this.props.user} dataActions={this.props.dataActions} data={this.props.data}
                />);
            case '_dateFrom':
                return frame(<DateTimePicker format={"DD/MM/YYYY"} value={value} onChange={onChange} maxDate={this.state.filters._dateTo} />);
            case '_dateTo':
                return frame(<DateTimePicker format={"DD/MM/YYYY"} value={value} onChange={onChange} minDate={this.state.filters._dateFrom} />);
        }
    }

    checkFilter = () => {
        this.setState({ modalFilter: false }, () => {
            this.props.dataActions.genericDataSetFilter(
                this.id, this.props.user.companyCode, this.props.user.token, this.state.filters, this.api.get);
        });
        this.setState({ filterActive: true })
    }

    sumFormat = (n) => {
        return n ? (Math.round(parseFloat(n) * 100) / 100).toFixed(2) : '0.00'
    }

    itemDetails = (text, record) => {
        //iGroup, iCode, iName, iPrice, iFoodDishId, iLevelJson, iOriginalPrice, iFatherCode, iColorID, iColorDesc, iSize1ID, iSize2ID, iSizeDesc, iQty, iMivza, iTotalSum   
        if (record.iFoodDishId && record.iLevelJson) {
            let dataObj = JSON.parse(record.iLevelJson);
            return dataObj.levels.map((l) => {
                return (<div className={'text_item'} style={{ marginTop: 5, padding: 5 }}>
                    <div style={{ fontSize: 17, fontWeight: 600 }}>{l.name}</div>
                    <div style={{ fontSize: 15 }}>
                        {l.ownerData.map((o) => {
                            return (<div>
                                <div>{o.oItemName}</div>
                                <div>{o.oExtraItems ? this.sortExtraItems(o.oExtraItems) : ""}</div>
                            </div>)
                        })}
                    </div>
                </div>)
            })
        } else if (record.iSizeDesc || record.iColorDesc) {
            let sDiv = record.iSizeDesc ? (<div>{this.getText(14500)}: {record.iSizeDesc}</div>) : ""
            let cDiv = record.iColorDesc ? (<div>{this.getText(14501)}: {record.iColorDesc}</div>) : ""
            return (<div className={'text_item'} style={{ fontSize: 20, lineHeight: 1.2, minHeight: 40 }}>{sDiv}{cDiv}</div>)
        } else {
            return ''
        }
    }

    render() {
        let divStyle = {
            align: "center",
            dir: "rtl", // dir: 'ltr'
            padding: "2% 2% 2% 2%",
        };

        let gt = this.getText;
        let colTxt = (t) => t

        let columns = [
            { title: gt(14593), key: 'yID', dataIndex: 'yID', width: '5%', sorter: true, render: colTxt },
            { title: gt(14594), key: 'yPos', dataIndex: 'yPos', width: '15%', sorter: true, render: colTxt },
            { title: gt(14595), key: 'yRefNum', dataIndex: 'yRefNum', width: '10%', sorter: true, render: colTxt },
            { title: gt(14596), key: 'yEmployee', dataIndex: 'yEmployee', width: '15%', sorter: true, render: colTxt },
            { title: gt(14597), key: 'yBundle', dataIndex: 'yBundle', width: '10%', sorter: true, render: colTxt },
            { title: gt(14598), key: 'yRequired', dataIndex: 'yRequired', width: '15%', sorter: true, render: colTxt },
            { title: gt(14599), key: 'yStatus', dataIndex: 'yStatus', width: '10%', sorter: true, render: colTxt },
            { title: gt(14600), key: 'yStatusUpdate', dataIndex: 'yStatusUpdate', width: '15%', sorter: true, render: colTxt },
            { title: gt(14601), width: '5%', render: (t, r) => this.getIcons(r) },
        ];

        let detailsColumns = [
            { title: gt(14493), key: 'iCode', dataIndex: 'iCode', width: '10%', render: colTxt },
            { title: gt(14494), key: 'iName', dataIndex: 'iName', width: '20%', render: colTxt },
            { title: gt(14495), width: '30%', render: this.itemDetails },
            { title: gt(14496), key: 'iPrice', dataIndex: 'iPrice', width: '10%', render: this.sumFormat },
            { title: gt(14497), key: 'iQty', dataIndex: 'iQty', width: '10%', render: this.sumFormat },
            { title: gt(14498), key: 'iMivza', dataIndex: 'iMivza', width: '10%', render: this.sumFormat },
            { title: gt(14499), key: 'iTotalSum', dataIndex: 'iTotalSum', width: '10%', render: this.sumFormat },
        ];

        let filterFields = (<Row>
            {this.filterField(14607, '_dateFrom')}
            {this.filterField(14608, '_dateTo')}
            {this.filterField(14609, '_statusList')}
        </Row>);

        let filterButtons = [
            <Button key="back" onClick={() => { this.setState({ modalFilter: false }) }}>{gt(14604)}</Button>,
            <Button key="clear" onClick={this.clearFilter}>{gt(14605)}</Button>,
            <Button key="create" type="primary" onClick={this.checkFilter}>{gt(14606)}</Button>,
        ];

        let md = this.state.modalDetails;
        let isOrder = md ? md.yOwnerId : null;
        let detailsFilter = isOrder ? { oID: isOrder } : md ? { docNum: md.yRefNum, docType: md.yDocType, docPos: md.yDocPos } : {}

        let detailsFields = [
            {
                span: 8,
                list: [
                    { key: gt(14593), value: md ? md['yID'] : '' },
                    { key: gt(14594), value: md ? md['yPos'] : '' },
                    { key: gt(14595), value: md ? md['yRefNum'] : '' },
                    { key: gt(14596), value: md ? md['yEmployee'] : '' },
                    { key: gt(14597), value: md ? md['yBundle'] : '' },
                    { key: gt(14598), value: md ? md['yRequired'] : '' },
                    { key: gt(14599), value: md ? md['yStatus'] : '' },
                    { key: gt(14600), value: md ? md['yStatusUpdate'] : '' },
                ]
            }, {
                span: 8,
                list: [
                    { key: gt(14615), value: md ? md['yCustomerName'] : '' },
                    { key: gt(14616), value: md ? md['yCustomerPhone'] : '' },
                    { key: gt(14617), value: md ? md['yCity'] : '' },
                    { key: gt(14618), value: md ? md['yStreet'] : '' },
                    { key: gt(14619), value: md ? md['yBuilding'] : '' },
                    { key: gt(14620), value: md ? md['yEntrance'] : '' },
                    { key: gt(14621), value: md ? md['yFloor'] : '' },
                    { key: gt(14622), value: md ? md['yApartement'] : '' },
                ]
            }, {
                span: 8,
                list: [
                    { key: gt(14623), value: md ? md['yTotalToPAy'] : '' },
                    { key: gt(14624), value: md ? md['yExtRefference'] : '' },
                    { key: gt(14626), value: md ? md['yTargetAlt'] : '' },
                    { key: gt(14625), value: md ? md['yTargetLong'] : '' },
                    { key: gt(14627), value: md ? md['yCalculateMinutes'] : '' },
                    { key: gt(14628), value: md ? md['yRemarks'] : '' },
                ]
            }
        ]

        return (<div style={divStyle}>
            <GenericTable
                id={this.id} getApi={this.api.get} title={gt(14592)}
                filters={this.state.filters}
                columns={columns}
                actionButtons={[
                    {
                        buttonType: 'filter',
                        buttonFunction: () => { this.setState({ modalFilter: true }) }
                    }, {
                        buttonDisabled: !this.state.filterActive,
                        buttonType: 'clear',
                        buttonFunction: this.clearFilter,
                    }
                ]}
                percentageToMobile={[20, 0, 50, 30]}
                searchBox={true}
                valueSearch={this.state.valueSearch}
                onChangeSearch={e => { this.setState({ valueSearch: e.target.value }) }}
                setFilterActive={e => { this.setState({ filterActive: e }) }}
                data={this.props.data} dataActions={this.props.dataActions}
                ui={this.props.ui} uiActions={this.props.uiActions} user={this.props.user}
            />
            <GenericModal
                visible={this.state.modalFilter}
                onCancel={() => { this.setState({ modalFilter: false }) }}
                width={'80%'} title={gt(14603)} footer={filterButtons}
            >
                {filterFields}
            </GenericModal>
            <GenericModal
                visible={md}
                onCancel={() => { this.setState({ modalDetails: null }) }}
                width={'95%'} title={gt(14602)}
            >
                {md ?
                    (<div>
                        <GridKeyValues content={detailsFields} />
                        <GenericTable
                            id={this.lines_id}
                            getApi={isOrder ? this.api.get_lines : this.api.get_doc}
                            title={gt(14613)}
                            columns={detailsColumns}
                            filters={detailsFilter}
                            scroll={{ y: 500 }}
                            percentageToMobile={[40, 60]}
                            data={this.props.data} dataActions={this.props.dataActions}
                            ui={this.props.ui} uiActions={this.props.uiActions} user={this.props.user}
                        />
                    </div>)
                    : ""}
            </GenericModal>
        </div>)
    }

}
export default DeliveriesTable;