import React, { Component } from "react";
import GenericModal from "./GenericModal";
import { Button, Checkbox, Icon, Tooltip } from "antd";
import LangContext from "../contextProvider/LangContext";
import { Job, OutputType, ProcessType, User } from "../JmReact";
import ResponsiveTable from "./ResponsiveTable";


class UsersTableColumns extends Component {
    state = {
        modalOpen: false,
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    sendAPI = (script, data, sCal) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCal, (e) => { console.error(e) });
    }

    componentDidMount() {
        const { table, list, setViewList } = this.props
        this.sendAPI("get_users_table_columns", "_table\r" + table, ob => { setViewList(ob.data ? ob.data.split(",") : list?.map(x => x.key)) })
    }

    onDragAndDrop = (keyFrom, keyTo) => {
        const { viewList, setViewList } = this.props
        let newList = []
        let indexKeyFrom = viewList.findIndex(x => x == keyFrom)
        let indexKeyTo = viewList.findIndex(x => x == keyTo)
        if (indexKeyFrom > -1 && indexKeyTo > -1) {
            viewList.forEach(x => {
                if (x == keyTo && indexKeyTo < indexKeyFrom) newList.push(keyFrom)
                if (x != keyFrom) newList.push(x)
                if (x == keyTo && indexKeyTo > indexKeyFrom) newList.push(keyFrom)
            })
            setViewList(newList)
        }
    }

    handleOk = () => {
        const { viewList, table } = this.props
        this.sendAPI("set_users_table_columns", "_table\f_list\r" + table + "\f" + viewList.join(","), () => { this.setState({ modalOpen: false }) })
    }

    renderCB = (key) => {
        const { viewList, setViewList } = this.props
        return (<Checkbox
            checked={viewList?.find(f => f == key) ? true : false}
            onChange={e => {
                let newList = viewList ?? []
                if (e.target.checked) newList.push(key)
                else newList = newList.filter(f => f != key)
                setViewList(newList)
            }} />)
    }

    render() {
        const gt = this.getText
        const btnStyle = { marginTop: "5px", marginLeft: "5px" }
        const iconStyle = { fontSize: "16px" }
        const { list, viewList, title } = this.props
        let dataSource = viewList ? [...viewList.map(x => list?.find(f => f.key == x)), ...list.filter(f => !viewList.find(ff => ff == f.key))] : list

        return (<>
            <Tooltip title={gt(20396)}>
                <Button className={"btn"} style={btnStyle} onClick={() => { this.setState({ modalOpen: true }) }}>
                    <Icon type={"setting"} style={iconStyle} theme={"filled"} />
                </Button>
            </Tooltip>
            <GenericModal
                visible={this.state.modalOpen}
                onCancel={() => { this.setState({ modalOpen: false }) }}
                title={title}
                width={300}
                footer={[
                    <Button key="back" onClick={() => { this.setState({ modalOpen: false }) }}>{gt(20397)}</Button>,
                    <Button key="create" type="primary" onClick={this.handleOk}>{gt(20398)}</Button>,
                ]}>
                <ResponsiveTable
                    tableOnly
                    id={"USERS_TABLE_COLUMNS"}
                    onDragAndDrop={this.onDragAndDrop}
                    dataSource={dataSource}
                    columns={[
                        { key: "key", dataIndex: "key", width: "20%", render: this.renderCB },
                        { key: "name", dataIndex: "name", width: "80%", render: t => t },
                    ]}
                    pagination={false}
                    scroll={{ y: window.innerHeight - 300 }}
                />
            </GenericModal>
        </>)
    }
}

export default UsersTableColumns