
/* @flow */
/*jshint esversion: 6 */

import React, { Component } from "react";
import { Card, InputNumber, Input, Checkbox, Form, DatePicker, Select, Radio, Button, Icon } from "antd";
import "../App.css";
import DateTimePicker from "../components/DateTimePicker"
import LockPicker from "../components/LockPicker"
import SignatureInput from "../components/SignatureInputV2";
import moment from "moment";

class QuestionnaireForm extends Component {
    constructor(props) {
        super(props);

        this.id = "QUESTIONNAIRE_QUESTIONS_LIST";

        if (props.data[this.id] === undefined) props.data[this.id] = { data: [], qID: null }

        this.api = {
            get: "get_questionnaires_questions",
        }

        this.innerHTMLList = [];
    }

    componentDidMount() {
        if (this.props.qId) this.getApiQQ()
    }

    componentDidUpdate(p) {
        if (p.qId !== this.props.qId || p.custId !== this.props.custId) this.getApiQQ()
     }

    getApiQQ = () => {
        let qID = this.props.qId;
        let custId = this.props.custId ? this.props.custId : "";
        let qDraftIdentity = this.props.qDraftIdentity ? this.props.qDraftIdentity : "";

        this.props.sendAPI(this.api.get, "qID\fcustId\fqDraftIdentity\r" + qID + "\f" + custId + "\f" + qDraftIdentity, (ob) => {
            let fullData = ob.data ? ob.data.split("\r").filter(f => f) : [];
            let headers = fullData[0] ? fullData[0].split("\f") : [];
            let qaDatetime = moment().format("YYYY-MM-DD HH:mm:ss")
            let qqLocals = [];
            let data = fullData.slice(1, -1).map(x => {
                let y = x.split("\f");
                let objData = {}
                headers.forEach((z, i) => { objData = { ...objData, [z]: y[i] } })
                qqLocals.push(objData.qqID);
                let local = qqLocals.filter(f => f == objData.qqID).length;
                if (objData.qqAnswerType == 'E') objData.qaValue = moment().format("YYYY-MM-DD")

                return { ...objData, local, qaDatetime }
            }).filter(f => f && f.qqIsActive == '1')

            this.props.setJson(this.id, { data, qID });
            this.innerHTMLList = data.filter(f => f && f.qqHTML);
            this.innerHTMLTitle()

        }, (e) => { console.error(e) })
    }

    getField = (field) => {
        let qqText = (<div id={"qq_field_" + field.qqID} style={{ display: 'inline-block', marginRight: 5 }}>{field.qqText}</div>);

        const value = field.qaValue;

        const onChange = (e) => {
            let newData = this.props.data[this.id].data.map(x => {
                return x.qqID == field.qqID && x.local == field.local ? { ...x, qaValue: e, qaDatetime: moment().format("YYYY-MM-DD HH:mm:ss") } : x
            })
            this.props.setJson(this.id, { data: newData });
        }

        const qqAnswerOptions = field.qqAnswerOptions ? field.qqAnswerOptions.split(",") : []

        const fieldProps = {
            key: field.qqID,
            style: { marginBottom: 8 },
            validateStatus: (field.qqIsRequired == '1' && !value) ? "error" : "success",
            required: field.qqIsRequired == '1',
            extra: <label style={(field.qqIsRequired == '1' && !value) ? { color: '#f5222d' } : { display: 'none' }}>{this.props.getText(90006)}</label>,
        }

        const fieldInputProps = {
            ...fieldProps,
            label: qqText,
            labelCol: { span: 12 },
            wrapperCol: { span: 12 },
        }

        switch (field.qqAnswerType) {
            case 'T': return (<Form.Item {...fieldInputProps}><Input value={value} onChange={e => { onChange(e.target.value) }} readOnly={this.props.readOnly} /></Form.Item>)
            case 'F': return (<Form.Item {...fieldInputProps}><Input.TextArea value={value} onChange={e => { onChange(e.target.value) }} readOnly={this.props.readOnly} /></Form.Item>)
            case 'I': return (<Form.Item {...fieldInputProps}><InputNumber precision={0} value={value} onChange={onChange} readOnly={this.props.readOnly} /></Form.Item>)
            case 'M': return (<Form.Item {...fieldInputProps}><InputNumber precision={2} value={value} onChange={onChange} readOnly={this.props.readOnly} /></Form.Item>)
            case 'D':
            case 'E': return (<Form.Item {...fieldInputProps}><DateTimePicker format="DD/MM/YYYY" value={value} onChange={onChange} disabled={this.props.readOnly} /></Form.Item>)
            case 'H': return (<Form.Item {...fieldInputProps}><DateTimePicker format="HH:mm:ss" value={value} onChange={onChange} disabled={this.props.readOnly} /></Form.Item>)
            case 'B': return (<Form.Item {...fieldProps}><Checkbox checked={value == '1'} onChange={e => { onChange(e.target.checked ? '1' : '0') }} disabled={this.props.readOnly} >{qqText}</Checkbox></Form.Item>)
            case 'L': return (<Form.Item {...fieldInputProps}><LockPicker value={value} onChange={onChange} disabled={this.props.readOnly} /></Form.Item>)
            case 'S': return (<Form.Item {...fieldInputProps}>
                <SignatureInput
                    {...this.props}
                    user={{
                        token: this.props.token,
                        companyCode: this.props.companyCode,
                        entpoint: "JMForms",
                    }}
                    key={field.qqID}
                    idKey={field.qqID}
                    value={value}
                    onChange={onChange}
                    disabled={this.props.readOnly}
                />
            </Form.Item>)
            case 'R': return (<Form.Item {...fieldInputProps}>
                <Radio.Group value={value} onChange={e => { onChange(e.target.value) }} disabled={this.props.readOnly} >
                    {qqAnswerOptions.map(x => (<Radio value={x}>{x}</Radio>))}
                </Radio.Group>
            </Form.Item>)
            case 'A': return (<Form.Item {...fieldInputProps}>
                <Select value={value} onChange={onChange} style={{ width: '100%' }} disabled={this.props.readOnly} >
                    {qqAnswerOptions.map(x => (<Select.Option value={x}>{x}</Select.Option>))}
                </Select>
            </Form.Item>)
            case 'Z': return (<Form.Item {...fieldInputProps}>
                <Select value={value ? value.split(",") : []} onChange={e => { onChange(e ? e.join(",") : '') }} style={{ width: '100%' }} mode="multiple" maxTagCount={4} disabled={this.props.readOnly} >
                    {qqAnswerOptions.map(x => (<Select.Option value={x}>{x}</Select.Option>))}
                </Select>
            </Form.Item>)
            case 'N': return qqText;
            case 'P':
                return (<div style={{ height: window.innerWidth > 1200 ? window.innerHeight - 150 : window.innerHeight - 20 }}>
                    <iframe style={{ position: "relative", height: "100%", width: "100%" }}
                        src={this.props.docLink ? this.props.docLink : "about:blank"}></iframe>
                </div>);
            default: return qqText;
        }
    }

    innerHTMLTitle = () => {
        this.innerHTMLList.forEach((x, i) => {
            let e = document.getElementById("qq_field_" + x.qqID)
            if (e) {
                e.innerHTML = x.qqHTML;
                this.innerHTMLList[i] = null;
            }
        });

        this.innerHTMLList = this.innerHTMLList.filter(f => f);

        if (this.innerHTMLList.length) setTimeout(this.innerHTMLTitle, 1000);
    }

    addGroup = ({ id, last }) => {
        let oldData = this.props.data[this.id].data;
        let lastLocal = oldData[last].local;
        let toAdd = oldData.filter(f => f.qqGroup == id && f.local == 1).map(x => { return { ...x, local: lastLocal + 1, qaValue: '' } });
        let data = [
            ...oldData.slice(0, last + 1),
            ...toAdd,
            ...oldData.slice(last + 1),
        ];
        this.props.setJson(this.id, { data })
    }

    deleteGroup = ({ id, last }) => {
        let oldData = this.props.data[this.id].data;
        let lastLocal = oldData[last].local;
        let data = oldData.filter(f => f.qqGroup == id && f.local == lastLocal ? false : true);
        this.props.setJson(this.id, { data })
    }

    render() {
        console.log("titleExtra", this.props.isNullComponent);
        if (this.props.isNullComponent) return "";

        let groups = [];
        let data = this.props.data[this.id].data.map((x, index) => {
            if (x.qqGroup) {
                let findGroup = groups.findIndex(f => f.id == x.qqGroup);
                if (findGroup > -1) groups[findGroup] = { ...groups[findGroup], last: index }
                else groups.push({ id: x.qqGroup, last: index })
            }
            return { ...x, index }
        });



        return data.map(x => {
            let lastInGroup = groups.find(f => f.id == x.qqGroup && f.last == x.index);
            let plusButton = lastInGroup ? (<Button onClick={() => { this.addGroup(lastInGroup) }}><Icon type="plus" /></Button>) : null
            let deleteButton = lastInGroup && x.local > 1 ? (<Button onClick={() => { this.deleteGroup(lastInGroup) }}><Icon type="delete" /></Button>) : null

            return [
                this.getField(x),
                plusButton || deleteButton ? (<Form.Item>{plusButton}{deleteButton}</Form.Item>) : ''
            ]
        })
    }
}

export default QuestionnaireForm;