
import React from "react";
import {
    Input, Row, Col, Icon, Button, Popconfirm, Tooltip, Modal, Table, Select,
    Checkbox, message, InputNumber
} from 'antd';
import PageToolbar from "./PageToolbar";
import TableIDs from '../data/TableIDs.js';
import ResponsiveTable from "./ResponsiveTable";
import GenericModal from "./GenericModal";
import LangContext from "../contextProvider/LangContext";
import ModalPricesHistory from "./ModalPricesHistory";
import GenericSelector from "./GenericSelector";
import GenericSelectorSuppliers from "./GenericSelectorSuppliers";
import ModalBonusCulc from "./ModalBonusCalc";

const { Option, OptGroup } = Select;


class ModalCreateEditItemSuppliers extends React.Component/*<Props, State>*/ {
    constructor(props/*: Props*/) {
        super(props);
        this.state = {
            modalBonusCulc: null,
            modalPricesHistory: false,
        }

        this.id = 'ITEM_SUPPLIERS_CREATE_EDIT';

        this.api = {
            get: "get_suppliers_by_item",
            selector: "get_suppliers",
        }

        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericData }
        if (props.data[TableIDs.genericSelectorSuppliers] === undefined) props.data[TableIDs.genericSelectorSuppliers] = { ...props.data.genericSelector }
        if (props.data["CURRENCY_SELECTOR"] === undefined) props.data["CURRENCY_SELECTOR"] = { ...props.data.genericSelector }

        this.picksSuppliers = [];

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    refreshData = () => {
        let mItemCode = this.props.ui.data ? this.props.ui.data.itemId : '';
        this.props.dataActions.genericDataSetFilter(this.id, this.props.user.companyCode, this.props.user.token, { mItemCode }, this.api.get);
        this.props.dataActions.genericSelectorRefreshDataset(TableIDs.genericSelectorSuppliers, this.props.user.companyCode, this.props.user.token, this.api.selector);
    }

    generatePriceInTable = (text, record) => {
        let source = this.props.data[this.id];
        let pFormat = (p) => p ? parseFloat(p).toFixed(2) : '0.00';
        if (source.editing == record.key) {
            return (<span>
                <InputNumber
                    min={0} step={1} precision={2}
                    style={{ width: '78%', textAlign: 'center' }}
                    defaultValue={text}
                    value={source.editedRecord['mItemCostNoVat']}
                    disabled={source.editedRecord['mCostIncludeBonus']}
                    onChange={(value) => {
                        this.props.dataActions.genericDataSetEdit(this.id, {
                            mItemCostNoVat: value ? value : '0.00',
                            // mBaseCost: '',
                            // mSupDscnt1: '',
                            // mSupDscnt2: '',
                            // mSupDscnt3: '',
                            // mCostIncludeDscnt: '',
                            // mBonusQty: '',
                            // mQtyForBonusQty: '',
                            // mCostIncludeBonus: ''
                        });
                    }} />
                <ModalBonusCulc
                    modalData={source.editedRecord}
                    style={{ width: '20%' }}
                    handleOk={(newRecord) => { this.props.dataActions.genericDataSetEdit(this.id, newRecord) }} />
                {/* <Icon
                    type="setting"
                    onClick={() => { this.setState({ modalBonusCulc: source.editedRecord }) }}
                    style={{ color: source.editedRecord['mCostIncludeBonus'] ? '#40a9ff' : 'black', width: '20%' }}
                /> */}
            </span>);
        } else {
            return (!text || text === '0.00') ? (<div style={{ backgroundColor: 'pink' }}>{'0.00'}</div>) : pFormat(text);
        }
    }

    handleSuppliersCreate = () => {
        this.props.dataActions.genericDataStartInlineCreate(this.id, { mIsDefault: "1", mCurrencyCode: this.props.defaultCurrencyCode });
    }

    // getModalBonusCulcField = (label, field) => {
    //     let editedRecord = this.state.modalBonusCulc ? this.state.modalBonusCulc : {}
    //     let value = Math.round(editedRecord[field] * 100) / 100;
    //     let isPercent = field === 'mSupDscnt1' || field === 'mSupDscnt2' || field === 'mSupDscnt3';
    //     let isSum = field === 'mCostIncludeDscnt' || field === 'mCostIncludeBonus';

    //     return (<Row>
    //         <Col span={16} style={{ textAlign: 'left', padding: 5 }}>
    //             {isSum ? (<b>{this.getText(label)}</b>) : this.getText(label)}:
    //         </Col>
    //         <Col span={8} style={{ textAlign: 'right', padding: 5 }}>
    //             {isSum ? <b>{value}</b> : (
    //                 <InputNumber
    //                     min={0} step={1} precision={2}
    //                     max={isPercent ? 100 : undefined}
    //                     value={value}
    //                     onChange={e => {
    //                         let newRecord = {}
    //                         Object.keys(editedRecord).forEach(x => { newRecord[x] = parseFloat(editedRecord[x]) > 0 ? parseFloat(editedRecord[x]) : 0 });
    //                         newRecord[field] = e;
    //                         newRecord['mCostIncludeDscnt'] = newRecord['mBaseCost'];
    //                         newRecord['mCostIncludeDscnt'] = newRecord['mCostIncludeDscnt'] * (1 - (newRecord['mSupDscnt1'] / 100));
    //                         newRecord['mCostIncludeDscnt'] = newRecord['mCostIncludeDscnt'] * (1 - (newRecord['mSupDscnt2'] / 100));
    //                         newRecord['mCostIncludeDscnt'] = newRecord['mCostIncludeDscnt'] * (1 - (newRecord['mSupDscnt3'] / 100));
    //                         newRecord['mCostIncludeBonus'] = newRecord['mCostIncludeDscnt'];
    //                         if (newRecord['mBonusQty'] > 0 && newRecord['mQtyForBonusQty'] > 0) {
    //                             let culcBonusItems = newRecord['mQtyForBonusQty'] / (newRecord['mBonusQty'] + newRecord['mQtyForBonusQty'])
    //                             newRecord['mCostIncludeBonus'] = newRecord['mCostIncludeBonus'] * culcBonusItems;
    //                         }
    //                         newRecord['mCostIncludeBonus'] = Math.round(newRecord['mCostIncludeBonus'] * 100) / 100
    //                         newRecord['mItemCostNoVat'] = newRecord['mCostIncludeBonus'];
    //                         this.setState({ modalBonusCulc: newRecord })

    //                     }} />
    //             )}
    //         </Col>
    //     </Row>)
    // }

    tooltipButton = (title, type, onClick, disabled) => {
        return (<Tooltip title={this.getText(title)}>
            <Button className={"btn"} style={{ marginTop: "5px", marginLeft: "5px" }} onClick={onClick} disabled={disabled}>
                <Icon type={type} style={{ fontSize: "16px" }} />
            </Button>
        </Tooltip>)
    }

    renderSuppId = (text, record) => {
        // let list = this.props.data[TableIDs.genericSelectorSuppliers].dataset
        //     .filter((obj) => !this.picksSuppliers.includes(obj['code']))
        //     .map((obj, index) => {
        //         return (<Option key={obj['code']} value={obj['code']} valuefilter={obj['code'] + obj['name']}>{obj['code'] + ' - ' + obj['name']}</Option>)
        //     });
        if (this.props.data[this.id].editing == record.key) {
            return (
                <GenericSelectorSuppliers
                    {...this.props} notApi
                    disabled={(this.props.data[this.id].editing != record.key)}
                    value={(this.props.data[this.id].editing == record.key) ?
                        this.props.data[this.id].editedRecord['mSupplierId'] : text}
                    onChange={(e) => { this.props.dataActions.genericDataSetEdit(this.id, { mSupplierId: e }) }}
                />)
        } else {
            return text + " - " + record.mSupplierName
        }
    }

    renderCurrencyCode = (text, record) => {
        return (
            <GenericSelector
                {...this.props}
                id={"CURRENCY_SELECTOR"}
                disabled={(this.props.data[this.id].editing != record.key)}
                value={(this.props.data[this.id].editing == record.key) ?
                    this.props.data[this.id].editedRecord['mCurrencyCode'] : text}
                onChange={(e) => { this.props.dataActions.genericDataSetEdit(this.id, { mCurrencyCode: e }) }}
                modalStyle={{
                    title: this.getText(17886),
                    col1Title: this.getText(17887),
                    col2Title: this.getText(17888),
                    cancelText: this.getText(17889),
                    clearText: this.getText(17890),
                }}
            />)
    }

    renderSuppItemCode = (text, record) => {
        if (this.props.data[this.id].editing == record.key) {
            return (
                <Input.TextArea
                    style={{ width: '98%', textAlign: 'center' }}
                    defaultValue={text}
                    onChange={(value) => {
                        this.props.dataActions.genericDataSetEdit(this.id, { mSupplierItemCode: value.target.value });
                    }} />);
        } else {
            return text;
        }
    }

    renderSuppBarCode = (text, record) => {
        if (this.props.data[this.id].editing == record.key) {
            return (
                <Input.TextArea
                    style={{ width: '98%', textAlign: 'center' }}
                    defaultValue={text}
                    onChange={(value) => {
                        this.props.dataActions.genericDataSetEdit(this.id, { mSupplierBarCode: value.target.value });
                    }} />);
        } else {
            return text;
        }
    }

    renderIsDefault = (text, record) => {
        let checkedVal = (this.props.data[this.id].editing == record.key) ? this.props.data[this.id].editedRecord['mIsDefault'] : text;

        return (<Checkbox
            onChange={(e) => {
                this.props.dataActions.genericDataSetEdit(this.id, { mIsDefault: (e.target.checked) ? "1" : "0" })
            }}
            checked={checkedVal == '1'}
            disabled={(this.props.data[this.id].editing != record.key) || record['mIsDefault'] === '1'}
        />)
    }

    renderEditCol = (text, record) => {
        const { editing, editedRecord, headers, data } = this.props.data[this.id];
        let isEdit = editedRecord['mSupplierId'];
        let mSupplierItemCode = editedRecord['mSupplierItemCode'];
        let isDefault = editedRecord['mIsDefault'] == '1';

        if (editing != record.key) {
            return (
                <span>
                    <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"edit"} onClick={() => {
                        this.props.dataActions.genericDataStartEditing(this.id, record.key, record.index, false);
                    }} />
                    <Popconfirm title={this.getText(10317)}
                        onConfirm={() => {
                            this.props.dataActions.dataTableDeleteRow(this.id, record.index);
                        }}
                        okText={this.getText(10318)} cancelText={this.getText(10319)}>
                        <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"delete"} />
                    </Popconfirm>
                </span >);
        } else {
            let isCreate = (editing == 0) ? true : false;
            return (<span>
                <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"check"}
                    onClick={() => {
                        let duplicateSupplier = false;
                        let defLine = null;

                        if (!isEdit) {
                            message.error(this.getText(18685));
                            return;
                        }

                        if (mSupplierItemCode && mSupplierItemCode.length > 50) {
                            message.error(this.getText(18684));
                            return;
                        }

                        data.forEach((x, i) => {
                            let y = x.split('\f');
                            let obj = { key: y[1], index: i }
                            headers.forEach((z, ii) => {
                                obj = { ...obj, [z]: y[ii] }
                            })
                            if (record.index !== i && y[1] === isEdit) duplicateSupplier = true;
                            if (record.index !== i && isDefault && y[4] == '1') {
                                defLine = { ...obj, mIsDefault: '0' };
                            }
                            return y.join('\f');
                        });

                        if (duplicateSupplier) {
                            message.error(this.getText(10288));
                        } else {
                            this.props.dataActions.dataTableSetRow(this.id, record.index, editedRecord, true);
                            if (defLine) this.props.dataActions.dataTableSetRow(this.id, defLine.index, defLine, true);
                            if (this.props.ui.modalStack.indexOf(this.id + "Editor") > 0) this.props.uiActions.hideModal(this.id + "Editor");
                        }
                    }} />
                <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={"close"}
                    onClick={() => {
                        if (isCreate)
                            this.props.dataActions.genericDataCancelInlineCreate(this.id, true);
                        else
                            this.props.dataActions.genericDataCancelEditing(this.id);
                    }} />
            </span>)
        }
    }

    render() {
        const gt = this.getText;
        let sorter = false;

        const columnsSuppliers = [
            { title: gt(10283), dataIndex: "mSupplierId", key: "mSupplierId", width: '25%', sorter, render: this.renderSuppId },
            { title: gt(10284), dataIndex: "mItemCostNoVat", key: "mItemCostNoVat", width: '15%', sorter, render: this.generatePriceInTable },
            { title: gt(17423), dataIndex: "mCurrencyCode", key: "mCurrencyCode", width: '15%', sorter, render: this.renderCurrencyCode },
            { title: gt(10285), dataIndex: "mSupplierItemCode", key: "mSupplierItemCode", width: '15%', sorter, render: this.renderSuppItemCode },
            { title: gt(19870), dataIndex: "mSupplierBarCode", key: "mSupplierBarCode", width: '15%', sorter, render: this.renderSuppBarCode },
            { title: gt(10286), dataIndex: "mIsDefault", key: "mIsDefault", width: '7%', sorter, render: this.renderIsDefault },
            { title: gt(10287), width: '8%', render: this.renderEditCol }
        ];

        const dataSourceSuppliers = (this.props.data[this.id]) ?
            this.props.data[this.id].data.map((x, index) => {
                let y = x.split("\f");

                return ({
                    key: (y[1] === '') ? 0 : y[1],
                    mItemCode: y[0],
                    mSupplierId: y[1],
                    mItemCostNoVat: y[2],
                    mSupplierItemCode: y[3],
                    mIsDefault: y[4],
                    mCurrencyCode: y[13],
                    mSupplierName: y[14],
                    mSupplierBarCode: y[15],
                    index: index
                })
            })
            : [];

        let genericActionButtons = [
            this.tooltipButton(10281, 'plus', this.handleSuppliersCreate, !this.props.isValidItemCode || this.props.ui.workInProgres || this.props.data[this.id].editing != -1),
            this.tooltipButton(14287, 'table', () => { this.setState({ modalPricesHistory: true }) }, !this.props.ui.data || !this.props.ui.data.itemId),
        ]

        let cols = (window.innerWidth > 600) ? columnsSuppliers :
            columnsSuppliers.map((x) => { return { ...x, title: (<span style={{ fontSize: 11 }}>{x.title}</span>) } });

        return (<span>
            <div style={{ padding: '4px' }}>
                <PageToolbar title={this.getText(10279)}
                    actionButtons={[]} genericActionButtons={genericActionButtons}
                    ui={this.props.ui} uiActions={this.props.uiActions} data={this.props.data} dataActions={this.props.dataActions} />
            </div>
            <ResponsiveTable
                idTable={this.id}
                tableOnly={true}
                percentageToMobile={[80, 20]}
                dataSource={dataSourceSuppliers}
                columns={cols}
                scroll={{ y: 160 }}
                bordered
                pagination={false}
                ui={this.props.ui}
                uiActions={this.props.uiActions} />
            {/* <ModalBonusCulc
                modalData={this.state.modalBonusCulc}
                visible={this.state.modalBonusCulc}
                onCancel={() => { this.setState({ modalBonusCulc: null }) }}
                handleOk={(newRecord) => {
                    this.props.dataActions.genericDataSetEdit(this.id, newRecord);
                    setTimeout(() => {
                        this.setState({ modalBonusCulc: null })
                    }, 500);
                }} /> */}
            {/* <GenericModal
                visible={this.state.modalBonusCulc !== null}
                onCancel={() => { this.setState({ modalBonusCulc: null }) }}
                width={400}
                title={this.getText(16433)}
                footer={[
                    <Button onClick={() => {
                        this.setState({ modalBonusCulc: null })
                    }}>{this.getText(16444)}</Button>,
                    <Button type={'primary'} onClick={() => {
                        let newRecord = this.state.modalBonusCulc;
                        this.setState({ modalBonusCulc: null }, () => {
                            this.props.dataActions.genericDataSetEdit(this.id, newRecord)
                        })
                    }}>{this.getText(16445)}</Button>,
                ]}>
                {this.getModalBonusCulcField(16434, 'mBaseCost')}
                {this.getModalBonusCulcField(16435, 'mSupDscnt1')}
                {this.getModalBonusCulcField(16436, 'mSupDscnt2')}
                {this.getModalBonusCulcField(16437, 'mSupDscnt3')}
                {this.getModalBonusCulcField(16438, 'mCostIncludeDscnt')}
                {this.getModalBonusCulcField(16439, 'mBonusQty')}
                {this.getModalBonusCulcField(16440, 'mQtyForBonusQty')}
                {this.getModalBonusCulcField(16441, 'mCostIncludeBonus')}
            </GenericModal> */}
            <ModalPricesHistory
                visible={this.state.modalPricesHistory}
                close={() => { this.setState({ modalPricesHistory: false }) }}
                ui={this.props.ui} uiActions={this.props.uiActions}
                data={this.props.data} dataActions={this.props.dataActions}
                user={this.props.user}
            />
        </span>);

    }

}
export default ModalCreateEditItemSuppliers;
