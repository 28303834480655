import React, { Component } from "react";
import "../App.css";
import { Tooltip, Icon, Button, Modal, Popover } from 'antd';
import LangContext from "../contextProvider/LangContext";

import { Job, User, OutputType, ProcessType } from '../JmReact';
import TableIDs from "../data/TableIDs";



class ReportShowButton extends Component {
    constructor(props) {
        super(props)
        this.state = {
            type: "pdf",
            isReportModalShown: false,
            url: null,
            pageNumber: 1,
            numberOfPages: 1,
            loading: false,
        }
        this.id = this.getRandomInt();
    }


    static contextType = LangContext;

    getRandomInt = () => {
        const max = 1000000;
        return Math.floor(Math.random() * max);
    }

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    checkBeforeShowReport = () => {

        if (this.props.confirmBeforePrint) {
            Modal.confirm({
                ...this.props.confirmBeforePrint,
                onOk: () => { this.showReport() },
                onCancel() { },
            })
        } else {
            this.showReport()
        }
    }

    showReport = () => {
        setTimeout(() => {
            let isOpenModalItems = this.props.ui.modalStack.includes(TableIDs.SelectorItemsModal)
            const { params, validSubmit } = this.props
            // let validSubmit = this.props.validSubmit  ? this.props.validSubmit() : true
            console.log("ReportShowButton run...", params, validSubmit)

            if (params !== null && params.reportName && validSubmit !== false && !isOpenModalItems) {
                const succCallback = (response: ReportBlob) => {
                    const data = response.blob;
                    let blob;

                    if (this.state.type === "pdf") {
                        blob = data.slice(0, data.size, "application/pdf");
                    } else if (this.state.type === "xls") {
                        blob = data.slice(0, data.size, "application/vnd.ms-excel");
                    } else if (this.state.type === "docx") {
                        blob = data.slice(0, data.size, "application/vnd.openxmlformats-officedocument.wordprocessingml.document");
                    } else {
                        blob = data;
                    }
                    const url = window.URL.createObjectURL(blob);
                    this.setState({ url, isReportModalShown: true, loading: false })
                }

                const errCallback = () => {
                    const { url } = this.state;
                    if (url) { window.URL.revokeObjectURL(url); }
                    this.setState({ isReportModalShown: false, url: null, loading: false })
                }
                if (!this.state.loading) {
                    this.setState({ loading: true }, () => {
                        let user = new User(this.props.user.token, this.props.user.companyCode);
                        let request = new Job(user, "generate_report", OutputType.OUTPUT_TYPE_REPORT_A4, ProcessType.PROCESS_TYPE_SYNC);
                        request.setInput(params);
                        request.send("/cgi-bin/CashOnTab", succCallback, errCallback);
                    })
                }
            }

        }, 500);
    }

    get_canvas_id = () => {
        return `canvas_${this.id}`;
    }

    get_container_id = () => {
        return `container_${this.id}`;
    }





    render() {


        return (<span>
            {this.props.iconButton ?
                <Tooltip title={this.props.tooltip ?? this.getText(19768)}>
                    {this.props.confirmBeforePrint ?
                        <Popover
                            content={[this.props.confirmBeforePrint.content, <Button type="primary" onClick={this.showReport}>{this.props.confirmBeforePrint.okText}</Button>]}
                            title={this.props.confirmBeforePrint.title}
                            trigger="click">
                            <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={this.props.type ?? "file-pdf"} />
                        </Popover>
                        : <Icon style={{ fontSize: 16 }} className={"clickable margin-4"} type={this.props.type ?? "file-pdf"} onClick={this.showReport} />
                    }
                </Tooltip>
                : this.props.confirmBeforePrint ?
                    <Popover
                        content={[this.props.confirmBeforePrint.content,<Button type="primary" onClick={this.showReport}>{this.props.confirmBeforePrint.okText}</Button>]}
                        title={this.props.confirmBeforePrint.title} trigger="click">
                        <Button
                            type={"primary"}
                            disabled={this.props.disabled}
                            loading={this.state.loading}
                            style={{ marginRight: 4 }}
                        >{this.props.textBtn ?? this.getText(18904)}</Button>
                    </Popover>
                    : <Button
                        type={"primary"}
                        disabled={this.props.disabled}
                        loading={this.state.loading}
                        onClick={this.showReport}
                        style={{ marginRight: 4 }}
                    >{this.props.textBtn ?? this.getText(18904)}</Button>}

            <Modal
                visible={this.state.isReportModalShown}
                title={false} footer={false}
                width={window.innerWidth > 1200 ? 1200 : '96%'}
                //height={window.innerHeight > 500 ? window.innerHeight - 50 : 800 /*'96%'*/}
                onCancel={() => {
                    const { url } = this.state;
                    if (url) { window.URL.revokeObjectURL(url); }
                    this.setState({ isReportModalShown: false, url: null })
                }} >
                {/* {nav()}<div id={this.get_container_id()}><canvas id={this.get_canvas_id()} /></div>{nav()} */}
                <div style={{ height: window.innerWidth > 1200 ? window.innerHeight - 150 : window.innerHeight - 20 }}>
                    <iframe style={{ position: "relative", height: "100%", width: "100%" }}
                        src={(this.state.url) ? this.state.url : "about:blank"}></iframe>
                </div>
            </Modal>

        </span>)
    }
}

export default ReportShowButton;