import React, { Component } from "react";
import "../App.css";
import DateTimePicker from "./DateTimePicker";
import { Col, Form } from "antd";
import { Row } from "antd/es/grid";


class GenericFromToDatePicker extends Component {


    render() {
        const { format, fromDate, toDate, inline, showTime } = this.props;
        const colSpan = window.innerWidth > 600 && inline ? 12 : 24;

        return (<Row>
            <Col span={colSpan}>
                <Form.Item label={fromDate.label}>
                    <DateTimePicker format={format} value={fromDate.value} onChange={fromDate.onChange} maxDate={toDate.value} showTime={showTime} />
                </Form.Item>
            </Col>
            <Col span={colSpan}>
                <Form.Item label={toDate.label}>
                    <DateTimePicker format={format} value={toDate.value} onChange={toDate.onChange} minDate={fromDate.value} showTime={showTime} />
                </Form.Item>
            </Col>
        </Row>)
    }
}

export default GenericFromToDatePicker;