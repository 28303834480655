
import React from "react";
import { Input, InputNumber, Icon, Button, Popconfirm, Tooltip, Modal, Table, Select, Row, Col, Checkbox, message } from 'antd';

import { Job, User, OutputType, ProcessType } from '../JmReact';
import PageToolbar from "./PageToolbar";
import PictureView from "./PictureView";
import TableIDs from '../data/TableIDs.js';
import InputNumeric from "./InputNumeric";
import { fixCurrentPage } from "../utils/utils";
import ResponsiveTable from "./ResponsiveTable";
import LangContext from "../contextProvider/LangContext";

const { Option, OptGroup } = Select;
const { TextArea } = Input;

const topHeight = 340; // px
const rowHeight = 30;  // px

//type State = {}
class ModalUnitQtySelector extends React.Component/*<Props, State>*/ {
    // props: user,tableFormat,apiCalls
    constructor(props/*: Props*/) {
        super(props);
        this.state = {
            loading: false,
            visible: false,
        }

        this.id = "MODAL_UOM_QTY";
        this.state = { defaultItemCode: "", itemCodeSelectionVisible: false };

        // calc page height
        this.state.height = window.innerHeight;
        this.doOnResize = null;

        if (props.data[this.id] === undefined) {
            props.data[this.id] = { ...props.data.genericData };
        }

        this.api = {
            get: "get_UOM_ruler_by_itemCode",
        }

        this.actionButtons = [
            /*{
            buttonType: 'add',
            buttonFunction: this.handleCreateNew
        }, {
            buttonType: 'refresh',
            buttonFunction: this.refreshData
        }, {
            buttonType: 'print',
            buttonFunction: () => { alert('printing'); },
        }, {
            buttonType: 'export',
            buttonFunction: () => { alert('exporting'); },
        },*/]


    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        //document.title = "Cash On Tab - מידות";
        //this.initFilters();
    }


    refreshData = () => {
        // SizeID, SizeDesc, SizeUOMQty, ItemRuler1ID
        var dataSend = "_ItemCode\r" + ((this.props.ui.data) ? (this.props.ui.data)['_itemCodeRuler'] : '0');
        var user = new User(this.props.user.token, this.props.user.companyCode);
        var job = new Job(user, this.api.get, OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
        job.setInput(dataSend);
        job.send("/cgi-bin/CashOnTab", (ob) => {
            console.log("bbbbbzzzz  ", ob.data);
            if (ob.data) {
                let _data = (ob.data)
                    .split('\r')
                    .map((x, index) => {
                        let y = x.split("\f");
                        return ({
                            key: y[0],
                            mSizeID: y[0],
                            mSizeDesc: y[1],
                            mSizeUOMQty: y[2],
                            mRulerID: y[3],
                            mQty: 0,
                            index: index
                        })
                    });

                this.props.dataActions.setJson(this.id + '.data', { dataset: _data });
            } else {
                this.props.dataActions.setJson(this.id + '.data', { dataset: [] });
            }
            // SizeID, SizeDesc, SizeUOMQty, ItemRuler1ID

        },
            e => e); // callback problem here
    }

    // handleOkLeave = () => {
    //     //this.clear();
    //     this.props.uiActions.hideModal(TableIDs.modalAddSizesToRuler);
    // }
    handleOk = () => {
        let dataSource = this.props.data[this.id]?.data['dataset'] ?? [];
        let formTotal = dataSource.reduce((acc, val) => {
            return acc += (parseFloat(val.mQty) * parseFloat(val.mSizeUOMQty));
        }, 0.00).toFixed(2);
        this.props.onConfirm(formTotal);
        this.props.uiActions.hideModal(TableIDs.modalSelectorUOMQty, {});
        document.getElementById("quantity_input").focus();
    }
    handleCancel = () => {

        console.log("BBBB JJ")
        this.props.uiActions.hideModal(TableIDs.modalSelectorUOMQty, {});
        document.getElementById("quantity_input").focus();
    }
    handleClear = () => {
        // { key, mSizeID1, mSizeDesc, mUOMQty, mQty }
        let storeData = this.props.data[this.id].data['dataset']; //[TableIDs.selectorUOMQty];
        let updatedDataset = storeData.map(i => {
            i.mQty = 0;
            return i;
        });
        this.props.dataActions.setJson(this.id + '.data' /*TableIDs.selectorUOMQty*/, { dataset: updatedDataset })
    }

    render() {
        const { loading } = this.state;

        let titleBar = (
            <div style={{
                paddingLeft: '4px',
                paddingRight: '4px',
                paddingTop: '4px',
                paddingBottom: '0px',
            }}>
                <PageToolbar
                    title={'עריכת כמות על פי יחידות מידה'/*this.getText(10708)*/}
                    actionButtons={this.actionButtons}
                    // showsearch={(val) => {
                    //     this.props.dataActions.genericDataSetFilter(
                    //         this.id, this.props.user.companyCode, this.props.user.token,
                    //         {
                    //             ...request, _words: val
                    //                 .trim().replace(/\s\s+/g, ' ').replace(/\'/g, "\\\'")
                    //         }, this.api.get)
                    // }}
                    isModal={true}
                    ui={this.props.ui}
                    uiActions={this.props.uiActions}
                    data={this.props.data}
                    dataActions={this.props.dataActions}
                />
            </div>)

        // const dataSource = this.props.data[this.id].data.map((x, index) => {
        //     let y = x.split("\f");
        //     return ({
        //         key: y[0],
        //         mID: y[0],
        //         //mRulerID: y[1],
        //         mNumerator: y[2],
        //         mCode: y[3],
        //         mDesc: y[4],
        //         mUOMQty: y[5],
        //         index: index
        //     })
        // });

        let dataSource = this.props.data[this.id]?.data['dataset'] ?? [];
        // mSizeID: y[0],
        // mSizeDesc: y[1],
        // mSizeUOMQty: y[2],
        // mRulerID: y[3],
        // mQty: 0,
        let formTotal = dataSource.reduce((acc, val) => {
            return acc += (parseFloat(val.mQty) * parseFloat(val.mSizeUOMQty));
        }, 0.00).toFixed(2);

        let columns = [{
            title: 'קוד יח\' מידה',
            dataIndex: "mSizeID",
            key: "mSizeID",
            width: '15%',
            sorter: true,
            render: (text, record) => text
        }, {
            title: 'תיאור',
            dataIndex: "mSizeDesc",
            key: "mSizeDesc",
            width: '25%',
            sorter: true,
            render: (text, record) => text
        }, {
            title: 'יחידת מידה',
            dataIndex: "mSizeUOMQty",
            key: "mSizeUOMQty",
            width: '20%',
            sorter: true,
            render: (text, record) => text
        }, {
            title: 'כמות',
            dataIndex: "mQty",
            key: "mQty",
            width: '20%',
            sorter: true,
            render: (text, record) => {
                return (<InputNumber
                    style={{ width: '98%', textAlign: 'center' }}
                    defaultValue={text}
                    value={text}
                    onChange={(value) => {
                        console.log("input number: ", text, record);
                        // { key, mSizeID1, mSizeDesc, mUOMQty, mQty }
                        let storeData = this.props.data[this.id].data['dataset']; //[TableIDs.selectorUOMQty];
                        let updatedDataset = storeData.map(i => {
                            if (record['mSizeID'] === i.mSizeID) {
                                i.mQty = value;
                            }
                            return i;
                        });
                        this.props.dataActions.setJson(this.id + '.data' /*TableIDs.selectorUOMQty*/, { dataset: updatedDataset })
                    }} />)
            }
        },
        {
            title: 'סה"כ',
            dataIndex: "mTotalQty",
            key: "mTotalQty",
            width: '20%',
            sorter: true,
            render: (text, record) => { return record['mQty'] * record['mSizeUOMQty'] }
        }]

        return (
            <Modal
                //style={modalStyle}
                destroyOnClose={true}
                width={window.innerWidth > 600 ? '70%' : '90%'}
                visible={false || this.props.toggle}
                // title={this.props.title} // pass title in props for generics
                title={titleBar}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                //footer={false}
                footer={
                    [
                        <Button key="back"
                            onClick={this.handleCancel}>ביטול</Button>,
                        <Button key="clear"
                            loading={loading} onClick={this.handleClear}>ניקוי</Button>,
                        <Button key="create"
                            type="primary" loading={loading} onClick={this.handleOk}>שמור</Button>,
                    ]}
                ui={this.props.ui} uiActions={this.props.uiActions}
                data={this.props.data} dataAction={this.props.dataAction}
            >

                <Table
                    id={'UOM_TABLE'}
                    dataSource={dataSource}
                    columns={columns}
                    loading={this.props.ui.workInProgress}
                    scroll={this.props.scroll ? this.props.scroll : {}}
                    bordered
                    //pagination={pagination}
                    pagination={false}
                    //onChange={this.props.onChange ? this.props.onChange : false}
                    //onClick={() => { document.getElementById('responsive-table-id').display = 'none' }}
                    locale={{ filterConfirm: this.getText(10245), filterReset: this.getText(10246), emptyText: this.getText(10247) }}
                //expandedRowRender={(window.innerWidth > 600 || !this.props.percentageToMobile) ? false : record => record.detail}
                />
                <div style={{ textAlign: 'left' }}>
                    {'סה\"כ פריטים:'}
                    <Input style={{ width: '20%', textAlign: 'center', marginRight: 8 }} readOnly={true} tabIndex={-1} value={formTotal} />
                </div>
            </Modal >
        )
    }

    componentDidUpdate(prevProps) {
        if (this.props.ui.data !== prevProps.ui.data ||
            (prevProps.toggle === false && this.props.toggle === true)) {
            console.log("prev", prevProps.ui.data, "next", this.props.ui.data);

            this.refreshData();
            // let request = this.initFilters();
            // request._id = (this.props.ui.data) ? this.props.ui.data : 0;

            // this.props.dataActions.genericDataGetEditWithParams(
            //     this.id,
            //     this.props.user.companyCode,
            //     this.props.user.token,
            //     request
            //     /*{_id: this.props.ui.data }*/,
            //     //this.api.get_suppliers
            //     this.api.get
            // );
        }
    }
}

export default ModalUnitQtySelector;
