/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import { Table, Card } from "antd";
import PageToolbar from "./PageToolbar";
import "../App.css";
import LangContext from "../contextProvider/LangContext";

type ResponsiveTableProps = {
    idTable: String,
    columns: Array,
    dataSource: Array,
    percentageToMobile: Array,
    title: String,
    actionButtons: Array,
    showsearch: any,
    onChange: any,
    ui: any,
    uiActions: any,
    scroll: any,
    pagination: any,
    titles: Array,
    moreColumns: Array,
    keyIndex: Number,
    onDragAndDrop: Function,
    filterDatasetText: String,
    rowsFilter: Function,
};

class ResponsiveTable extends Component<ResponsiveTableProps, State> {

    constructor(props/*: Props*/) {
        super(props);
        this.state = {}

    }

    static contextType = LangContext;

    getText = (id) => {
        if (this.props.getText) {
            return this.props.getText(id);
        } else {
            return this.context.get(id) || '[' + id + ']'
        }
    }

    componentDidMount() {
        if (this.props.onDragAndDrop) {
            this.dragOrderTable()
        }
    }

    componentDidUpdate(prev) {
        let prevData = prev.dataSource ? prev.dataSource : prev.idTable ? prev.idTable.data : null;
        let newData = this.props.dataSource ? this.props.dataSource : this.props.idTable ? this.props.idTable.data : null;

        if (prevData !== newData && this.props.onDragAndDrop) {
            console.log("dragOrderTable run")
            this.dragOrderTable()
        }
    }

    colToMobile = () => {
        let res = [];
        let columns = this.dynamicColumns()
        this.props.percentageToMobile.map((x, i) => {
            if (x) {
                let y = columns[i];
               if(y) res.push({ ...y, title: (<span style={{ fontSize: 11 }}>{y.title??""}</span>), width: x + '%', fixed: false })
            }
        });
        return res;
    }

    dynamicColumns = () => {
        let res = this.props.columns;
        // let data = this.props.idTable;
        // let titles = this.props.titles;
        if (!res) res = [];
        // if (titles && data && data.headers) {
        //     data.headers.forEach((x, i) => {
        //         if (titles[i]) res.push({ title: this.getText(titles[i]), key: x, dataIndex: x, render: (t) => t })
        //     });
        //     if (this.props.moreColumns) res = [...res, ...this.props.moreColumns];
        // }
        if (this.props.sorters) res = res.map(x => { return x.dataIndex ? { ...x, sorter: true } : x });
        // if (this.props.percentage) res = res.map((x, i) => { return this.props.percentage[i] ? { ...x, width: this.props.percentage[i] + '%' } : x });

        return res;

    }

    getDetail = (record) => {
        let res = record.detail;
        if (this.props.moreDetail) {
            res = (<span>{res}{this.props.moreDetail}</span>)
        }
        return res;
    }

    createTable = () => {
        let pagin = this.props.pagination === false ?
            false :
            this.props.pagination !== undefined ?
                this.props.pagination :
                this.props.idTable === undefined ?
                    {} :
                    this.props.idTable.pagination;
        let showTotal = (total, range) => '  ' + this.getText(10372) + ' ' + range[0] + ' - ' + range[1] + ' ' + this.getText(10373) + ' ' + total;

        return (<Table
            id={this.props.id ? this.props.id : 'ResponsiveTable'}
            dataSource={this.dataObject()}
            columns={(window.innerWidth > 600 || !this.props.percentageToMobile) ? this.dynamicColumns() : this.colToMobile()}
            loading={this.props.loadingPresonal !== undefined || !this.props.ui ? this.props.loadingPresonal : this.props.ui.workInProgress}
            scroll={this.props.scroll ? this.props.scroll : {}}
            bordered
            pagination={pagin ? { ...pagin, showTotal: showTotal } : false}
            onChange={this.props.onChange ? this.props.onChange : this.props.handleTableChange ? this.handleTableChange : false}
            onRowClick={this.props.onRowClick ? this.props.onRowClick : false}
            rowSelection={this.props.rowSelection ? this.props.rowSelection : false}
            rowClassName={this.props.rowClassName ? this.props.rowClassName : undefined}
            //onClick={() => { document.getElementById('responsive-table-id').display = 'none' }}
            locale={this.props.locale ? this.props.locale : { filterConfirm: this.getText(10245), filterReset: this.getText(10246), emptyText: this.getText(10247) }}
            expandedRowRender={(window.innerWidth > 600 || !this.props.percentageToMobile) ? false : this.getDetail}
            onExpand={this.props.onExpand}
        />)
    }

    createCardStyle = () => {
        let titleCard = (this.props.genericActionButtons) ?
            (<span><span>{this.props.title}</span><span style={{ float: 'left' }}>{this.props.genericActionButtons}</span></span>)
            : this.props.title

        return (<Card
            bordered={false}
            title={titleCard}
            bodyStyle={{ padding: (window.innerWidth > 600) ? 24 : 5, /*direction: "ltr"*/ }}
        >{this.createTable()}</Card>)
    }

    createToolbarStyle = () => {
        return (<div><PageToolbar
            title={this.props.title}
            actionButtons={this.props.actionButtons}
            genericActionButtons={this.props.genericActionButtons}
            showsearch={this.props.showsearch ? this.props.showsearch : false}
            showsearchWidth={this.props.showsearchWidth}
            showsearchSuffix={this.props.showsearchSuffix}
            valueSearch={this.props.valueSearch}
            onChangeSearch={this.props.onChangeSearch}
            ui={this.props.ui}
            uiActions={this.props.uiActions} />{this.createTable()}</div>)
    }

    dataObject = () => {
        let data = this.props.idTable;
        let dataToParse = data && data.data && data.data[0] ? data.data : []
        // if (this.props.filterDatasetText) dataToParse = dataToParse.filter(f => f.indexOf(this.props.filterDatasetText) > -1)
        let dataSource = this.props.dataSource ? this.props.dataSource :
            dataToParse.map((x, indexData) => {
                if (this.props.filterDatasetText && x.indexOf(this.props.filterDatasetText) === -1) return null;

                let y = x.split("\f");
                let ob = { key: y[this.props.keyIndex ? this.props.keyIndex : 0], index: indexData }
                if (data && data.headers) data.headers.forEach((z, index) => { ob = { ...ob, [z]: y[index] } });

                return ob;
            }).filter(f => f);

        let columns = this.dynamicColumns();

        if (window.innerWidth <= 600) {
            dataSource = dataSource.map((x) => {
                let detail = [];
                columns.map((c) => {
                    if (!c.dataIndex) {
                        detail.push({ key: c.title, value: c.render ? c.render(0, x) : '' })
                    } else {
                        detail.push({ key: c.title, value: c.render ? c.render(x[c.dataIndex], x) : '' })
                    }
                });

                let res = (<div style={{ textAlign: 'right', margin: 3 }}>
                    {detail.map((x) => (
                        <div>
                            <h3>{x.key}: </h3>
                            <div>{x.value}</div>
                        </div>))}
                </div>);
                x = { ...x, detail: res }
                return x;
            });
        }

        if (this.props.rowsFilter) dataSource = dataSource.filter(this.props.rowsFilter)

        return dataSource;
    }

    handleTableChange = (pagination: Object, filters: Object, sorter: Object) => {
        let colKeys = this.dynamicColumns().map(x => x.key).filter(f => f);
        let col = colKeys.indexOf(sorter.field) + 1;

        let request = {
            ...this.props.idTable.filters,
            // just of ui-pagination update
            page: pagination.current,
            recordsPerPage: pagination.pageSize,
            sortType: sorter.order === "ascend" ? 1 : 0,
            sortColumn: col,

            _page_num: pagination.current,
            _rows_num: pagination.pageSize,
            _isAsc: sorter.order === undefined || sorter.order === "ascend" ? 1 : 0,
            _sort_by: 'col_' + col,
        };

        this.props.handleTableChange(request);
    }

    dragOrderTable = () => {
        let id = this.props.id ? this.props.id : 'ResponsiveTable';
        let dataSource = this.dataObject()
        let tableElement = document.getElementById(id);
        let lineElement = tableElement ? tableElement.getElementsByClassName('ant-table-row-level-0') : [];

        if (tableElement && lineElement[0]) {
            // tableElement.getElementsByClassName('ant-table-thead')[0].setAttribute("style", "display:none;")
            dataSource.forEach((record, index) => {
                let line = lineElement[index];
                if (line) {
                    line.setAttribute("style", "cursor: all-scroll;height:40px;");
                    line.draggable = true;
                    line.ondragstart = (e) => {
                        e.dataTransfer.setData("keyStart", e.target.dataset.rowKey);
                    }
                    line.ondragover = (e) => { e.preventDefault() }
                    line.ondrop = (e) => { this.props.onDragAndDrop(e.dataTransfer.getData("keyStart"), record.key) }
                }
            });
        }
    }

    render() {
        return this.props.cardStyle ? this.createCardStyle() :
            this.props.tableOnly ? this.createTable() :
                this.createToolbarStyle();
    }
}

export default ResponsiveTable;