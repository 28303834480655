import React, { Component } from "react";
import StockItemCard from "../pages/reports/StockItemCardReport";
import LangContext from "../contextProvider/LangContext";


class StockItemCardView extends Component {
    constructor(props) {
        super(props)

        this.stock_id = "STOCK_ITEM_CARD";
        if (props.data[this.stock_id] === undefined) props.data[this.stock_id] = { ...props.data.genericData };
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    getDataStockItemCard = (params) => {
        this.props.dataActions.genericDataSetFilter(this.stock_id, this.props.user.companyCode, this.props.user.token, params, "get_stock_item_data")
    }


    render() {


        let _subTotal = 0;

        let dataSourceTableStockCard = [];

        const { mMovinType } = this.props.data[this.stock_id].filters;

        this.props.data[this.stock_id].data.forEach((x, index) => {
            let y = x.split("\f");
            if (index == 0) {
                _subTotal = parseFloat(y[0]);
                dataSourceTableStockCard.push({ _outQty: this.getText(17302), _subTotal })
            }

            let _qty = parseFloat(y[9]);
            let _isInDoc = y[17] == '1';
            if (mMovinType == 1 && !_isInDoc) return;
            if (mMovinType == 2 && _isInDoc) return;
            _subTotal += _qty;
            let _inQty = _isInDoc ? _qty : null;
            let _outQty = !_isInDoc ? _qty * -1 : null;

            dataSourceTableStockCard.push({
                index,
                key: index + 1,
                _time: y[3],
                _type: y[5],
                _number: y[6],
                _sumNoTax: y[7],
                _discount: y[8],
                _inQty,
                _outQty,
                _subTotal,
                _storage: y[18] + " - " + y[10],
                _opposite: parseInt(y[16]) ? y[16] + ' - ' + y[15] : '',
                _docCreationDate: y[20]
            })

        })

        return (<StockItemCard
            {...this.props}
            isModalFromItems
            refreshData={this.getDataStockItemCard}
            dataSourceTable={dataSourceTableStockCard}
        />)
    }
}

export default StockItemCardView;