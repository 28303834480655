import React, { Component } from "react";
import GenericModal from "./GenericModal";
import LangContext from "../contextProvider/LangContext";
import ResponsiveTable from "./ResponsiveTable";
import TableLineIcons from "./TableLineIcons"
import { Button, Checkbox, Form, Input, message } from "antd";


class ModalContacts extends Component {
    constructor(props) {
        super(props);

        this.state = {}

        this.id = "CONTACTS_TABLE";
        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericData };

        this.api = {
            get: "get_contacts",
            create_edit: "create_edit_contact",
            delete: "delete_contact",
        }
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    setFilter = (_ownerType, _owner, _isActive) => {
        this.props.dataActions.genericDataSetFilter(
            this.id, this.props.user.companyCode, this.props.user.token, { _ownerType, _owner, _isActive }, this.api.get)
    }

    refreshData = () => {
        this.props.dataActions.genericDataGetRequested(
            this.id, this.props.user.companyCode, this.props.user.token, this.api.get);
    }

    onAdd = () => {
        this.props.dataActions.genericDataStartInlineCreate(this.id)
    }

    componentDidUpdate(prev) {
        const { visible, ownerType } = this.props;
        console.log("contacts modal", { visible, ownerType })
        const prevVisible = prev.visible ?? null;
        if (prevVisible !== visible) {
            this.setFilter(ownerType, visible, "1");
        }
    }

    onChangeField = (field, value, length) => {
        if (value.length > length) {
            message.error(this.getText(19660))
        } else if ((field == 'cPhone1' || field == 'cPhone2') && !(new RegExp(/^[0-9]+$/).test(value))) {
            message.error(this.getText(19661))
        } else {
            this.props.dataActions.genericDataSetEdit(this.id, { [field]: value })
        }
    }

    getInput = (text, record, field, length) => {
        const { editing, editedRecord } = this.props.data[this.id];
        return record.key == editing ?
            (<Input value={editedRecord[field]} onChange={e => { this.onChangeField(field, e.target.value, length) }} />)
            : text
    }

    getTextarea = (text, record, field) => {
        const { editing, editedRecord } = this.props.data[this.id];
        return record.key == editing ?
            (<Input.TextArea value={editedRecord[field]} onChange={e => { this.onChangeField(field, e.target.value, 99999999) }} />)
            : text
    }

    getCheckBox = (text, record, field) => {
        const { editing, editedRecord } = this.props.data[this.id];
        return record.key == editing ?
            (<Checkbox checked={editedRecord[field] == '1'} onChange={e => { this.onChangeField(field, e.target.checked ? "1" : "0", 99999999) }} />)
            : (<Checkbox checked={text == '1'} disabled />)
    }

    getEditCol = (text, record) => {
        const { editing } = this.props.data[this.id];
        if (editing == record.key) {
            return (<TableLineIcons
                onCheck={() => {
                    this.props.dataActions.genericDataSaveEditWithParams(
                        this.id, this.props.user.companyCode, this.props.user.token, this.api.create_edit, this.api.get,
                        { cOwnerType: this.props.ownerType, cOwner: this.props.visible })
                }}
                onClose={() => {
                    if (editing == 0) this.props.dataActions.genericDataCancelInlineCreate(this.id)
                    else this.props.dataActions.genericDataCancelEditing(this.id)
                }}
            />)
        } else {
            return (<TableLineIcons
                onEdit={() => { this.props.dataActions.genericDataStartEditing(this.id, record.key, record.index) }}
                onDelete={() => { this.props.dataActions.genericDataDeleteRow(this.id, record.key, this.props.user.companyCode, this.props.user.token, this.api.delete, this.api.get) }}
                deleteQuetion={{ title: this.getText(19094), okText: this.getText(19095), cancelText: this.getText(19096) }}
            />)
        }
    }


    render() {
        console.log("ModalContacts props", this.props)
        const gt = this.getText;
        const { filters } = this.props.data[this.id] ?? {};
        const { ownerType, visible } = this.props;

        const actionButtons = this.props.onCheckMail ? [] : [
            { buttonType: 'refresh', buttonFunction: this.refreshData },
            { buttonType: 'add', buttonFunction: this.onAdd }
        ]

        const width = this.props.onCheckMail ? 600 : undefined

        const columns = this.props.onCheckMail ?
            [
                { title: gt(19652), key: "cName", dataIndex: "cName", width: "30%", render: t => t },
                { title: gt(19653), key: "cJob", dataIndex: "cJob", width: "25%", render: t => t },
                { title: gt(19656), key: "cEmail", dataIndex: "cEmail", width: "25%", render: t => t },
                { title: gt(19658), key: "cIsActive", dataIndex: "cIsActive", width: "10%", render: (t, r) => this.getCheckBox(t, r, "cIsActive") },
                { title: "", width: "10%", render: (t, r) => (<Button onClick={() => { this.props.onCheckMail(r.cEmail) }}>{gt(20284)}</Button>) },
            ]
            : [
                { title: gt(19652), key: "cName", dataIndex: "cName", width: "15%", render: (t, r) => this.getInput(t, r, "cName", 30) },
                { title: gt(19653), key: "cJob", dataIndex: "cJob", width: "10%", render: (t, r) => this.getInput(t, r, "cJob", 30) },
                { title: gt(19654), key: "cPhone1", dataIndex: "cPhone1", width: "10%", render: (t, r) => this.getInput(t, r, "cPhone1", 50) },
                { title: gt(19655), key: "cPhone2", dataIndex: "cPhone2", width: "10%", render: (t, r) => this.getInput(t, r, "cPhone2", 50) },
                { title: gt(19656), key: "cEmail", dataIndex: "cEmail", width: "15%", render: (t, r) => this.getInput(t, r, "cEmail", 50) },
                { title: gt(19657), key: "cRemarks", dataIndex: "cRemarks", width: "28%", render: (t, r) => this.getTextarea(t, r, "cRemarks") },
                { title: gt(19658), key: "cIsActive", dataIndex: "cIsActive", width: "6%", render: (t, r) => this.getCheckBox(t, r, "cIsActive") },
                { title: gt(19659), width: "6%", render: this.getEditCol },
            ]

        return (<GenericModal  {...this.props} actionButtons={actionButtons} width={width}>
            <Form.Item>
                <Checkbox
                    checked={filters?._isActive == "1"}
                    onChange={e => {
                        this.setFilter(ownerType, visible, e.target.checked ? "1" : "0")
                    }}
                >{gt(19651)}</Checkbox>
            </Form.Item>

            <ResponsiveTable
                tableOnly
                idTable={this.props.data[this.id]}
                columns={columns}
                pagination={{ pageSize: 14 }}
                percentageToMobile={[100]}
            />
        </GenericModal>)
    }

}

export default ModalContacts;