/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import "../../App.css";
import LangContext from "../../contextProvider/LangContext";
import moment from "moment";
import GenericTable from "../../components/GenericTable";
import GenericModal from "../../components/GenericModal";
import { Button, Col, Form, Input, InputNumber, message, Radio } from "antd";
import GenericFormItem from "../../components/GenericFormItem";
import CustPicker from "../../components/CustPicker";
import GenericFromToDatePicker from "../../components/GenericFromToDatePicker";
import { Row } from "antd/es/grid";
import GenericSelector from "../../components/GenericSelector";
import SelectorItemClassic from "../../components/SelectorItemClassic";
import GenericSelectorItemTags from "../../components/GenericSelectorItemTags";
import DateTimePicker from "../../components/DateTimePicker";
import TableLineIcons from "../../components/TableLineIcons";
import TableIDs from "../../data/TableIDs";
import { Job, OutputType, ProcessType, User } from "../../JmReact";


class ServiceCalls extends Component {
    constructor(props: Props) {
        super(props);
        this.defaultFilters = {
            _custId: null,
            _fromDateOpen: null,
            _toDateOpen: null,
            _isClosed: '-1',
            _fromDateClose: null,
            _toDateClose: null,
            _subjectList: "",
            _subjectDetailList: "",
            _itemCode: "",
            _sn: "",
            _statusList: "",
            _tagList: "",
        }

        this.state = {
            ...this.defaultFilters,
            modalFilter: false,
            modalReadOnly: false,
        };

        this.id = "SERVICE_CALLS";
        this.status_id = "SERVICE_CALL_STATUSES";

        this.api = {
            get: "get_service_calls",
            create_edit: "create_edit_service_calls",
            get_status: "get_service_call_statuses",

        }

        this.selectors = [
            { id: "SERVICE_SUBJECTS_LIST", api: "service_subjects_list" },
            { id: "SERVICE_SUBJECT_DETAILS_LIST", api: "service_subject_details_list" },
            { id: "SELECTOR_TAG_TYPE_9", api: "get_tags_from_type_9" },
            { id: "CUSTOMER_BUSINESS_BRANCHES_LIST", api: "customer_business_branches_list" },
            { id: "SERVICE_SUBJECTS_TAGS_AND_USERS", api: "get_service_subjects_tags_and_users" },
        ]

        if (props.data[this.id] === undefined) props.data[this.id] = { ...props.data.genericData };
        if (props.data[this.status_id] === undefined) props.data[this.status_id] = { ...props.data.genericData };
        if (props.data.SERVICE_SUBJECT_DETAILS_LIST2 === undefined) props.data.SERVICE_SUBJECT_DETAILS_LIST2 = { dataset: [] };
        this.selectors.forEach(x => { if (props.data[x.id] === undefined) props.data[x.id] = { ...props.data.genericSelector } });

    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    sendAPI = (script, data, sCal) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCal, (e) => { console.error(e) });
    }


    componentDidMount() {
        this.props.dataActions.genericDataGetRequested(
            this.status_id, this.props.user.companyCode, this.props.user.token, this.api.get_status);

        const s1 = this.selectors[1]
        const s4 = this.selectors[4]

        this.sendAPI(s1.api, "", (ob) => {
            let dataset = ob.data ? ob.data.split("\r").map(x => {
                let y = x.split("\f")
                return {
                    code: y[0],
                    name: y[1],
                    expectedClosing: y[2]
                }
            }) : []
            this.props.dataActions.setJson("SERVICE_SUBJECT_DETAILS_LIST2", { dataset })
        })


        this.sendAPI(s4.api, "", (ob) => {
            let dataset = ob.data ? ob.data.split("\r").map(x => {
                let y = x.split("\f")
                return {
                    subjectDetailId: y[0],
                    tagId: y[1],
                    tagName: y[2],
                    userId: y[3],
                    userName: y[4],
                }
            }) : []
            this.props.dataActions.setJson(s4.id, { dataset })
        })

    }

    componentDidUpdate() {
        document.title = "Cash On Tab - " + this.getText(20331);
    }

    onFilter = () => {
        this.setState({ modalFilter: false }, () => {
            this.props.dataActions.genericDataSetFilter(
                this.id, this.props.user.companyCode, this.props.user.token,
                { ...this.props.data[this.id].filters, ...this.state, page: 1, _page_num: 1 },
                this.api.get);
        })
    }

    handleCancel = () => {
        this.setState({ modalReadOnly: false }, () => {
            if (this.props.data[this.id].editing == 0) this.props.dataActions.genericDataCancelInlineCreate(this.id);
            else this.props.dataActions.genericDataCancelEditing(this.id);
        })
    }

    handleOk = () => {

        const { editing, editedRecord } = this.props.data[this.id]

        if (!editedRecord.cCustomerID || !editedRecord.cSubjectDetailId || !editedRecord.cDesc) {
            message.error(this.getText(20417))
        } else if (editing == 0 && (!editedRecord.cTargetUserID && !editedRecord.cTargetUserTagID)) {
            message.error(this.getText(20418))
        } else {
            this.props.dataActions.genericDataSaveEdit(
                this.id, this.props.user.companyCode, this.props.user.token, this.api.create_edit, this.api.get);
        }
    }

    filterField = (field) => {
        const gt = this.getText
        const value = this.state[field]
        const onChange = e => { this.setState({ [field]: e }) }
        switch (field) {
            case '_custId': return (<CustPicker {...this.props} {...{ value, onChange }} defaultView={gt(12121)} />)
            case '_isClosed':
                return (<Radio.Group value={value} onChange={e => { onChange(e.target.value) }}>
                    <Radio value="-1">{gt(20362)}</Radio>
                    <Radio value="0">{gt(20363)}</Radio>
                    <Radio value="1">{gt(20364)}</Radio>
                </Radio.Group>)
            case '_itemCode':
                return (<SelectorItemClassic {...this.props} valueId={this.state._itemCode} valueName={this.state._itemName}
                    onChange={(_itemCode, _itemName) => { this.setState({ _itemCode, _itemName }) }} />)
            case '_sn': return (<Input value={value} onChange={e => { onChange(e.target.value) }} />)
        }
    }

    filterFromToDate = (field1, field2) => {
        return (<GenericFromToDatePicker
            format={"DD/MM/YYYY HH:mm"} showTime inline
            fromDate={{ label: this.getText(20359), value: this.state[field1], onChange: e => { this.setState({ [field1]: e }) } }}
            toDate={{ label: this.getText(20360), value: this.state[field2], onChange: e => { this.setState({ [field2]: e }) } }}
        />)
    }

    filterSelector = (field, index, moreAttr) => {
        let props = { ...this.props }
        if (index > -1) props = { ...props, ...this.selectors[index] }
        if (moreAttr) props = { ...props, ...moreAttr }
        return ((<GenericSelector {...props} multi
            value={this.state[field] ? this.state[field].split(",") : []}
            onChange={e => { this.setState({ [field]: e?.join(",") }) }} />
        ))
    }

    apiCustBranches = (cust) =>{
        this.props.dataActions.genericSelectorRefreshDataset(
            this.selectors[3].id, this.props.user.companyCode, this.props.user.token, this.selectors[3].api, {cust});
    }

    editField = (field) => {
        const gt = this.getText
        const { editing, editedRecord } = this.props.data[this.id]
        const value = editedRecord[field]
        const onChange = e => { this.props.dataActions.genericDataSetEdit(this.id, { [field]: e }) }
        let dataset = []
        switch (field) {
            case 'cCustomerID':
                return editing > 0 ?
                    value + " - " + editedRecord.cCustName
                    : (<CustPicker {...this.props} defaultView={gt(12121)} disabled={editing > 0} value={value} onChange={e=>{
                        onChange(e)
                       this.apiCustBranches(e)
                    }}/>)
            case 'cBranchId':
                return this.state.modalReadOnly ?
                    value + " - " + editedRecord.cBranchName
                    : (<GenericSelector {...this.props} id={this.selectors[3].id} {...{ value, onChange }} />)
            case 'cSubjectDetailId':
                return editing > 0 ?
                    value + " - " + editedRecord.cSubjectDetailDesc
                    : (<GenericSelector {...this.props} id={"SERVICE_SUBJECT_DETAILS_LIST2"} value={value} onChange={e => {
                        let findRow = this.props.data.SERVICE_SUBJECT_DETAILS_LIST2.dataset.find(f => f.code == e)
                        this.props.dataActions.genericDataSetEdit(this.id, { [field]: e, cExpectedClosingDateTime: moment().add(findRow?.expectedClosing, "minute").format("YYYY-MM-DD HH:mm:ss") })
                    }} />)
            case 'cCallerName': case 'cCallerTel1': case 'cCallerTel2': case 'cDesc': case 'cItemSN':
                return this.state.modalReadOnly || (editing > 0 && (field == 'cDesc' || field == 'cItemSN')) ? value
                    : (<Input value={value} onChange={e => { onChange(e.target.value) }} />)
            case 'cRemarks':
                return this.state.modalReadOnly ? value
                    : (<Input.TextArea value={value} onChange={e => { onChange(e.target.value) }} />)
            case 'cItemCode':
                return editing > 0 ?
                    value + " - " + editedRecord.cItemName
                    : (<SelectorItemClassic {...this.props} valueId={value} valueName={editedRecord.cItemName}
                        onChange={(cItemCode, cItemName) => { this.props.dataActions.genericDataSetEdit(this.id, { cItemCode, cItemName }) }} />)
            case 'cStatusID':
                dataset = this.getStatusList().map(x => { return { ...x, disabled: x.sOpen || x.sClose } })
                return (<GenericSelector {...this.props} {...{ value, onChange, dataset }} backgroundSet disabled={editing == 0 || this.state.modalReadOnly} />)
            case 'cTargetUserID':
                this.props.data[this.selectors[4].id].dataset.forEach(x => {
                    if (editedRecord.cSubjectDetailId == x.subjectDetailId && !dataset.find(f => f.code == x.userId)) dataset.push({ code: x.userId, name: x.userName })
                })
                return (<GenericSelector {...this.props} {...{ value, dataset }} onChange={e => {
                    this.props.dataActions.genericDataSetEdit(this.id, { cTargetUserID: e, cTargetUserTagID: "" })
                }} />)
            case 'cTargetUserTagID':
                this.props.data[this.selectors[4].id].dataset.forEach(x => {
                    if (editedRecord.cSubjectDetailId == x.subjectDetailId && !dataset.find(f => f.code == x.tagId)) dataset.push({ code: x.tagId, name: x.tagName })
                })
                return (<GenericSelector {...this.props} {...{ value, dataset }} onChange={e => {
                    this.props.dataActions.genericDataSetEdit(this.id, { cTargetUserID: "", cTargetUserTagID: e })
                }} />)
            case 'cExpectedClosingDateTime':
                return moment(value).format("DD/MM/YYYY HH:mm")
        }
    }

    getStatusList = () => {
        return this.props.data[this.status_id].data
            .map(x => x.split("\f"))
            .filter(f => f[6] == '1')
            .map(y => {
                return {
                    code: y[0],
                    name: y[1],
                    color: y[3],
                    sOpen: y[4] == '1',
                    sClose: y[5] == '1',
                }
            })
    }

    renderEditCol = (text, record) => {
        return (<TableLineIcons
            genericIcons={[
                {
                    type: "edit",
                    onClick: () => { 
                        this.props.dataActions.genericDataStartEditing(this.id, record.key, record.index) 
                        this.apiCustBranches(record.cCustomerID)
                    },
                    tooltip: this.getText(20419),
                    disabled: record.cCloseDateTime ? true : false,
                }, {
                    type: "idcard",
                    onClick: () => { message.info("בפיתוח...") },
                    tooltip: this.getText(20420),
                    disabled: record.cCloseDateTime ? true : false,
                }, {
                    type: "eye",
                    onClick: () => {
                        this.setState({ modalReadOnly: true }, () => {
                            this.props.dataActions.genericDataStartEditing(this.id, record.key, record.index)
                        })
                    },
                    tooltip: this.getText(20421),
                }, {
                    type: "printer",
                    onClick: () => { message.info("בפיתוח...") },
                    tooltip: this.getText(20422),
                },
            ]}
        />)
    }


    render() {
        const gt = this.getText;
        const colSpan = n => window.innerWidth > 600 ? n : 24
        const render = t => t
        const renderDatetime = t => t ? moment(t).format("DD/MM/YYYY HH:mm") : null
        const renderStatus = (t, r) => (<div style={{ background: r.cStatusColor }}>{t}</div>)

        const { editing, editedRecord } = this.props.data[this.id]
        const validProps = (f, d) => { return d && d.length ? {} : { required: true, validateStatus: !editedRecord[f] ? "error" : "success" } }


        const divStyle = {
            align: "center",
            dir: "rtl", // dir: "ltr"
            padding: "2% 2% 2% 2%",
        };

        const modalEditFooter = this.state.modalReadOnly ?
            [
                <Button key={"open-Detaild"} onClick={() => { message.info("בפיתוח...") }} style={{ margin: 5 }}>{gt(20423)}</Button>,
                <Button key={"back"} onClick={this.handleCancel} style={{ margin: 5 }}>{gt(20424)}</Button>,
            ] : [
                <Button key={"back"} onClick={this.handleCancel} style={{ margin: 5 }}>{gt(20385)}</Button>,
                <Button key={"create"} type={"primary"} onClick={this.handleOk} style={{ margin: 5 }}>{gt(20386)}</Button>,
            ]

        return (<div style={divStyle}>
            <GenericTable
                {...this.props}
                rowsFilter={r => r.key > 0}
                id={this.id}
                getApi={this.api.get}
                filters={this.defaultFilters}
                title={gt(20332)}
                searchBox
                addButton={() => {
                    let findOpenStatus = this.props.data[this.status_id].data.map(x => x.split("\f"))
                        .map(y => { return { code: y[0], name: y[1], sOpen: y[4] == '1' } })
                        .find(f => f.sOpen)
                    console.log("findOpenStatus", findOpenStatus)
                    this.props.dataActions.genericDataStartInlineCreate(this.id)
                    this.props.dataActions.genericDataSetEdit(this.id, {
                        cOpenDateTime: moment().format("YYYY-MM-DD HH:mm:ss"),
                        cOpenUserID: this.props.user.userId,
                        cOpenUserName: this.props.user.displayName,
                        cStatusID: findOpenStatus?.code,
                        cStatusName: findOpenStatus?.name,
                    })
                }}
                filterButton={() => { this.setState({ modalFilter: true }) }}
                columns={[
                    { title: gt(20333), key: "cID", dataIndex: "cID", width: "5%", render },
                    { title: gt(20334), key: "cOpenDateTime", dataIndex: "cOpenDateTime", width: "7%", render: renderDatetime },
                    { title: gt(20335), key: "cCustomerID", dataIndex: "cCustomerID", width: "5%", render },
                    { title: gt(20336), key: "cCustName", dataIndex: "cCustName", width: "9", render },
                    { title: gt(20337), key: "cBranchName", dataIndex: "cBranchName", width: "9%", render },
                    { title: gt(20338), key: "cCallerName", dataIndex: "cCallerName", width: "9%", render },
                    { title: gt(20339), key: "cCallerTel1", dataIndex: "cCallerTel1", width: "7%", render },
                    { title: gt(20340), key: "cCallerTel2", dataIndex: "cCallerTel2", width: "7%", render },
                    { title: gt(20341), key: "cSubjectDetailDesc", dataIndex: "cSubjectDetailDesc", width: "9%", render },
                    { title: gt(20342), key: "cDesc", dataIndex: "cDesc", width: "9%", render },
                    { title: gt(20343), key: "cStatusName", dataIndex: "cStatusName", width: "9%", render: renderStatus },
                    { title: gt(20344), key: "cCloseDateTime", dataIndex: "cCloseDateTime", width: "7%", render: renderDatetime },
                    { title: gt(20345), width: "8%", render: this.renderEditCol },

                ]}
            />
            <GenericModal
                visible={this.state.modalFilter}
                onCancel={() => { this.setState({ modalFilter: false }) }}
                title={gt(20346)}
                footer={[
                    <Button onClick={() => { this.setState({ modalFilter: false }) }}>{this.getText(20356)}</Button>,
                    <Button onClick={() => { this.setState({ ...this.defaultFilters }) }}>{this.getText(20357)}</Button>,
                    <Button type="primary" onClick={this.onFilter}>{this.getText(20358)}</Button>,
                ]}>
                <Row>
                    <Col span={colSpan(8)}>
                        <Form.Item label={gt(20347)}>{this.filterField('_custId')}</Form.Item>
                        <Form.Item label={gt(20348)}>{this.filterFromToDate('_fromDateOpen', '_toDateOpen')}</Form.Item>
                        <Form.Item label={gt(20361)}>{this.filterField('_isClosed')}</Form.Item>
                        {this.state._isClosed == '0' ? "" : <Form.Item label={gt(20349)}>{this.filterFromToDate('_fromDateClose', '_toDateClose')}</Form.Item>}
                    </Col>
                    <Col span={colSpan(8)}>
                        <Form.Item label={gt(20350)}>{this.filterSelector('_subjectList', 0)}</Form.Item>
                        <Form.Item label={gt(20351)}>{this.filterSelector('_subjectDetailList', 1)}</Form.Item>
                        <Form.Item label={gt(20352)}>{this.filterField('_itemCode')}</Form.Item>
                        <Form.Item label={gt(20353)}>{this.filterField('_sn')}</Form.Item>
                    </Col>
                    <Col span={colSpan(8)}>
                        <Form.Item label={gt(20354)}>{this.filterSelector('_statusList', -1, {
                            dataset: this.props.data[this.status_id].data.map(x => x.split("\f")).map(y => { return { code: y[0], name: y[1], color: y[3] } }),
                            backgroundSet: true
                        })}</Form.Item>
                        <Form.Item label={gt(20355)}>{this.filterSelector('_tagList', 2)}</Form.Item>
                    </Col>
                </Row>
            </GenericModal>

            <GenericModal
                visible={editing > -1}
                onCancel={this.handleCancel}
                title={(<span>
                    <span>{gt(20367)} {editedRecord.cID > 0 ? editedRecord.cID : gt(20371)}</span> &nbsp; &nbsp; &nbsp;	&nbsp;
                    <span>{gt(20368)} {renderDatetime(editedRecord.cOpenDateTime)}</span> &nbsp;	&nbsp; &nbsp; &nbsp;
                    <span>{gt(20369)} {editedRecord.cOpenUserName}</span> &nbsp;	&nbsp; &nbsp; &nbsp;
                    <span>{gt(20370)} {renderDatetime(editedRecord.cCloseDateTime)}</span>
                </span>)}
                width={1200}
                footer={modalEditFooter}>
                <Form labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
                    <Form.Item label={gt(20372)} {...validProps('cCustomerID')}>{this.editField('cCustomerID')}</Form.Item>
                    <Form.Item label={gt(20373)}>{this.editField('cBranchId', 3)}</Form.Item>
                    <Row>
                        <Col span={colSpan(8)} style={{ paddingLeft: 5 }}><Form.Item label={gt(20374)}>{this.editField('cCallerName')}</Form.Item></Col>
                        <Col span={colSpan(8)} style={{ paddingLeft: 5, paddingRight: 5 }}><Form.Item label={gt(20375)}>{this.editField('cCallerTel1')}</Form.Item></Col>
                        <Col span={colSpan(8)} style={{ paddingRight: 5 }}><Form.Item label={gt(20376)}>{this.editField('cCallerTel2')}</Form.Item></Col>
                    </Row>
                    <Form.Item label={gt(20377)} {...validProps('cSubjectDetailId')}>{this.editField('cSubjectDetailId')}</Form.Item>
                    <Form.Item label={gt(20378)} {...validProps('cDesc')}>{this.editField('cDesc')}</Form.Item>
                    <Form.Item label={gt(20379)}>{this.editField('cRemarks')}</Form.Item>
                    <Row>
                        <Col span={colSpan(12)}><Form.Item label={gt(20380)}>{this.editField('cItemCode')}</Form.Item></Col>
                        <Col span={colSpan(12)}><Form.Item label={gt(20381)}>{this.editField('cItemSN')}</Form.Item></Col>
                    </Row>
                    <Row>
                        <Col span={colSpan(12)}><Form.Item label={gt(20382)}>{this.editField('cStatusID')}</Form.Item></Col>
                        <Col span={colSpan(12)}><Form.Item label={gt(20383)}>{this.editField('cExpectedClosingDateTime')}</Form.Item></Col>
                    </Row>
                    {editing == 0 ? <Row>
                        <Col span={colSpan(12)} ><Form.Item label={gt(20384)} {...validProps('cTargetUserID', editedRecord.cTargetUserTagID)}>{this.editField('cTargetUserID')}</Form.Item></Col>
                        <Col span={colSpan(12)}><Form.Item label={gt(20387)} {...validProps('cTargetUserTagID', editedRecord.cTargetUserID)}>{this.editField('cTargetUserTagID')}</Form.Item></Col>
                    </Row> : ""}
                </Form>
            </GenericModal>
        </div>)
    }

}

export default ServiceCalls;