import React, { Component } from "react";
import "../App.css";
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { Modal, Card, Button, Input } from "antd";
import PageToolbar from "./PageToolbar";

const Search = Input.Search;

class ImageSlicer extends Component<Props, State> {
    state = {
        crop: {
            unit: '%',
            y: 0,
            x: 0,
            width: 100,
            height: 100,
            aspect: this.props.aspect ? this.props.aspect : 16 / 9,
        },
        croppedImageUrl: null,
        googleItems: null,
    };

    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
            await this.getCroppedImg(this.imageRef, crop);
        }
    }

    getCroppedImg = (image, crop) => {
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;

        const canvas = document.createElement('canvas');
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        canvas.toBlob((blob) => {
            if (blob) {
                let reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onloadend = () => {
                    let base64data = reader.result;
                    console.log('base64data', base64data)
                    if (base64data.length > 500000) {
                        let size = Math.sqrt(500000 / base64data.length);
                        this.convertSize(image, crop, size);
                    } else {
                        this.setState({ croppedImageUrl: base64data });
                    }
                }
            }
        })
    }

    convertSize = (image, crop, size) => {
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;

        const canvas = document.createElement('canvas');
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width * size,
            crop.height * size
        );

        canvas.toBlob((blob) => {
            if (blob) {
                let reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onloadend = () => {
                    let base64data = reader.result;
                    let newCrop = {
                        ...crop,
                        x: 0,
                        y: 0,
                        width: crop.width * size,
                        height: crop.height * size,
                    }
                    let imgElement = document.createElement('img');
                    imgElement.src = base64data;
                    imgElement.style.display = 'none';

                    imgElement.addEventListener('load', () => {
                        this.getCroppedImg(imgElement, newCrop);
                    });
                }
            }
        })
    }

    googleApiTest2 = (q) => {

        let xmlHttp = new XMLHttpRequest();
        xmlHttp.onreadystatechange = () => {
            if (xmlHttp.readyState === 4 && xmlHttp.status === 200) {
                let json = JSON.parse(xmlHttp.response);
                this.setState({ googleItems: json.items });
                console.log('yuda googleApiTest2 ', json.items);
            }
        }
        let key = "AIzaSyAkXPjTCk3mXYwFp39_rsN9zjS855CzICY";
        let cx = "018122850742097613232:trki8auoui0";

        let url = "https://www.googleapis.com/customsearch/v1?key=" + key + "&cx=" + cx + "&q=" + q;

        xmlHttp.open('GET', url, true);
        xmlHttp.send();
    }

    render() {
        console.log(this.props, "pictureView props")
    const { crop, croppedImageUrl } = this.state;

        let titleBar = (
            <div style={{
                paddingLeft: '4px',
                paddingRight: '4px',
                paddingTop: '4px',
                paddingBottom: '0px',
            }}>
                <PageToolbar
                    title={this.props.title}
                    actionButtons={[]}
                    isModal={true}
                    getText={this.props.getText}
                    />
            </div>);

        return (<Modal
            visible={this.props.src}
            onCancel={this.props.close}
            width={500}
            title={titleBar}
            footer={[<Button key="Save" type="primary" onClick={() => { this.props.newImg(croppedImageUrl) }}>{this.props.textButton}</Button>]}
        >

{/* 
            <Search
                placeholder={"חפש תמונה"}
                onSearch={(value) => { this.googleApiTest2(value) }}
                size={"large"}
                enterButton
            />
            {this.state.googleItems ? this.state.googleItems.map((x) => x.pagemap && x.pagemap.cse_image ? (<img src={x.pagemap.cse_image[0].src} width={'32%'} />) : '') : ''} */}


            <ReactCrop
                src={this.props.src}
                crop={crop}
                ruleOfThirds
                onImageLoaded={(image) => { this.imageRef = image }}
                onComplete={(crop) => { this.makeClientCrop(crop) }}
                onChange={(crop) => { this.setState({ crop }) }}
            />
            {/* {this.props.crop.aspect} */}
            {croppedImageUrl ? (<img alt="Crop" id="newImageUp" style={{ width: '90%', margin: '5%', marginTop: 20 }} src={croppedImageUrl} />) : ''}

        </Modal>);
    }
}

export default ImageSlicer;
