/* @flow */
/*jshint esversion: 6 */
import React, { Component } from "react";
import { InputNumber, message, Form, Select, Modal, Button, Row, Col, Tooltip, Icon, Input, Checkbox, Popconfirm, Popover } from "antd";
import PageToolbar from "./PageToolbar";
import TableIDs from "../data/TableIDs";
import { Job, User, OutputType, ProcessType } from '../JmReact';
import LangContext from "../contextProvider/LangContext";
import "../App.css";
import GenericModal from "./GenericModal";
import ResponsiveTable from "./ResponsiveTable";
import { escapeRegExp } from "../utils/utils";

const FormItem = Form.Item;
const { Option } = Select;

type LabelPrinterProps = {
    labels: Array,
    qtyInput: Boolean,
    defaultQty: Number
};

class LabelPrinter extends Component<LabelPrinterProps, State> {

    constructor(props/*: Props*/) {
        super(props);
        this.state = {
            labelId: -1,
            priceListId: '1',
            quantity: 0,
            packageQty: 1,

            isFormatA4: '0',
            reportName: "",
            startRow: 1,
            startCol: 1,
        }

        this.clearState = this.state;

        this.selectors = [
            { id: TableIDs.genericSelectorLabelsList, api: "get_labels_list" },
            { id: TableIDs.genericSelectorPriceList, api: "get_prices" }
        ];

        this.selectors.map((x) => {
            if (props.data[x.id] === undefined) { props.data[x.id] = { ...props.data.genericSelector } }
        })
    }

    static contextType = LangContext;

    getText = (id) => {
        return this.context.get(id) || '[' + id + ']'
    }

    componentDidMount() {
        this.selectors.map((x) => {
            this.props.dataActions.genericSelectorRefreshDataset(
                x.id, this.props.user.companyCode, this.props.user.token, x.api);
        })


    }

    componentDidUpdate(prev) {
        if (!prev.toggle && this.props.toggle) {
            this.sendAPI("get_price_list_default", "999", ob => { this.setState({ priceListId: ob.data }) })
        }
    }


    sendAPI = (script, data, sCal) => {
        let user = new User(this.props.user.token, this.props.user.companyCode);
        let job = new Job(user, script, OutputType.OUTPUT_TYPE_DATA, ProcessType.PROCESS_TYPE_SYNC);
        job.setInput(data);
        job.send("/cgi-bin/CashOnTab", sCal, (e) => { console.error(e) });
    }

    stringRTL = (code) => {
        let resCode = '';
        let isZebraFormat = code.indexOf("^XA^JBE^XZ") === 0;
        let isFMTFormat = code.indexOf("%Type3Values:%") > -1;

        let bdSplit = (s, r) => {
            let triger = r ? s.match(/[a-zA-Z0-9`]/) : s.match(/[א-ת]/);
            return (triger) ? [s.slice(0, (triger.index)), s.slice(triger.index)] : null;
        }
        let bdFun = (text) => {
            let isR = text.match(/[א-ת]/);
            let isL = text.match(/[a-zA-Z0-9`]/);

            if (isZebraFormat && isR) {
                if (isL) {
                    text = text.split(" ").map(x => {
                        return x.match(/[א-ת]/) ? x.split("").reverse().join("") : x
                    }).join(" ")
                } else {
                    text = text.split("").reverse().join("")
                }
            }

            if (isR && isL) {
                let corR = (isR.index === 0) ? true : false;
                let resArray = [];
                while (bdSplit(text, corR)) {
                    resArray.push(bdSplit(text, corR)[0])
                    text = bdSplit(text, corR)[1];
                    corR = corR ? false : true;
                }
                resArray.push(text);

                let res = '';
                for (let i = resArray.length - 1; i >= 0; i--) {
                    res += resArray[i];
                }
                return ' ' + res + ' ';
            } else {
                return text;
            }
        }


        if (isZebraFormat) {
            resCode += code

        } else if (isFMTFormat) {
            let splitData = code.split("%Type3Values:%")
            let splitData2 = splitData[1].split("%Type3Qty:%")

            let baseData = splitData[0];
            let values = splitData2[0]?.split("\n").slice(1, -1)
            let qty = parseInt(splitData2[1]) ?? 1
            if (values) values.forEach((x, i) => { baseData = baseData.split('%' + (i + 1) + '%').join(x) })
            for (let x = 1; x <= qty; x++) {
                resCode += "\n" + baseData;
            }

        } else {

            code.split('^Kformat').map((x, i) => {
                if (i === 0) {
                    resCode += x;
                } else {
                    x.split("\n").map((y, index) => {
                        if (index === 0) {
                            resCode += '^Kformat' + y + "\n";
                        } else {
                            if (['', 'E', '~'].indexOf(y[0]) < 0) resCode += bdFun(y) + "\n";
                            else resCode += y + "\n";
                        }
                    })
                }
            })
        }

        return resCode;
    }

    getQty = () => {
        return (this.state.quantity > 0)
            ? this.state.quantity
            : (this.props.defaultQty && this.props.defaultQty > 0) ? this.props.defaultQty : 1
    }

    handleOk = () => {
        if (this.props.labels) {
            const { labelId, isFormatA4, reportName, startRow, startCol, packageQty } = this.state;
            let _id = labelId;

            const { dataset } = this.props.data[this.selectors[0].id];
            if (dataset.length === 1) _id = dataset[0].code;

            if ((isFormatA4 != '1' && _id === -1) || (isFormatA4 == '1' && !reportName)) {
                message.info(this.getText(10349), 3, null);
                return;
            }

            if (isFormatA4 == '1') {
                let params = { startRow, startCol, packageQty, reportName, REPORT_LOCALE: this.getText(101) }
                if (this.state.reportName == 'Labels/deliveries.pdf') {
                    this.props.deliveriesFields.forEach(x => { params = { ...params, [x.key]: encodeURIComponent(x.value) } })
                } else {
                    params = {
                        ...params,
                        _data: encodeURIComponent(
                            JSON.stringify(this.props.labels.filter(f => f.checked).map(x => [x._item_code, x._item_name, x._item_price, x._quantity]))
                        )
                    }
                }
                this.props.ActionQueue.addToQueue({ action: this.props.dataActions.generateReport, args: [this.props.user.companyCode, this.props.user.token, params] });
                return;
            }

            let request = this.props.labels.filter(f => f.checked).map((x) => { return { _id, ...x } })
            let dataSend = Object.keys(request[0]).join('\f') + request.map((x) => '\r' + Object.values(x).join('\f'));
            let user = new User(this.props.user.token, this.props.user.companyCode);
            let job = new Job(user, "get_EZPL_code_to_printer_v3", OutputType["OUTPUT_TYPE_DATA"], ProcessType["PROCESS_TYPE_SYNC"]);
            job.setInput(dataSend);
            job.send("/cgi-bin/CashOnTab", this.successCallback, this.errorCallback);
        }
    }

    successCallback = (obj) => {
        if (obj.data) {
            let data = "\n" + this.stringRTL(obj.data) + "\n";
            let blob = new Blob([data.replace(/([^\r])\n/g, "$1\r\n")], { type: 'text/plain' });
            let anchor = document.createElement('a');

            anchor.download = "labelJob.lpjob";
            anchor.href = (window.webkitURL || window.URL).createObjectURL(blob);
            anchor.dataset.downloadurl = ['text/plain', anchor.download, anchor.href].join(':');
            anchor.click();
            this.handleCancel();
        } else {
            message.error(this.getText(10350));
        }
    }

    errorCallback = () => {
        message.error(this.getText(10350));
    }

    handleCancel = () => {
        this.props.uiActions.hideModal(TableIDs.LabelPrinter);
    }


    getSelectOptions = (selector, stateName) => {
        if (this.props.data[selector] && this.props.data[selector].dataset[0]) {
            let list = this.props.data[selector].dataset.map((obj, index) => {
                return (<Option key={obj['code']} value={obj['code']} valuefilter={obj['name']}>{obj['name']}</Option>)
            });
            return (<Select
                disabled={false} value={this.state[stateName]} style={{ width: '100%', paddingLeft: '4px' }}
                onChange={(e) => { this.setState({ [stateName]: e }) }}>
                <Option key={-1} value={-1} valuefilter={-1}>- {this.getText(10351)} -</Option>
                {list}
            </Select>)
        }
    }

    renderField = (text, record, field) => {
        const onChange = e => { this.props.onChangeLabels(this.props.labels.map((x, i) => i == record.index ? { ...x, [field]: e } : x)) }
        switch (field) {
            case '_item_name': case '_cust_name': return (<Input value={text} onChange={e => { onChange(e.target.value) }} />)
            case '_item_price': case '_package_qty': case '_quantity': return (<InputNumber value={text} onChange={onChange} />)
            case 'checked': return (<Checkbox checked={text} onChange={e => { onChange(e.target.checked) }} />)
        }
    }

    renderAllCheckbox = () => {
        if (this.props.labels) {
            let lCount = this.props.labels.length;
            let cCount = this.props.labels.filter(f => f.checked).length;
            return (<Checkbox
                checked={lCount && lCount === cCount}
                indeterminate={lCount && cCount && lCount !== cCount}
                onChange={e => { this.props.onChangeLabels(this.props.labels.map((x) => { return { ...x, checked: e.target.checked } })) }}
            />)
        }
    }

    onImportPrices = () => {
        let dataSend = "_priceList\f_itemCode\r" + this.props.labels.map(x => this.state.priceListId + "\f" + x._item_code).join("\r")
        this.sendAPI("get_price_by_item_and_price_list", dataSend, ob => {
            let newData = [...this.props.labels]
            ob.data.split("\r").map(x => {
                let y = x.split("\f")
                newData = newData.map(z => z._item_code == y[0] ? { ...z, _item_price: y[1] } : z)
            })
            this.props.onChangeLabels(newData)
            this.setState({ popoverPriceList: false })
        })
    }

    onImportNames = (isShort) => {
        let dataSend = "_isShort\f_itemCode\r" + this.props.labels.map(x => isShort + "\f" + x._item_code).join("\r")
        this.sendAPI("get_name_by_item", dataSend, ob => {
            let newData = [...this.props.labels]
            ob.data.split("\r").map(x => {
                let y = x.split("\f")
                newData = newData.map(z => z._item_code == y[0] ? { ...z, _item_name: y[1] } : z)
            })
            this.props.onChangeLabels(newData)
            this.setState({ popoverItemName: false })
        })
    }


    render() {
        let issetMultiLabels = this.props.data[this.selectors[0].id] && this.props.data[this.selectors[0].id].dataset.length > 1;

        return (<GenericModal
            width={1200}
            visible={this.props.toggle}
            title={this.props.title}
            genericActionButtons={[
                <Tooltip title={this.getText(20054)}>

                    <Button style={{ marginTop: 5, marginLeft: 5 }} onClick={() => { this.setState({ popoverPriceList: !this.state.popoverPriceList }) }}>
                        <Icon style={{ fontSize: 20 }} className={"clickable margin-4"} type={"dollar"} />
                    </Button>
                </Tooltip>,
                <Tooltip title={this.getText(20056)}>
                    <Popover
                        placement="top"
                        visible={this.state.popoverItemName}
                        content={[
                            <Button type="primary" style={{ margin: 5 }} onClick={() => { this.onImportNames(0) }}>{this.getText(20057)}</Button>,
                            <Button type="primary" style={{ margin: 5 }} onClick={() => { this.onImportNames(1) }}>{this.getText(20058)}</Button>
                        ]}
                        title={this.getText(20056)}
                        trigger="click">
                        <Button style={{ marginTop: 5, marginLeft: 5 }} onClick={() => { this.setState({ popoverItemName: !this.state.popoverItemName }) }}>
                            <Icon style={{ fontSize: 20 }} className={"clickable margin-4"} type={"import"} />
                        </Button>
                    </Popover>
                </Tooltip>
            ]}
            onCancel={this.handleCancel}
            footer={[
                <Button key="back" onClick={this.handleCancel}>{this.getText(10353)}</Button>,
                <Button key="create" type="primary" onClick={this.handleOk}>{this.getText(10355)}</Button>,
            ]}>
            <Row>
                <Col span={window.innerWidth > 600 ? 8 : 24} style={{ paddingLeft: 10 }}>
                    <FormItem label={this.getText(17316)}>
                        <Select
                            style={{ width: "100%" }}
                            value={this.state.isFormatA4}
                            onChange={e => { this.setState({ isFormatA4: e, labelId: -1, reportName: "" }) }}>
                            <Option value={'0'}>{this.getText(17314)}</Option>
                            <Option value={'1'}>{this.getText(17315)}</Option>
                        </Select>
                    </FormItem>
                </Col>

                <Col span={window.innerWidth > 600 ? 8 : 24} style={{ paddingLeft: 10 }}>
                    {this.state.isFormatA4 == '1' ?
                        <FormItem label={this.getText(10356)}>
                            <Select
                                style={{ width: "100%" }}
                                value={this.state.reportName}
                                onChange={e => { this.setState({ reportName: e }) }}>
                                <Option value={''}>- {this.getText(10351)} -</Option>
                                <Option value={'Labels/docItems4on12.pdf'}>{this.getText(17317)}</Option>
                                <Option value={'Labels/docItems4on6.pdf'}>{this.getText(18438)}</Option>
                                <Option value={'Labels/docItems6on12.pdf'}>{this.getText(18769)}</Option>
                                {this.props.deliveriesFields ? <Option value={'Labels/deliveries.pdf'}>{this.getText(20327)}</Option> : ""}
                            </Select>
                        </FormItem>
                        : issetMultiLabels ?
                            <FormItem label={this.getText(10356)}>
                                {this.getSelectOptions(this.selectors[0].id, 'labelId')}
                            </FormItem>
                            : ""}
                </Col>

                <Col span={window.innerWidth > 600 ? 8 : 24}>
                    {this.state.isFormatA4 == '1' ?
                        <FormItem label={this.getText(17320)}>
                            {this.getText(17321)} {" : "} <InputNumber value={this.state.startRow} onChange={e => { this.setState({ startRow: e }) }} />
                            {this.getText(17322)} {" : "} <InputNumber value={this.state.startCol} onChange={e => { this.setState({ startCol: e }) }} />
                        </FormItem>
                        : ""}
                </Col>
            </Row>

            {this.state.isFormatA4 == '1' && this.state.reportName == 'Labels/deliveries.pdf' ?
                <Row>
                    <Col span={8}>
                        <FormItem label={this.getText(20328)}>
                            <InputNumber value={this.state.packageQty} onChange={e => { this.setState({ packageQty: e }) }} />
                        </FormItem>
                    </Col>
                    <Col span={16}>
                        {this.props.deliveriesFields?.map(x => {
                            return (<Row>
                                <Col span={8}>{x.label}</Col>
                                <Col span={16}>
                                    <Input
                                        value={x.value}
                                        onChange={e => {
                                            this.props.onChangeDeliveriesFields(this.props.deliveriesFields.map(y => y.key == x.key ? { ...x, value: e.target.value } : y))
                                        }}
                                    />
                                </Col>
                            </Row>)
                        })}
                    </Col>
                </Row>


                : <ResponsiveTable
                    tableOnly
                    dataSource={this.props.labels}
                    columns={[
                        { title: this.getText(20048), key: "_item_code", dataIndex: "_item_code", width: "10%", render: t => t },
                        { title: this.getText(20049), key: "_item_name", dataIndex: "_item_name", width: "20%", render: (t, r) => this.renderField(t, r, '_item_name') },
                        { title: this.getText(20050), key: "_item_price", dataIndex: "_item_price", width: "15%", render: (t, r) => this.renderField(t, r, '_item_price') },
                        { title: this.getText(20051), key: "_cust_name", dataIndex: "_cust_name", width: "15%", render: (t, r) => this.renderField(t, r, '_cust_name') },
                        { title: this.getText(20052), key: "_package_qty", dataIndex: "_package_qty", width: "15%", render: (t, r) => this.renderField(t, r, '_package_qty') },
                        { title: this.getText(20053), key: "_quantity", dataIndex: "_quantity", width: "15%", render: (t, r) => this.renderField(t, r, '_quantity') },
                        { title: this.renderAllCheckbox(), key: "checked", dataIndex: "checked", width: "15%", render: (t, r) => this.renderField(t, r, 'checked') },
                    ]}
                    pagination={this.props.labels?.length > 10 ? { pageSize: 10 } : false}
                />}

            <GenericModal
                visible={this.state.popoverPriceList}
                onCancel={() => { this.setState({ popoverPriceList: false }) }}
                title={this.getText(20054)}
                width={500}
                footer={[
                    <Button onClick={() => { this.setState({ popoverPriceList: false }) }}>{this.getText(20085)}</Button>,
                    <Button type="primary" onClick={this.onImportPrices}>{this.getText(20055)}</Button>,
                ]}>
                <FormItem style={{ width: 250 }} label={this.getText(10357)} zIndex={50000}>{this.getSelectOptions(this.selectors[1].id, 'priceListId')}</FormItem>
            </GenericModal>

        </GenericModal >)
    }
}

export default LabelPrinter;