import { Checkbox, Form, Input, InputNumber } from "antd";
import React, { Component } from "react";
import DateTimePicker from "./DateTimePicker";
import GenericFromToDatePicker from "./GenericFromToDatePicker";


class GenericFormItem extends Component {

    getContent = () => {
        const { value, onChange, inputType, inputProps, label, dateFrom, dateTo, inline } = this.props
        switch (inputType) {
            case "text": return (<Input value={value} onChange={e => { onChange(e.target.value) }} {...inputProps} />)
            case "textarea": return (<Input.TextArea value={value} onChange={e => { onChange(e.target.value) }} {...inputProps} />)
            case "number": return (<InputNumber value={value} onChange={onChange} {...inputProps} />)
            case "checkbox": return (<Checkbox checked={value == '1'} onChange={e => { onChange(e.target.checked ? "1" : "0") }} {...inputProps}>{label}</Checkbox>)
            case "date": return (<DateTimePicker format={"DD/MM/YYYY"} value={value} onChange={onChange} {...inputProps} />)
            case "datetime": return (<DateTimePicker format={"DD/MM/YYYY HH:mm"} showTime value={value} onChange={onChange} {...inputProps} />)
            case "dateFromTo": return (<GenericFromToDatePicker format={"DD/MM/YYYY"} {...{ dateFrom, dateTo, inline }} {...inputProps} />)
        }
    }

    render() {
        return (<Form.Item
            label={this.props.inputType != "checkbox" ? this.props.label : null}
            {...this.props.formItemProps}>
            {this.getContent()}
        </Form.Item>)
    }
}

export default GenericFormItem;